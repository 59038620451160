/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		: 768px;
$breakpoint_tb		: 1100px;
$breakpoint_pc		: $breakpoint_sp + 1;


/* Color */
$c_base : #005b2a;
/*サイトカラー*/
$c_baseD : #003500;
/*サイトカラー暗め*/
$c_baseL : #748ba6;
$c_baseL2 : #215aa3;
$c_bg_gray : #f3f3f3;

$c_text : #333;
/*テキストに使うフォントカラー*/
$c_gray: #93989e;
/*未設定*/


@mixin maxPC {
    max-width: 1102px;
    width: 90%;
    margin: 0 auto;
}

@mixin fontUsu {
    font-family: 'Noto Sans JP', sans-serif;
}

@mixin fontEn {
    font-family: "Lato", sans-serif;
}

@mixin fontOs {
    font-family: 'Oswald', sans-serif;
}

@mixin fontMin {
    font-family: 'Shippori Mincho', serif;
}

@mixin fontTel {
    font-family: "Jost", sans-serif;
}

@mixin fontLc {
    font-family: learning-curve, sans-serif;
    font-weight: 700;
    font-style: normal;
}

/*
.f_em{ @include fontUsu; }
.f_os{ @include fontOs; }
.f_min{ @include fontMin; }
.f_lc{ @include fontLc; }
*/