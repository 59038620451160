main {
	*+p {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 20px;
			font-size: 14px;
		}

		@if $type ==pc {
			margin-top: 20px;
			font-size: 16px;
		}
	}

	// p {
	// 	@if $type ==sp {
	// 		font-size: 17px;
	// 		line-height: 2;
	// 	}

	// 	@if $type ==pc {
	// 		font-size: 16px;
	// 		line-height: 2;
	// 	}
	// }

}


//ボタンにつく共通のmargin-top
.btnWrap {
	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 30px;
	}
}

p {
	// @if $type ==sp {
	// 	margin-top: 24px;
	// }

	// @if $type ==pc {
	// 	margin-top: 32px;
	// 	line-height: 2.18;
	// }
}


a {
	@if $type =="" {
		color: $c_base;
		text-decoration: underline;
		text-underline-offset: 5px;
	}

	@if $type ==sp {}

	@if $type ==pc {
		transition: .2s color;

		&:hover {
			text-decoration: none;
		}
	}

	&.hasArrow {
		span {
			position: relative;
			padding-left: 20px;

			&::after {
				position: absolute;
				content: "";
				background: url(../images/common/arrow-green.svg);
				background-size: contain;
				background-repeat: no-repeat;
				aspect-ratio: 83/150;
				width: 6px;
				height: auto;
				left: 0;
				top: 8px;
			}
		}

	}



	&.external {
		@if $type =="" {
			position: relative;
		}

		@if $type ==sp {}

		@if $type ==pc {}


		&::after {
			@if $type =="" {
				position: absolute;
				content: "";
				background: url(../images/common/external_icon.svg);
				background-size: contain;
				background-repeat: no-repeat;
				aspect-ratio: 1/1;
			}

			@if $type ==sp {
				width: 20px;
				height: auto;
				right: -20px;
				bottom: -1px;
			}

			@if $type ==pc {
				width: 26px;
				height: auto;
				right: -27px;
				bottom: -2px;
			}
		}
	}
}




.second {
	@if $type ==sp {
		padding-top: 100px;
	}
}


.breadcrumbArea {
	@if $type ==sp {
		position: relative;
		background: #fff;
		padding-top: 10px;
		padding-bottom: 50px;
	}

	@if $type ==pc {
		max-width: 1102px;
		margin: 0 auto;
		position: relative;
		// padding-bottom: 20px;
		margin-top: 24px;
		padding-bottom: 80px;
	}

	ul {
		display: flex;

		@if $type ==sp or $type ==tb {
			padding-left: 20px;
			padding-right: 20px;
			flex-wrap: wrap;
		}

		li {
			color: $c_text;
			margin-right: 5px;
			font-weight: 400;

			@if $type ==sp {
				font-size: 12px;
			}

			@if $type ==pc {
				font-size: 15px;
			}

			&:before {
				content: "/";
				display: inline-block;
				margin-right: 3px;
			}

			&:first-child:before {
				content: "";
				display: none;
			}

			a {
				text-decoration: none;
				color: #333;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.breadcrumbArea+.section {
	@if $type =="" {
		padding-top: 0 !important;
	}
}

.section {
	@if $type =="" {}

	@if $type ==sp {
		padding: 40px 0;
		margin: 0 auto;
	}

	@if $type ==pc {
		padding: 60px 0 60px;
	}

	&:last-of-type {
		@if $type =="" {
			padding-bottom: 0;
		}
	}


	.inner {
		@if $type =="" {}

		@if $type ==sp {
			padding: 0 20px;
		}

		@if $type ==pc {
			@include maxPC;
		}

		@if $type ==tb {
			padding: 0 20px
		}

		>.greenLabel:first-child {
			@if $type =="" {
				margin-top: 0;
			}
		}

		>.pageBigLead:first-child {
			@if $type =="" {
				margin-top: 0;
			}
		}


		&.semi {
			@if $type =="" {
				margin-inline: auto;
			}

			@if $type ==pc {
				padding-inline: 20px;
				width: 100%;
			}
		}

		>p {
			@if $type ==sp {
				margin-top: 20px;
			}

			@if $type ==pc {
				margin-top: 32px;
			}
		}
	}
}



.pageBigLead {
	@if $type =="" {
		color: $c_text;
		font-weight: 600;
		text-align: center;
		margin-inline: auto;

	}

	@if $type ==sp {
		font-size: 18px;
		line-height: 1.5;
	}

	@if $type ==pc {
		font-size: 24px;
		line-height: 2 !important;
	}

	&+.pageLead {
		@if $type ==sp {
			margin-top: 25px;
		}

		@if $type ==pc {
			margin-top: 20px;
		}
	}

	&+p {
		@if $type ==sp {
			margin-top: 20px;
		}

		@if $type ==pc {
			margin-top: 24px;
		}
	}
}

.pageLead {
	@if $type =="" {
		color: $c_text;
		font-weight: 500;
		margin-inline: auto;
	}

	@if $type ==sp {
		font-size: 14px;
		line-height: 1.85;
		letter-spacing: 0.04em;
		text-align: center;
	}

	@if $type ==pc {
		line-height: 2.18;
		font-size: 16px;
		max-width: 870px;
		text-align: center;
	}
}

.pageLead+.btnWrap {
	@if $type ==sp {
		margin-top: 52px;
	}

	@if $type ==pc {
		margin-top: 70px;
	}
}

.h2TitleWrap {
	@if $type =="" {
		color: $c_base;
		margin-inline: auto;
		text-align: center;
		letter-spacing: 0.06em;
	}

	@if $type ==sp {
		font-size: 27px;
	}

	@if $type ==pc {
		font-size: 30px;
	}

	.engh2 {
		@if $type =="" {
			font-weight: 900;
			@include fontEn;
			font-style: italic;
			letter-spacing: 0.06em;
		}
	}

	h2 {
		@if $type =="" {
			@include fontUsu;
			color: $c_base;
			letter-spacing: 0.06em;
			line-height: 1;
		}

		@if $type ==sp {
			font-size: 17px;
			margin-top: -2px;
		}

		@if $type ==pc {
			font-size: 24px;
			margin-top: 4px;
		}
	}
}

.h2TitleWrap+p {
	@if $type =="" {
		font-weight: 500;
	}

	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 34px;
	}
}



.inner+* {
	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 40px;
	}
}



//タイトル
*+.titleBorder,
*+h2.title {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 40px;
	}

	@if $type ==pc {
		margin-top: 60px;
	}
}


*+h3.title {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 40px;
	}

	@if $type ==pc {
		margin-top: 50px;
	}
}

h3.title {
	@if $type =="" {
		font-weight: 400;
		line-height: 1.4;
		position: relative;
	}

	@if $type ==sp {
		padding-left: 14px;
		font-size: 18px;
	}

	@if $type ==pc {
		padding-left: 18px;
		font-size: 24px;
	}

	&:after {
		@if $type =="" {
			content: "";
			display: block;
			height: 100%;
			margin: 0;
			padding: 0;
			border-left: 2px solid $c_baseD;
			border-right: 2px solid $c_baseD;
			position: absolute;
			top: 0;
			left: 0;
		}

		@if $type ==sp {
			width: 3px;
		}

		@if $type ==pc {
			width: 3px;
		}
	}
}

*+.titleGray,
*+h4.title {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 30px;
	}
}

.titleGray+*,
h4.title+* {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 0;
	}

	@if $type ==pc {
		margin-top: 5px;
	}
}

.titleGray,
h4.title {
	@if $type =="" {
		color: $c_gray;
		font-weight: bold;
	}

	@if $type ==sp {
		font-size: 16px;
	}

	@if $type ==pc {
		font-size: 20px;
	}
}

*+h5.title {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 30px;
	}
}

h5.title+* {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 2px;
	}

	@if $type ==pc {
		margin-top: 5px;
	}
}

h5.title {
	@if $type =="" {
		font-weight: bold;
	}

	@if $type ==sp {
		font-size: 16px;
	}

	@if $type ==pc {
		font-size: 18px;
	}
}

*+.titleHeader {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 30px;
	}
}

.titleHeader {
	@if $type =="" {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@if $type ==sp {}

	@if $type ==pc {}

	.titleCenter {
		@if $type =="" {
			line-height: 1.4;
		}

		@if $type ==sp {
			font-size: 20px;
		}

		@if $type ==pc {
			font-size: 28px;
		}
	}
}

.titleCenterSub {
	@if $type =="" {
		color: $c_gray;
		line-height: 1.4;
		font-weight: bold;
	}

	@if $type ==sp {
		margin-top: 4px;
		font-size: 12px;
	}

	@if $type ==pc {
		margin-top: 8px;
		font-size: 15px;
	}
}


*+[class^="listCol"] {
	@if $type ==sp {
		margin-top: 28px;
	}

	@if $type ==pc {
		margin-top: 43px;
	}
}

[class^="listCol"] {
	@if $type =="" {}

	@if $type ==sp {
		width: 100%;

		>.listItem+.listItem {
			margin-top: 20px;
		}
	}

	@if $type ==pc {
		display: flex;
		// flex-wrap: wrap;
		width: 100%;
	}

	&.setBtn {
		@if $type =="" {
			margin-inline: auto;
			align-items: center;
		}

		@if $type ==sp {
			&.rev {
				flex-direction: column-reverse;
			}
		}

		@if $type ==pc {
			justify-content: space-between;
			gap: 74px;

			&.rev {
				flex-direction: row-reverse;
				gap: 7.3%;
			}
		}

		.listItem {
			@if $type ==pc {
				width: 49%
			}

			&.imgArea {
				@if $type ==sp {

					margin-inline: auto;
					text-align: center;
				}

				@if $type ==pc {
					margin-right: 0;
				}
			}

			&.textArea {
				@if $type ==pc {
					width: 100%;
				}

			}

			.btnWrap {
				@if $type ==sp {
					margin-top: 20px;
				}

				@if $type ==pc {
					margin-top: 30px;
				}

				.btn {
					@if $type ==sp {
						margin-inline: auto;
						font-size: 18px;
					}
				}
			}

			h4 {
				margin-top: 0;

				&+p {
					@if $type ==pc {
						margin-top: 8px;
					}
				}
			}
		}

	}

	&.hasBox {
		@if $type =="" {
			background: #fff;
			border-radius: 20px;
			box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
		}

		@if $type ==sp {
			padding: 20px;
		}

		@if $type ==pc {
			padding: 45px;
		}

		.imgArea {
			p {
				img {
					border-radius: 10px;
				}
			}
		}
	}
}

*+.setBtn {
	@if $type ==pc {
		margin-top: 40px;
	}
}


*+.hasBox {
	@if $type ==pc {
		margin-top: 85px;
	}
}



.listCol2 {
	>.listItem {
		@if $type ==pc {
			width: calc(50% - 27px);

			&:nth-child(odd) {
				margin-right: 54px;
			}

			&:nth-child(n + 3) {
				margin-top: 50px;
			}
		}
	}

}

.listCol3 {
	.listItem {
		@if $type ==pc {
			width: calc(33.3% - 37px);

			&:not(:nth-child(3n)) {
				margin-right: 56px;
			}

			&:nth-child(n + 4) {
				margin-top: 50px;
			}
		}

		@if $type ==tb {
			width: calc(33.3% - 37px);

			&:not(:nth-child(3n)) {
				margin-right: 56px;
			}

			&:nth-child(n + 4) {
				margin-top: 50px;
			}
		}
	}
}

.listCol4 {
	.listItem {
		@if $type ==pc {
			width: calc(25% - 26px);

			&:not(:nth-child(4n)) {
				margin-right: 30px;
			}

			&:nth-child(n + 5) {
				margin-top: 30px;
			}
		}
	}
}

*+.colImgFixed {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 30px;
	}

	@if $type ==pc {
		margin-top: 30px;
	}
}

.colImgFixed {
	@if $type =="" {
		display: flex;
	}

	@if $type ==sp {
		flex-direction: column;
	}

	@if $type ==pc {}

	/*
	@if $type == tb{
		flex-direction: column;
	}
	*/

	>* {
		@if $type =="" {
			&.colImg {
				img {
					display: block;
					margin: 0 auto;
				}
			}
		}

		@if $type ==sp {
			width: 100%;

			&.colText {
				order: 1;
				margin-top: 30px;
			}

			&.colImg {
				order: 0;
			}
		}

		@if $type ==pc {
			&.colImg {
				flex: none;
			}

			&:nth-child(odd) {
				margin-right: 97px;
			}

			&:nth-child(n + 3) {
				margin-top: 30px;
			}
		}

		/*
		@if $type == tb{
			width: 100%;

			&.colText {
				order: 1;
				margin-top: 30px;
			}

			&.colImg {
				order: 0;
			}

			&:nth-child(odd) {
				margin-right: 0;
			}

			&:nth-child(n + 3) {
				margin-top: 0;
			}
		}
*/
	}

}

//テーブル
*+.commonTable {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 30px;
	}
}

.commonTable {
	@if $type =="" {
		width: 100%;
		text-align: left;
		line-height: 1.25;
	}

	@if $type ==sp {
		font-size: 14px;
	}

	@if $type ==pc {
		width: 100%;
		margin-right: auto;
		margin-left: auto;

	}

	a {
		@if $type ==sp {
			line-height: 1.6;
		}
	}

	&.tableEqual {
		@if $type =="" {
			table-layout: fixed;
		}

		@if $type ==sp {}

		@if $type ==pc {}
	}

	>tbody>tr+tr,
	>thead>tr+tr {
		th {
			@if $type ==sp {
				border-top: none;
			}
		}
	}

	>tbody>tr,
	>thead>tr {
		*+* {
			@if $type ==sp {
				border-top: none;
			}
		}

		>th {
			@if $type =="" {
				vertical-align: top;
				font-weight: bold;

				word-break: break-all;
				background: #edf9e4;
				color: $c_base;

				&[scope="row"] {
					background: #eee;
				}
			}

			@if $type ==sp {
				padding: 12px 6px;
				display: block;
				border-left: 1px solid #cfcfcf;
				border-right: 1px solid #cfcfcf;
				border-top: 1px solid #cfcfcf;
			}

			@if $type ==pc {
				padding: 28px 1.5%;
				font-size: 18px;
				border-top: 1px solid #cfcfcf;
				border-left: 1px solid #cfcfcf;
				border-bottom: 1px solid #cfcfcf;

				&:not([scope="row"]) {
					width: 230px !important;
				}
			}
		}
	}

	>tbody>tr,
	>thead>tr {
		>td {
			@if $type =="" {
				text-align: left;
				vertical-align: middle;
				word-break: break-all;
				background: #fff;

			}

			@if $type ==sp {
				padding: 12px 10px;
				display: block;
				border-right: 1px solid #cfcfcf;
				border-bottom: 1px solid #cfcfcf;
				border-left: solid 1px #cfcfcf;
			}

			@if $type ==pc {
				padding: 28px 4%;
				line-height: 1.54;
				border-top: 1px solid #cfcfcf;
				border-right: 1px solid #cfcfcf;
				border-bottom: 1px solid #cfcfcf;
			}
		}
	}

	th.valTop,
	td.valTop {
		@if $type =="" {
			vertical-align: top;
		}
	}

	th.nowrap,
	td.nowrap {
		@if $type =="" {
			white-space: nowrap;
		}
	}

}

*+.otherTable {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 30px;
	}
}

.otherTable {
	@if $type =="" {
		width: 100%;
		line-height: 1.25;
		text-align: left;
	}

	@if $type ==sp {
		font-size: 14px;
	}


	th {
		background: #edf9e4;
		color: $c_base;
	}

	th,
	td {
		@if $type =="" {
			border: solid 1px #cfcfcf;
		}

		@if $type ==sp {
			padding: 12px 6px;
		}

		@if $type ==pc {
			padding: 28px 1.5%;
		}
	}
}




*+.scrollTable {
	@if $type =="" {}

	@if $type ==sp {}

	@if $type ==pc {
		margin-top: 30px;
	}
}

.scrollTable {
	@if $type ==sp {
		overflow: auto;
	}

	table {
		@if $type ==sp {
			min-width: calc(100% + 50px);
			margin-top: 0;
			white-space: nowrap;
		}
	}
}

*+.scrollText {
	@if $type ==sp {
		margin-top: 15px;
	}
}

.scrolltext {
	@if $type ==sp {
		width: 160px;
		display: block;
		padding: 4px 8px;
		margin-bottom: 8px;
		margin-left: auto;
		color: $c_base;
		font-size: 11px;
		font-weight: bold;
		text-align: center;
		border-radius: 12px;
		background: #333;
	}

	@if $type ==pc {
		display: none !important;
	}
}


//list
*+ul.commonList,
*+ol.commonList {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 20px;
	}
}

ul.commonList {
	@if $type =="" {}

	@if $type ==sp {

		&.col_sp {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			>li {
				width: calc(50% - 20px);

				&:nth-child(odd) {
					margin-right: 10px;
				}

				&:nth-child(even) {
					margin-left: 10px;
				}
			}
		}
	}

	@if $type ==pc {

		&.col_pc {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			>li {
				width: calc(50% - 20px);

				&:nth-child(odd) {
					margin-right: 10px;
				}

				&:nth-child(even) {
					margin-left: 10px;
				}
			}

		}
	}

	>li {
		@if $type =="" {
			padding-left: 1.2em;
			position: relative;
		}

		@if $type ==sp {
			&+li {
				margin-top: 10px;
			}
		}

		@if $type ==pc {
			&+li {
				margin-top: 18px;
			}
		}

		&:before {
			@if $type =="" {
				content: "";
				display: block;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: $c_base;
				position: absolute;
			}

			@if $type ==sp {
				top: 11px;
				margin: auto;
				left: 0;
			}

			@if $type ==pc {
				top: 11px;
				margin: auto;
				left: 0;
			}

		}

		a {
			@if $type =="" {
				display: inline-block;
			}

			@if $type ==sp {
				font-size: 14px;
			}

			@if $type ==pc {}
		}
	}
}

ol.commonList {
	@if $type =="" {
		counter-reset: number 0;
	}

	@if $type ==pc {

		&.col_pc {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			>li {
				width: calc(50% - 20px);

				&:nth-child(odd) {
					margin-right: 10px;
				}

				&:nth-child(even) {
					margin-left: 10px;
				}

				&:nth-of-type(2) {
					margin-top: 0 !important;
				}
			}
		}
	}

	>li {
		@if $type =="" {
			padding-left: 1.6em;
			position: relative;
		}

		@if $type ==sp {
			position: relative;
		}

		@if $type ==pc {}

		+li {
			@if $type ==sp {
				margin-top: 7px;
			}

			@if $type ==pc {
				margin-top: 7px;
			}
		}

		&:before {
			@if $type =="" {
				counter-increment: number 1;
				content: counter(number) ".";
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: bold;
				color: $c_base;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
			}

			@if $type ==sp {}

			@if $type ==pc {
				font-size: 16px;
				left: 7px;
			}
		}

		&:nth-child(n + 10) {
			&:before {
				@if $type =="" {
					content: counter(number) ".";
				}
			}
		}

		a {
			@if $type ==sp {
				font-size: 14px;
			}
		}
	}
}

*:not(.title)+dl.commonList {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 40px;
	}
}

.title+dl.commonList {
	@if $type =="" {
		border-top: none !important;
	}

	@if $type ==sp {
		margin-top: 0;
	}

	@if $type ==pc {
		margin-top: 0;
	}
}

dl.commonList {
	@if $type =="" {
		border-top: 1px solid $c_gray;
	}

	@if $type ==sp {}

	@if $type ==pc {}

	>div {
		@if $type =="" {
			border-bottom: 1px solid $c_gray;
		}

		@if $type ==sp {
			padding: 15px 0;
		}

		@if $type ==pc {
			display: flex;
			padding: 20px 0;
		}

		>dt,
		>dd {
			@if $type =="" {}

			@if $type ==sp {
				font-size: 12px;
				line-height: 1.3;
			}

			@if $type ==pc {
				font-size: 16px;
			}
		}

		>dt {
			@if $type =="" {
				flex: none;
				font-weight: bold;
			}

			@if $type ==sp {
				font-size: 16px;
				font-weight: 600;
			}

			@if $type ==pc {
				width: 196px;
				padding-right: 20px;
			}
		}

		>dd {
			@if $type =="" {
				>*:nth-of-type(1) {
					margin-top: 0 !important;
				}
			}

			@if $type ==sp {
				margin-top: 8px;
				font-size: 14px;
				line-height: 1.76;
			}

			@if $type ==pc {
				width: calc(100% - 196px);
			}
		}
	}
}


//2カラムボタン
.col2Btn {
	@if $type =="" {
		display: flex;
		justify-content: center;
		gap: 30px;
		text-align: center;
	}

	@if $type ==sp {
		flex-direction: column;
		margin-top: 30px;
	}

	@if $type ==pc {
		margin-top: 40px;
	}

	.btnWrap {
		@if $type =="" {
			margin-top: 0;
		}
	}
}


//list＆text flex
*+.listTextCol2 {
	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {

		margin-top: 30px;
	}
}

.listTextCol2 {
	@if $type =="" {
		display: flex;
		border: solid 1px #cfcfcf;

	}

	@if $type ==sp {
		flex-direction: column;
		padding: 20px;
	}

	@if $type ==pc {

		margin-top: 20px;
	}

	ul {
		@if $type =="" {}

		@if $type ==pc {
			width: 48%;
			// margin-right: 4%;
			padding: 28px;
		}

		&.bold {
			li {
				@if $type ==pc {
					font-size: 18px;
				}

				&+li {
					@if $type ==pc {
						margin-top: 10px;
					}
				}
			}
		}

	}

	.textArea {
		@if $type ==sp {
			margin-top: 30px;
			border-top: solid 1px #cfcfcf;
			padding-top: 30px;
		}

		@if $type ==pc {
			width: 48%;
			padding: 28px;
			border-left: solid 1px #cfcfcf;
		}


		>ul {
			@if $type =="" {
				padding: 0;
			}

			@if $type ==pc {
				width: 100%;
			}
		}

		h5 {
			@if $type =="" {
				margin-top: 0;
			}

		}
	}

	.textArea:has(+ .textArea) {
		@if $type =="" {
			border-top: 0;
			margin-top: 0;
			border-left: none;
		}

		@if $type ==sp {
			padding-top: 0;
		}
	}

}

.lineBox {
	@if $type =="" {
		border: solid 1px #cfcfcf;
		border-radius: 10px;
	}

	@if $type ==sp {
		padding: 20px;
		margin-top: 30px;
	}

	@if $type ==pc {
		padding: 28px;
		margin-top: 40px;
	}
}

//textlink
.textLink {
	@if $type =="" {
		// color: $c_base;
		// display: inline;
		// text-decoration: underline;
		// text-underline-offset: 5px;

	}

	// &:hover {
	// 	text-decoration: none;
	// }


}

//icon text
.iconText {
	@if $type =="" {
		text-decoration: underline;
		text-underline-offset: 5px;
		display: inline !important;
	}

	@if $type ==pc {

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	&.pdf {
		@if $type =="" {
			position: relative;
		}

		&::before {
			content: "";
			position: absolute;
			background: url(../images/second/pdf-icon.svg);
			background-size: contain;
			aspect-ratio: 117/150;
			height: auto;

			@if $type ==sp {
				right: -30px;
				background-repeat: no-repeat;
				bottom: -2px;
				width: 15px;

			}

			@if $type ==pc {
				right: -30px;
				background-repeat: no-repeat;
				bottom: -2px;
				width: 19px;

			}
		}

	}

	&.excel {
		@if $type =="" {
			position: relative;
		}

		&::before {
			position: absolute;
			background: url(../images/second/excel-icon.svg);
			background-repeat: no-repeat;
			background-size: contain;
			content: "";
			right: -30px;
			top: 50%;
			transform: translateY(-50%);
			width: 20px;
			height: 24px;
		}
	}

	&.word {
		@if $type =="" {
			position: relative;
		}

		&::before {
			position: absolute;
			content: "";
			background: url(../images/second/word-icon.svg);
			background-repeat: no-repeat;
			background-size: contain;
			right: -30px;
			top: 50%;
			transform: translateY(-50%);
			width: 20px;
			height: 30px;
		}
	}
}


//2カラム黒いナビリンク
.col2NavLink {
	@if $type =="" {
		display: flex;
	}

	@if $type ==sp {
		flex-direction: column;
		gap: 24px;
		margin-top: 40px;
	}

	@if $type ==pc {
		flex-wrap: wrap;
		gap: 30px;
		margin-top: 60px;
	}

	div {
		@if $type ==sp {
			width: 100%;
		}

		@if $type ==pc {
			width: calc((100% - 30px)/2);
		}

		a {
			@if $type =="" {
				background: #2e2e2e;
				color: #fff;
				text-decoration: none;
				font-weight: 600;
				display: inline-block;
				width: 100%;
				border-radius: 8px;
				position: relative;
			}

			@if $type ==sp {
				font-size: 16px;
				padding-left: 34px;
				padding-block: 11px;
				padding-right: 70px;
			}

			@if $type ==pc {
				font-size: 18px;
				padding-left: 34px;
				padding-block: 11px;
				padding-right: 70px;
			}

			.arrow {
				@if $type =="" {
					position: absolute;
					background: #fff;
					border-radius: 50px;
					right: 30px;
					top: 50%;
					transform: translateY(-50%);
				}

				@if $type ==sp {
					width: 18px;
					height: 18px;
				}

				@if $type ==pc {
					width: 27px;
					height: 27px;
				}

				&::before {
					content: "";
					background: url(../images/common/arrow.svg);
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					aspect-ratio: 83 / 150;
					background-repeat: no-repeat;
					background-size: contain;

					@if $type ==sp {
						width: 4px;
						height: auto;
					}

					@if $type ==pc {
						width: 6px;
						height: auto;
					}
				}
			}
		}
	}
}



.iframeSize {
	margin-inline: auto;
	max-width: 640px;
	width: 90%;

	.iframeWrap {
		@if $type =="" {
			width: 100%;
			padding-bottom: 56.25%;
			position: relative;
		}

		@if $type ==sp {
			margin-top: 1.5em;
		}

		@if $type ==pc {
			margin-top: 42px;
		}

		iframe {
			@if $type =="" {
				border: none;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
	}
}

.mapWrap {
	@if $type =="" {
		width: 100%;
		margin-top: 1.5em;
		position: relative;
	}

	@if $type ==sp {
		padding-bottom: 50.25%;
	}

	@if $type ==pc {
		padding-bottom: 50.55%;
		margin-top: 63px;
	}

	iframe {
		@if $type =="" {
			border: none;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
}


// colorBox
*+.colorBox {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 30px;
	}

	@if $type ==pc {
		margin-top: 40px;
	}
}

*+.colorBoxAdd {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 15px;
	}

	@if $type ==pc {
		margin-top: 20px;
	}
}

.colorBox,
.colorBoxAdd {
	@if $type =="" {
		background: #fffce9;
	}
}

.colorBox {
	@if $type ==sp {
		padding: 25px 13px 25px;
		border-radius: 8px;
	}

	@if $type ==pc {
		padding: 40px 40px 40px;
		border-radius: 15px;
	}
}

.colorBoxAdd {
	@if $type ==sp {
		padding: 18px 15px 18px;
		border-radius: 8px;
	}

	@if $type ==pc {
		padding: 20px 40px 20px;
		border-radius: 8px;
	}
}

.colorBox,
.colorBoxAdd {
	.colorBoxTitle {
		@if $type =="" {
			font-weight: bold;
			color: $c_gray;
			line-height: 1.5;
		}

		@if $type ==sp {
			font-size: 15px;
		}

		@if $type ==pc {
			font-size: 18px;
		}

		+p {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 6px;
			}

			@if $type ==pc {
				margin-top: 4px;
			}
		}
	}
}

// 汎用的なもの
@if $type =="" {
	.taC {
		text-align: center !important;
	}

	.taL {
		text-align: left !important;
	}

	.taR {
		text-align: right !important;
	}

	.pT0 {
		padding-top: 0 !important;
	}

	.pT10 {
		padding-top: 10px !important;
	}

	.pT20 {
		padding-top: 20px !important;
	}

	.pT30 {
		padding-top: 30px !important;
	}

	.pT40 {
		padding-top: 40px !important;
	}

	.pT50 {
		padding-top: 50px !important;
	}

	.pT60 {
		padding-top: 60px !important;
	}

	.pT70 {
		padding-top: 70px !important;
	}

	.pB0 {
		padding-bottom: 0px !important;
	}

	.pB5 {
		padding-bottom: 5px !important;
	}

	.pB10 {
		padding-bottom: 10px !important;
	}

	.pB20 {
		padding-bottom: 20px !important;
	}

	.pB30 {
		padding-bottom: 30px !important;
	}

	.pB40 {
		padding-bottom: 40px !important;
	}

	.mB0 {
		margin-bottom: 0 !important;
	}

	.mB5 {
		margin-bottom: 5px !important;
	}

	.mB10 {
		margin-bottom: 10px !important;
	}

	.mB20 {
		margin-bottom: 20px !important;
	}

	.mB30 {
		margin-bottom: 30px !important;
	}

	.mB40 {
		margin-bottom: 40px !important;
	}

	.mB50 {
		margin-bottom: 50px !important;
	}

	.mB60 {
		margin-bottom: 60px !important;
	}

	.mB70 {
		margin-bottom: 70px !important;
	}

	.mB80 {
		margin-bottom: 80px !important;
	}

	.mT0 {
		margin-top: 0 !important;
	}

	.mT5 {
		margin-top: 5px !important;
	}

	.mT10 {
		margin-top: 10px !important;
	}

	.mT20 {
		margin-top: 20px !important;
	}

	.mT30 {
		margin-top: 30px !important;
	}

	.mT40 {
		margin-top: 40px !important;
	}

	.mT50 {
		margin-top: 50px !important;
	}

	.mT60 {
		margin-top: 60px !important;
	}

	.mT70 {
		margin-top: 70px !important;
	}

	.mT80 {
		margin-top: 80px !important;
	}

	.mL0 {
		margin-left: 0px !important;
	}

	.mL5 {
		margin-left: 5px !important;
	}

	.mL10 {
		margin-left: 10px !important;
	}

	.mL20 {
		margin-left: 20px !important;
	}

	.mL30 {
		margin-left: 30px !important;
	}

	.mL40 {
		margin-left: 40px !important;
	}

	.mL50 {
		margin-left: 50px !important;
	}

	.mR0 {
		margin-right: 0px !important;
	}

	.mR5 {
		margin-right: 5px !important;
	}

	.mR10 {
		margin-right: 10px !important;
	}

	.mR20 {
		margin-right: 20px !important;
	}

	.mR30 {
		margin-right: 30px !important;
	}

	.mR40 {
		margin-right: 40px !important;
	}

	.mR50 {
		margin-right: 50px !important;
	}

	.size12 {
		font-size: 12px !important;
	}

	.size13 {
		font-size: 13px !important;
	}

	.size14 {
		font-size: 14px !important;
	}

	.size15 {
		font-size: 15px !important;
	}

	.size16 {
		font-size: 16px !important;
	}

	.size17 {
		font-size: 17px !important;
	}

	.size18 {
		font-size: 18px !important;
	}

	.size19 {
		font-size: 19px !important;
	}

	.size20 {
		font-size: 20px !important;
	}

	.size22 {
		font-size: 22px !important;
	}

	.size24 {
		font-size: 24px !important;
	}

	.size28 {
		font-size: 28px !important;
	}

	.colorRed {
		color: #de0000 !important;
	}

	.colorBlue {
		color: #356fcf !important;
	}

	.colorGray {
		color: $c_gray !important;
	}

	.colorPrimary {
		color: $c_base !important;
	}

	.preBox {
		background: #eff0f4;
		border: 1px solid $c_base;
		padding: 15px;
		box-sizing: border-box;
		clear: both;
		overflow: hidden;
		font-family: Arial, sans-serif;
		line-height: 1.8;

		pre {
			overflow: auto;
			white-space: pre-wrap;
			word-wrap: break-word;
			text-align-last: auto;
		}
	}
}






@if $type ==sp {
	.preBox {
		margin-top: 20px;
	}

	.taC_sp {
		text-align: center !important;
	}

	.taL_sp {
		text-align: left !important;
	}

	.taR_sp {
		text-align: right !important;
	}

	.pT0_sp {
		padding-top: 0 !important;
	}

	.pT10_sp {
		padding-top: 10px !important;
	}

	.pT20_sp {
		padding-top: 20px !important;
	}

	.pB0_sp {
		padding-bottom: 0 !important;
	}

	.pB10_sp {
		padding-bottom: 10px !important;
	}

	.pB20_sp {
		padding-bottom: 20px !important;
	}

	.pB30_sp {
		padding-bottom: 30px !important;
	}

	.pB40_sp {
		padding-bottom: 40px !important;
	}

	.pT30_sp {
		padding-top: 30px !important;
	}

	.pT40_sp {
		padding-top: 40px !important;
	}

	.mB0_sp {
		margin-bottom: 0 !important;
	}

	.mB5_sp {
		margin-bottom: 5px !important;
	}

	.mB10_sp {
		margin-bottom: 10px !important;
	}

	.mB15_sp {
		margin-bottom: 15px !important;
	}

	.mB20_sp {
		margin-bottom: 20px !important;
	}

	.mB30_sp {
		margin-bottom: 30px !important;
	}

	.mB40_sp {
		margin-bottom: 40px !important;
	}

	.mB50_sp {
		margin-bottom: 50px !important;
	}

	.mB60_sp {
		margin-bottom: 60px !important;
	}

	.mB70_sp {
		margin-bottom: 70px !important;
	}

	.mB80_sp {
		margin-bottom: 80px !important;
	}

	.mT0_sp {
		margin-top: 0 !important;
	}

	.mT5_sp {
		margin-top: 5px !important;
	}

	.mT10_sp {
		margin-top: 10px !important;
	}

	.mT20_sp {
		margin-top: 20px !important;
	}

	.mT30_sp {
		margin-top: 30px !important;
	}

	.mT40_sp {
		margin-top: 40px !important;
	}

	.mT50_sp {
		margin-top: 50px !important;
	}

	.mT60_sp {
		margin-top: 60px !important;
	}

	.mT70_sp {
		margin-top: 70px !important;
	}

	.mT80_sp {
		margin-top: 80px !important;
	}

	.mL0_sp {
		margin-left: 0px !important;
	}

	.mL5_sp {
		margin-left: 5px !important;
	}

	.mL10_sp {
		margin-left: 10px !important;
	}

	.mL20_sp {
		margin-left: 20px !important;
	}

	.mL30_sp {
		margin-left: 30px !important;
	}

	.mL40_sp {
		margin-left: 40px !important;
	}

	.mL50_sp {
		margin-left: 50px !important;
	}

	.mR0_sp {
		margin-right: 0px !important;
	}

	.mR5_sp {
		margin-right: 5px !important;
	}

	.mR10_sp {
		margin-right: 10px !important;
	}

	.mR20_sp {
		margin-right: 20px !important;
	}

	.mR30_sp {
		margin-right: 30px !important;
	}

	.mR40_sp {
		margin-right: 40px !important;
	}

	.mR50_sp {
		margin-right: 50px !important;
	}

	.size12_sp {
		font-size: 12px !important;
	}

	.size13_sp {
		font-size: 13px !important;
	}

	.size14_sp {
		font-size: 14px !important;
	}

	.size15_sp {
		font-size: 15px !important;
	}

	.size16_sp {
		font-size: 16px !important;
	}

	.size17_sp {
		font-size: 17px !important;
	}

	.size18_sp {
		font-size: 18px !important;
	}

	.size19_sp {
		font-size: 19px !important;
	}

	.size20_sp {
		font-size: 20px !important;
	}

	.size22_sp {
		font-size: 22px !important;
	}

	.size24_sp {
		font-size: 24px !important;
	}

	.size28_sp {
		font-size: 28px !important;
	}

	.w50p_sp {
		width: 50% !important;
	}
}

@if $type ==pc {
	.preBox {
		margin-top: 30px;
	}

	.taC_pc {
		text-align: center !important;
	}

	.taL_pc {
		text-align: left !important;
	}

	.taR_pc {
		text-align: right !important;
	}

	.pT10_pc {
		padding-top: 10px !important;
	}

	.pT20_pc {
		padding-top: 20px !important;
	}

	.pT30_pc {
		padding-top: 30px !important;
	}

	.pT40_pc {
		padding-top: 40px !important;
	}

	.pB10_pc {
		padding-bottom: 10px !important;
	}

	.pB20_pc {
		padding-bottom: 20px !important;
	}

	.pB30_pc {
		padding-bottom: 30px !important;
	}

	.pB40_pc {
		padding-bottom: 40px !important;
	}

	.mB0_pc {
		margin-bottom: 0 !important;
	}

	.mB5_pc {
		margin-bottom: 5px !important;
	}

	.mB10_pc {
		margin-bottom: 10px !important;
	}

	.mB15_pc {
		margin-bottom: 15px !important;
	}

	.mB20_pc {
		margin-bottom: 20px !important;
	}

	.mB30_pc {
		margin-bottom: 30px !important;
	}

	.mB40_pc {
		margin-bottom: 40px !important;
	}

	.mB50_pc {
		margin-bottom: 50px !important;
	}

	.mB60_pc {
		margin-bottom: 60px !important;
	}

	.mB70_pc {
		margin-bottom: 70px !important;
	}

	.mB80_pc {
		margin-bottom: 80px !important;
	}

	.mT0_pc {
		margin-top: 0 !important;
	}

	.mT5_pc {
		margin-top: 5px !important;
	}

	.mT10_pc {
		margin-top: 10px !important;
	}

	.mT20_pc {
		margin-top: 20px !important;
	}

	.mT30_pc {
		margin-top: 30px !important;
	}

	.mT40_pc {
		margin-top: 40px !important;
	}

	.mT50_pc {
		margin-top: 50px !important;
	}

	.mT70_pc {
		margin-top: 70px !important;
	}

	.mT80_pc {
		margin-top: 80px !important;
	}

	.mL0_pc {
		margin-left: 0px !important;
	}

	.mL5_pc {
		margin-left: 5px !important;
	}

	.mL10_pc {
		margin-left: 10px !important;
	}

	.mL20_pc {
		margin-left: 20px !important;
	}

	.mL30_pc {
		margin-left: 30px !important;
	}

	.mL40_pc {
		margin-left: 40px !important;
	}

	.mL55_pc {
		margin-left: 55px !important;
	}

	.mR0_pc {
		margin-right: 0px !important;
	}

	.mR5_pc {
		margin-right: 5px !important;
	}

	.mR10_pc {
		margin-right: 10px !important;
	}

	.mR20_pc {
		margin-right: 20px !important;
	}

	.mR30_pc {
		margin-right: 30px !important;
	}

	.mR40_pc {
		margin-right: 40px !important;
	}

	.mR50_pc {
		margin-right: 50px !important;
	}

	.mR55_pc {
		margin-right: 55px !important;
	}

	.size12_pc {
		font-size: 12px !important;
	}

	.size13_pc {
		font-size: 13px !important;
	}

	.size14_pc {
		font-size: 14px !important;
	}

	.size15_pc {
		font-size: 15px !important;
	}

	.size16_pc {
		font-size: 16px !important;
	}

	.size17_pc {
		font-size: 17px !important;
	}

	.size18_pc {
		font-size: 18px !important;
	}

	.size19_pc {
		font-size: 19px !important;
	}

	.size20_pc {
		font-size: 20px !important;
	}

	.size22_pc {
		font-size: 22px !important;
	}

	.size24_pc {
		font-size: 24px !important;
	}

	.size28_pc {
		font-size: 28px !important;
	}

	.hoverAlpha,
	.hoverAlpha img {
		display: block;
		transition: .3s opacity;

		&:hover,
		&:hover img {
			opacity: .8;
		}
	}
}


*+.summaryBox {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 30px;
	}

	@if $type ==pc {
		margin-top: 30px;
	}
}

.summaryBox {
	@if $type =="" {}

	@if $type ==sp {}

	@if $type ==pc {
		display: flex;
		justify-content: space-between;
	}

	.summaryBoxText {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {
			max-width: 55%;
			margin-right: 5%;
		}
	}

	.summaryBoxThumb {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {
			width: 40%;
			max-width: 500px;
			overflow: hidden;
			aspect-ratio: 6/4;
			position: relative;
		}

		>span {
			@if $type =="" {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
			}

			@if $type ==sp {}

			@if $type ==pc {
				background: #c00;
				animation: .4s .4s scrollIn forwards ease-out;
				transform: translateX(0);
			}
		}

		img {
			@if $type =="" {
				display: block;
			}

			@if $type ==sp {}

			@if $type ==pc {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}
}

@keyframes scrollIn {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(100%);
	}
}


.movieLinkL {
	display: inline-block;
	position: relative;

	&:after {
		content: "";
		display: block;
		background: url(../images/home/movie.svg) no-repeat center center;
		background-size: contain;
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;

		@if $type ==sp {
			width: 70px;
			height: 70px;
			background-size: 100% auto;
			margin-top: -35px;
			margin-left: -35px;
		}

		@if $type ==pc {
			width: 100px;
			height: 100px;
			margin-top: -50px;
			margin-left: -50px;
		}
	}
}

.sec_border {
	border-top: 4px solid #3764ab;
}

.section.sec_white {
	background: #fff;

	&:last-of-type {
		margin-bottom: -170px;
		padding-bottom: 170px;
	}
}


//下層コンテンツ
.contentsWrap {
	@if $type ==sp {
		padding-bottom: 60px;
	}

	@if $type ==pc {
		padding-bottom: 120px;
	}

	&+.section {
		@if $type ==sp {
			padding-top: 0;
		}
	}
}

.contentsWrap>.pageLead {
	font-size: 15px;

	@if $type ==sp {
		text-align: left;
		padding: 0 20px;
		font-size: 17px;
		line-height: 2;
	}

	@if $type ==pc {
		text-align: center;
	}
}

.col2_img_list {
	@if $type ==sp {}

	@if $type ==pc {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	li {
		@if $type ==sp {
			margin-top: 30px;
			text-align: center;

			&:nth-of-type(1) {
				margin-top: 0;
			}
		}

		@if $type ==pc {
			flex-basis: 48%;
			margin-top: 50px;

			&:nth-of-type(1),
			&:nth-of-type(2) {
				margin-top: 0;
			}
		}

		p {
			margin-top: 15px;

			@if $type ==sp {
				text-align: left;
			}
		}
	}
}

.lc3wide {
	@if $type ==pc {
		justify-content: space-between;
	}

	.listItem:not(:nth-child(3n)) {
		@if $type ==pc {
			margin-right: 10px;
		}
	}

	.listItem {
		@if $type ==sp {
			text-align: center;
		}

		@if $type ==pc {
			width: calc(33.3% - 9px);
		}
	}

	a {
		text-decoration: none;
		transition: opacity 0.25s;

		&:hover {
			opacity: 0.75;
		}
	}


}




//news section

.newsSec {
	.inner {
		.newsH2Wrap {
			@if $type ==sp {
				display: flex;
				justify-content: space-between;
				align-items: baseline;
			}

			.h2TitleWrap {
				@if $type ==sp {
					display: flex;
					justify-content: flex-end;
					gap: 10px;
					align-items: baseline;
					margin-inline: unset;

				}

				.engh2 {
					@if $type ==sp {
						font-size: 24px;
					}
				}

				h2 {
					@if $type ==sp {
						font-size: 12px;
					}
				}
			}
		}
	}
}

.newsListArea {
	@if $type ==pc {
		margin-top: 30px;
	}

	.newsItem {
		a {
			@if $type =="" {
				display: flex;
				position: relative;
				display: block;
				text-decoration: none;
				color: #000;
			}

			@if $type ==sp {
				flex-direction: column;
				padding-top: 14px;
				padding-bottom: 14px;
				border-bottom: solid 1px #aaa;

			}

			@if $type ==pc {
				position: relative;
				border-radius: 10px;
				align-items: center;
				padding: 23px 40px 23px 30px;
				transition: 0.2s ease-in-out;
			}

			&::before {
				@if $type ==pc {
					content: "";
					position: absolute;
					background: #aaa;
					height: 1px;
					width: 98%;
					right: 0;
					bottom: 0px;
					transition: 0.2s ease-in-out;
				}
			}

			.textArea {
				@if $type =="" {
					display: flex;
				}

				@if $type ==sp {
					flex-direction: column;
				}

				@if $type ==pc {
					gap: 44px;
				}

				.date {
					@if $type =="" {
						color: $c_base;
						letter-spacing: 0.06em;
						@include fontEn;
						font-weight: 900;
						font-style: italic;
					}

					@if $type ==sp {
						font-size: 11px;
						letter-spacing: 0.15em;
					}

					@if $type ==pc {
						font-size: 16px;
						min-width: 82px;
					}
				}

				.title {
					@if $type =="" {
						font-weight: 500;
					}

					@if $type ==sp {
						margin-top: 4px;
						font-size: 13px;
					}

					@if $type ==pc {
						font-size: 16px;
					}
				}

				.cat {
					color: #fff;
					display: flex;
					justify-content: center;
					align-items: center;

					@if $type ==sp {
						font-size: 10px;
						padding: 4px 5px;
						height: 20px;
					}

					@if $type ==pc {
						font-size: 11px;
						width: 126px;
						height: 30px;
					}
				}
			}

			.c-arrow {
				@if $type =="" {
					border-radius: 50%;
					visibility: hidden;

				}

				@if $type ==sp {
					display: block;
					width: 20px;
					height: 20px;
					position: relative;
				}

				@if $type ==pc {
					position: absolute;
					width: 27px;
					height: 27px;
					top: 50%;
					right: 20px;
					transform: translateY(-50%);
					display: inline-block;
					transition: 0.2s ease-in-out;
				}

				&::before {
					@if $type ==sp {
						content: '';
						width: 20px;
						height: 20px;
						background: $c_base;
						border-radius: 50%;
						position: absolute;
						margin: auto;
					}

					@if $type ==pc {
						content: '';
						width: 27px;
						height: 27px;
						background: $c_base;
						border-radius: 50%;
						position: absolute;

					}
				}

				&::after {
					@if $type ==sp {
						content: '';
						width: 6px;
						height: 6px;
						border: 0;
						border-top: solid 2px #fff;
						border-right: solid 2px #fff;
						position: absolute;
						top: 11px;
						left: 5px;
						transform: rotate(45deg) translate(-50%, -50%);
						margin: auto;
						border-radius: 2px;
					}

					@if $type ==pc {
						content: '';
						width: 6px;
						height: 6px;
						border: 0;
						border-top: solid 2px #fff;
						border-right: solid 2px #fff;
						transform: rotate(45deg);
						position: absolute;
						top: 0;
						left: 8px;
						bottom: 0;
						margin: auto;
						border-radius: 2px;
					}
				}
			}

			&:hover,
			&:focus {
				box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);

				.c-arrow {
					visibility: visible;
				}

				&::before {
					@if $type ==pc {
						visibility: hidden;
					}
				}
			}
		}


	}
}


//pager
.pager {

	@if $type =="" {
		text-align: center;
		margin-inline: auto;
		margin-top: 40px;
	}

	ul {
		@if $type =="" {
			display: flex;
			justify-content: center;
		}

		@if $type ==sp {
			gap: 30px;
		}

		@if $type ==pc {
			gap: 30px;
		}

		li {
			a {
				text-decoration: none;

				&.link_before {
					color: $c_base;
				}

				&.link_after {
					color: $c_base;
				}
			}

			span {
				&.current_page {
					@if $type =="" {
						position: relative;
						color: #fff;
						z-index: 1;

						&::before {
							position: absolute;
							content: "";
							background: $c_base;
							width: 30px;
							height: 30px;
							border-radius: 50px;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							z-index: -1;
						}
					}
				}

			}

			&:hover,
			&:focus {

				@if $type ==pc {
					opacity: 0.7;
				}
			}
		}
	}
}





.s-moreBtn {
	@if $type ==pc {
		margin-top: 24px !important;
	}
}

.newsMoreBtn {
	@if $type ==sp {
		margin-top: 30px;
	}

	.s-moreBtn {
		@if $type ==sp {
			margin-left: auto;
		}

	}
}

.commonContactArea {
	@if $type ==sp {
		padding-top: 60px;
		padding-bottom: 62px;
	}

	@if $type ==pc {
		padding-top: 80px;
		padding-bottom: 70px;
	}

	.inner {
		.dialArea {
			@if $type =="" {
				max-width: 934px;
				background: $c_bg_gray;

				border-radius: 10px;
				margin-inline: auto;
			}

			@if $type ==sp {
				margin-top: 30px;
				padding: 16px 58px 8px;
			}

			@if $type ==pc {
				margin-top: 40px;
				padding: 20px 40px 20px;
			}

			.title {
				@if $type =="" {
					color: #fff;
					font-weight: 500;
					background: #449ab6;
					text-align: center;
					margin-inline: auto;
				}

				@if $type ==sp {
					font-size: 13px;
					border-radius: 5px;
					max-width: 198px;
					width: 100%;
				}

				@if $type ==pc {
					font-size: 18px;
					padding: 5px 40px;
					max-width: 390px;
					border-radius: 5px;
				}
			}

			a {
				@if $type =="" {
					font-weight: 900;
					margin-inline: auto;
					text-align: center;
					display: block;
					@include fontEn;
					font-style: italic;
					letter-spacing: 0.06em;
					text-decoration: none;
					color: #000;

				}

				@if $type ==sp {
					font-size: 30px;
					margin-top: -3px;
				}

				@if $type ==pc {
					font-size: 44px;
				}
			}

			.dialText {
				@if $type =="" {
					text-align: center;
				}

				@if $type ==sp {
					margin-top: -8px;
					line-height: 1.84;
					letter-spacing: 0.06em;
				}

				@if $type ==pc {
					margin-top: -12px;
				}

				.jpText {
					@if $type ==sp {
						font-size: 13px;
						margin-top: -8px;
					}

					@if $type ==pc {
						font-size: 17px;
					}
				}

				.stText {
					@if $type ==sp {
						font-size: 13px;
					}

					@if $type ==pc {
						font-size: 17px;
					}

					>span {
						@if $type =="" {
							@include fontEn;
							font-weight: 600;
							display: inline-block;
						}

						@if $type ==sp {}

						@if $type ==pc {
							font-size: 18px;
						}
					}
				}
			}
		}
	}
}


*+.navBtnWrap {
	@if $type ==pc {
		margin-top: 34px;
	}
}

.navBtnWrap {
	@if $type =="" {
		position: relative;
	}

	@if $type ==sp {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 24px;
		margin-top: 16px;
	}

	@if $type ==pc {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 18px;
	}

	&.center {
		@if $type =="" {
			justify-content: center !important;
		}
	}

	li {
		@if $type =="" {
			border-radius: 10px;
			background: #fff;
			box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.2);
		}

		@if $type ==sp {
			width: 100%;
		}

		@if $type ==pc {
			width: calc((100% - 36px)/3);
		}

		a {
			@if $type =="" {
				text-decoration: none;
				color: $c_text;
				font-weight: 500;
				display: block;
				border-radius: 10px;
				position: relative;
				height: 100%;
			}

			@if $type ==sp {
				font-size: 15px;
				padding: 10px 13px;
				letter-spacing: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 12px;

			}

			@if $type ==pc {
				font-size: 19px;
				padding: 23px 40px;
				letter-spacing: 0.06em;
			}

			.c-arrow {
				@if $type =="" {}

				@if $type ==sp {
					display: block;
					width: 20px;
					height: 20px;
					position: relative;
				}

				@if $type ==pc {
					position: absolute;
					right: 46px;
					top: 50%;
					transform: translateY(-50%);
					display: inline-block;
				}

				&::before {
					@if $type ==sp {
						content: '';
						width: 20px;
						height: 20px;
						background: $c_base;
						border-radius: 50%;
						position: absolute;
						margin: auto;
					}

					@if $type ==pc {
						content: '';
						width: 27px;
						height: 27px;
						background: $c_base;
						border-radius: 50%;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						margin: auto;
					}
				}

				&::after {

					position: absolute;
					content: "";
					background: url(../images/common/arrow-white.svg);

					transform: translate(-50%, -50%);
					background-repeat: no-repeat;
					background-size: contain;
					aspect-ratio: 83/150;

					@if $type ==sp {
						width: 4px;
						height: auto;
						top: 50%;
						left: 50%;
					}

					@if $type ==pc {
						width: 6px !important;
						height: auto;
						top: calc(0px + 50%);
						left: calc(14px + 50%);
					}
				}

				&.forLink {
					&::after {
						@if $type =="" {
							transform: rotate(90deg) !important;
							background-repeat: no-repeat;
						}

						@if $type ==sp {
							top: 7px !important;
							left: 8px !important;
						}

						@if $type ==pc {
							top: -5px !important;
							left: 9px !important;
						}



					}
				}
			}

			&:hover,
			&:focus {
				opacity: .7;
			}

			&.external {
				&::after {
					@if $type ==sp {
						bottom: 18px;
						top: 50%;
						transform: translateY(-50%);
						width: 24px;
						height: 24px;
						right: 8px;
					}

					@if $type ==pc {
						right: 18px;
						width: 30px;
						top: 14px;
					}
				}

			}

		}
	}

	&.mini {
		@if $type =="" {
			display: flex;

		}

		@if $type ==sp {
			justify-content: center;
		}

		@if $type ==pc {
			gap: 40px;
			row-gap: 30px;
			justify-content: flex-start;
			flex-wrap: wrap;
		}

		li {
			@if $type ==pc {
				width: calc((100% - 80px)/3);
				margin-right: 0;
				min-width: 262px;
			}


			a {
				@if $type =="" {
					font-weight: 600;
				}

				@if $type ==pc {
					padding: 14px 52px 14px 32px;
					font-size: 18px;
				}
			}
		}


	}




	&.col3 {
		@if $type ==sp {}

		li {
			@if $type ==sp {
				width: 100%;
			}

			@if $type ==pc {
				width: calc((100% - 80px)/3);
			}

			a {
				@if $type =="" {
					background: #2e2e2e;
					color: #fff;
				}

				.c-arrow {
					&::before {
						background: #fff;
					}

					&::after {
						@if $type ==sp {
							content: '';
							width: 4px;
							height: auto;
							position: absolute;
							background: url(../images/common/arrow.svg);
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							aspect-ratio: 83/150;
							background-size: contain;
							background-repeat: no-repeat;
						}

						@if $type ==pc {
							content: '';
							position: absolute;
							width: 9px;
							height: auto;
							background: url(../images/common/arrow.svg);
							top: 50%;
							left: calc(50% + 0px);
							transform: translate(-50%, -50%);
							aspect-ratio: 83/150;
							background-size: contain;
							background-repeat: no-repeat;

						}
					}
				}
			}
		}
	}

	&.col2 {
		li {
			@if $type ==pc {
				width: calc((100% - 18px)/2);
				max-width: 340px;
			}

			a {
				@if $type =="" {
					background: #2e2e2e;
					color: #fff;
				}

				.c-arrow {
					&::before {
						background: #fff;
					}

					&::after {
						@if $type =="" {}

						@if $type ==sp {
							content: '';
							width: 4px;
							height: auto;
							position: absolute;
							background: url(../images/common/arrow.svg);
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							aspect-ratio: 83/150;
							background-size: contain;
							background-repeat: no-repeat;

						}

						@if $type ==pc {
							content: '';
							position: absolute;
							width: 9px;
							height: auto;
							background: url(../images/common/arrow.svg);
							top: 50%;
							// left: calc(50% + 0px);
							transform: translate(-50%, -50%);
							aspect-ratio: 83/150;
							background-size: contain;
							background-repeat: no-repeat;

						}
					}
				}
			}
		}
	}
}

.navBtnBlock {
	@if $type =="" {
		background: #f3f3f3;
		border-radius: 10px;
	}

	@if $type ==sp {
		padding: 20px 20px;
		margin-top: 40px;
	}

	@if $type ==pc {
		padding: 32px 22px;
		margin-top: 80px;
	}

	.navBtnWrap {
		@if $type =="" {
			margin-inline: auto;
		}

		@if $type ==pc {}
	}
}

.navBtnWrap+p {
	@if $type ==sp {
		margin-top: 52px;
	}

	@if $type ==pc {
		margin-top: 42px;
	}
}


*+.navCardWrap {
	@if $type ==sp {
		padding-top: 20px;
	}

	@if $type ==pc {
		margin-top: 52px;

	}
}


.navCardWrap {
	@if $type =="" {
		display: flex;

	}

	@if $type ==sp {
		gap: 20px;
		flex-direction: column;
	}

	@if $type ==pc {
		flex-wrap: wrap;
		gap: 24px;
	}

	.navCardItem {
		@if $type =="" {
			border-radius: 10px;
			background: #fff;
			box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
		}

		@if $type ==pc {
			width: calc((100% - 24px)/2);
		}

		a {
			@if $type =="" {
				border-radius: 10px;
				display: block;
				position: relative;
				height: 100%;
				text-decoration: none;
				color: #000;
			}

			@if $type ==pc {
				transition: all 0.3s;
			}

			.title {
				@if $type =="" {
					color: #fff;
					font-weight: 500;
					background: $c_base;
					border-radius: 10px 10px 0 0;
					display: flex;
					align-items: center;
					position: relative;
				}

				@if $type ==sp {
					font-size: 16px;
					padding: 8px 16px 8px;
				}

				@if $type ==pc {
					font-size: 18px;
					padding: 6px 55px 6px 40px;
					line-height: 1.2;
					height: 70px;
				}

				&.forExternal {
					&::before {
						@if $type =="" {
							content: "";
							background: url(../images/common/external_icon_white.svg);
							position: absolute;
							background-size: contain;
							aspect-ratio: 1/1;
						}

						@if $type ==sp {
							width: 20px;
							height: auto;
							top: 14px;
							right: 2px;
						}

						@if $type ==pc {
							right: 20px;
							top: 20px;
							width: 30px;
							height: auto;
						}
					}
				}


			}

			*+.cardText {
				margin-top: 0;
			}

			.cardText {
				@if $type =="" {
					line-height: 1.75;
					font-weight: 500;
					border-radius: 0px 0px 10px 10px;
				}

				@if $type ==sp {
					font-size: 14px;
					padding: 10px 16px 32px;
					border-radius: 0px 0px 5px 5px;
				}

				@if $type ==pc {
					font-size: 16px;
					padding: 20px 40px 24px;
					border-radius: 0px 0px 10px 10px;
				}
			}

			.c-arrow {
				@if $type =="" {
					position: absolute;
					display: inline-block;
					right: 48px;
				}

				@if $type ==sp {
					bottom: 26px;
					right:
						40px;
				}

				@if $type ==pc {
					bottom: 30px;
				}

				&::before {
					content: '';
					background: $c_base;
					border-radius: 50%;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					margin: auto;

					@if $type ==sp {
						width: 20px;
						height: 20px;
					}

					@if $type ==pc {
						width: 27px;
						height: 27px;
					}
				}

				&::after {
					@if $type =="" {
						content: "";
						position: absolute;
						background: url(../images/common/arrow-white.svg);
						background-repeat: no-repeat;
						background-size: contain;
						top: calc(50% + 1px);
						left: calc(14px + 50%);
						transform: translate(-50%, -50%);
						width: 6px;
						height: auto;
						aspect-ratio: 83/150;
					}

					@if $type ==sp {
						top: 50%;
						left: calc(11px + 50%);
					}
				}
			}

			&:hover,
			&:focus {
				@if $type ==pc {
					opacity: 0.7;
				}
			}
		}
	}

	&.hasLine {
		@if $type ==sp {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				background: $c_base;
				height: 1px;
				width: 100%;
				left: 0;
				top: 0;
			}
		}




	}
}

p+.navCardWrap {
	@if $type ==pc {
		margin-top: 40px;
	}
}



//各種保険バナーリンク
p+.bannerWrap {
	@if $type ==sp {
		margin-top: 40px;
	}

	@if $type ==pc {
		margin-top: 70px;
	}
}

.bannerWrap {
	@if $type ==sp {
		display: flex;
		flex-direction: column;
		margin-inline: auto;
		gap: 30px;
		align-items: center;
	}

	@if $type ==pc {
		display: flex;
		max-width: 934px;
		margin-inline: auto;
		gap: 34px;
	}

	&.center {
		@if $type =="" {
			justify-content: center;
		}
	}
}

.commonBanner {
	@if $type =="" {
		display: flex;
		box-shadow: 0px 10px 10px -5px rgba(0 91 42 /20%);
		border-radius: 10px;
		text-decoration: none !important;
	}

	@if $type ==sp {
		width: 335px;
	}

	@if $type ==pc {
		max-width: 450px;
		width: 100%;
		transition: all .3s;
	}

	&:hover,
	&:focus {
		opacity: .7;
	}

	p {
		@if $type =="" {
			width: 50%;
			border-radius: 10px 0 0 10px;
			margin-top: 0;
		}

		&.green {
			background: $c_base;
		}

		&.skyblue {
			background: #449ab6;
		}

		&.orange {
			background: #f3961e;
		}

		&.chiken-blue {
			background: #3363ab;
		}

		>img {
			width: 100%;
			clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
		}
	}

	div {
		@if $type =="" {
			color: #fff;
			font-weight: 500;
			text-align: left;
			display: flex;
			align-items: center;
			width: 50%;
			border-radius: 0 10px 10px 0px;
			background: $c_base;
			position: relative;
			z-index: 2;

		}

		@if $type ==sp {
			font-size: 16px;
			line-height: 1.34;
			padding-left: 4px;
			gap: 18px;
		}

		@if $type ==pc {
			font-size: clamp(16px, 2vw, 18px);
			line-height: 1.31;
		}

		>span {
			@if $type ==pc {
				max-width: 165px;
			}
		}



		&.orange {
			background: #f3961e;
			position: relative;
			z-index: 2;

		}

		&.chiken-blue {
			background: #3363ab;
		}

		&.skyblue {
			background: #449ab6;
			position: relative;
			z-index: 2;

		}

		&.green {
			background: $c_base;
			position: relative;
			z-index: 2;


		}

		&.chiken-blue {
			background: #3363ab;
			position: relative;
			z-index: 2;

		}

		.c-arrow {
			@if $type ==sp {
				position: absolute;
				display: inline-block;
				right: min(1.85vw, 20px);
				width: 20px;
				height: 20px;
				top: 53%;
				transform: translateY(-50%);
			}

			@if $type ==pc {
				position: absolute;
				display: inline-block;
				right: min(1.25vw, 20px);
				width: 27px;
				height: 27px;
				top: 53%;
				transform: translateY(-50%);
			}

			&::before {
				content: '';
				width: 27px;
				height: 27px;
				background: #fff;
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				margin: auto;

				@if $type ==sp {
					width: 20px;
					height: 20px;
				}
			}

			&::after {
				@if $type =="" {
					content: "";
					position: absolute;
					left: calc(1px + 50%);
					transform: translate(-50%, -50%);
					aspect-ratio: 83/150;
				}

				@if $type ==sp {
					width: 4px;
					height: auto;
					top: 50%;
				}

				@if $type ==pc {
					width: 6px;
					height: auto;
					top: calc(50% + 0px);
				}

			}

			&.skyblue {
				&::after {
					background: url(../images/common/arrow-lightblue.svg);
					background-repeat: no-repeat;
					background-size: contain;
					aspect-ratio: 83/150;

				}
			}

			&.lightgray {
				&::after {
					background: url(../images/common/arrow-lightgray.svg);
					background-repeat: no-repeat;
					background-size: contain;
					aspect-ratio: 83/150;

				}
			}

			&.orange {
				&::after {
					background: url(../images/common/arrow-orange.svg);
					aspect-ratio: 83/150;
					background-repeat: no-repeat;
					background-size: contain;

				}
			}

			&.green {
				&::after {
					background: url(../images/common/arrow-green.svg);
					aspect-ratio: 83/150;
					background-repeat: no-repeat;
					background-size: contain;

				}
			}

			&.c-hojin {
				&::after {
					background: url(../images/common/arrow-lightgreen.svg);
					aspect-ratio: 83/150;
					background-repeat: no-repeat;
					background-size: contain;

				}
			}
		}
	}
}


.hasBorder {
	@if $type =="" {
		border-top: solid 1px $c_base;
	}

	@if $type ==pc {
		padding-top: 40px;
	}
}


//small morebtn
.s-moreBtn {
	@if $type =="" {
		font-weight: 600;
		border-radius: 8px;
		display: block;
		text-decoration: none;
		position: relative;
	}

	@if $type ==sp {
		color: #fff;
		background: $c_base;
		font-size: 11px;
		padding-left: 16px;
		width: 130px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-block: 4px;
	}

	@if $type ==pc {
		color: #fff;
		background: $c_base;
		font-size: 16px;
		padding-left: 16px;
		padding-right: 40px;
		padding-block: 11px;
		max-width: 160px;
		margin-left: auto;
		margin-top: 14px;
		transition: all 0.3s;
	}

	.c-arrow {
		@if $type ==sp {
			position: absolute;
			width: 12px;
			height: 12px;
			right: 10px;
		}

		@if $type ==pc {
			position: absolute;
			display: inline-block;
			right: 12px;
			top: 53%;
			width: 20px;
			height: 20px;
			transform: translateY(-50%);
		}

		&::before {
			content: '';
			width: 20px;
			height: 20px;
			background: #fff;
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto;

			@if $type ==sp {
				width: 12px;
				height: 12px;
			}
		}

		&::after {
			content: '';
			background: url(../images/common/arrow-green.svg);
			position: absolute;
			top: 50%;
			left: calc(0px + 50%);
			transform: translate(-50%, -50%);
			width: 5px;
			height: auto;

			background-size: contain;
			background-repeat: no-repeat;
			aspect-ratio: 83/150;


			@if $type ==sp {
				width: 3px;
				height: auto;
				left: 50%;
			}
		}
	}

	&:hover,
	&:focus {
		@if $type ==pc {
			opacity: 0.7;
		}
	}
}





// kaito sample

h2.greenLabel {
	@if $type =="" {
		background: $c_base;

		color: #fff;
		letter-spacing: 0.06em;
		line-height: 1.95;
		font-weight: 500;
	}

	@if $type ==sp {
		font-size: 16px;
		padding: 12px 20px 12px 20px;
		margin: 45px calc(50% - 50vw) 0;
		width: 100vw;

	}

	@if $type ==pc {
		font-size: 22px;
		padding: 5px 30px;
		margin-top: 40px;
		border-radius: 6px;
	}

	&+h3 {
		@if $type ==pc {
			margin-top: 26px;
		}
	}

	&+.cardWrap {
		@if $type ==sp {
			margin-top: 30px;
		}

		@if $type ==pc {
			margin-top: 40px;
		}
	}
}



h2.greenLabel+p {
	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 27px;
	}
}



h3 {
	@if $type =="" {
		border-radius: 6px;
		color: #000;
		letter-spacing: 0.06em;
		line-height: 1.75;
		font-weight: 600;
		position: relative;
	}

	@if $type ==sp {
		font-size: 15px;
		padding-left: 16px;
		margin-top: 26px;
	}

	@if $type ==pc {
		font-size: 20px;
		padding-left: 24px;
		margin-top: 30px;
	}

	&::before {
		@if $type =="" {
			content: "";
			background: $c_base;
			height: 100%;
			position: absolute;
		}

		@if $type ==sp {
			width: 4px;
			left: 0px;
			top: 0px;
		}

		@if $type ==pc {
			width: 7px;
			left: 0px;
			top: 4px;
			height: 30px;
		}
	}
}



h4 {
	@if $type =="" {
		font-weight: 600;
		letter-spacing: 0.06em;
		color: $c_base;
		line-height: 1.75;
	}

	@if $type ==sp {
		font-size: 16px;
		margin-top: 12px;
	}


	@if $type ==pc {
		font-size: 20px;
		margin-top: 22px;
	}
}

h4+p {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 8px;

	}

	@if $type ==pc {
		margin-top: 8px;

	}
}

h5 {
	@if $type =="" {
		font-weight: 600;
		line-height: 2.18;
		letter-spacing: 0.06em;
	}

	@if $type ==sp {
		margin-top: 8px;
		font-size: 16px;
	}

	@if $type ==pc {
		margin-top: 45px;
		font-size: 18px;
	}
}

h5+p {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 8px;

	}

	@if $type ==pc {
		margin-top: 12px;
	}
}

.capImg {
	@if $type =="" {
		max-width: 860px;
		margin-inline: auto;
	}

	@if $type ==sp {
		margin-top: 30px;
	}

	@if $type ==pc {
		margin-top: 36px;
	}

	img {}
}


.capText {
	@if $type =="" {
		line-height: 1.86;
		font-weight: 500;
		letter-spacing: 0.06em;
	}

	@if $type ==sp {
		margin-top: 12px;
		font-size: 13px;
	}

	@if $type ==pc {
		margin-top: 12px;
		font-size: 15px;
	}
}

*+.cardWrap {
	@if $type ==sp {
		margin-top: 30px;
	}
	@if $type ==pc {
		margin-top: 60px;
	}
}

.cardWrap {
	@if $type =="" {
		display: flex;
		flex-wrap: wrap;
	}

	@if $type ==sp {
		flex-direction: column;
		gap: 45px;
		align-items: center;
	}

	@if $type ==pc {
		row-gap: 30px;
	}

	.card {
		@if $type =="" {
			display: flex;
			background: #f2f2f2;
			border-radius: 10px;
			position: relative;
		}

		@if $type ==sp {
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 10px;
			padding: 12px 20px;
			max-width: 500px;
			width: 75%;
		}

		@if $type ==pc {
			justify-content: flex-start;
			align-items: center;
			flex-direction: column;
			padding: 10px;
			margin-right: 36px;
			flex-direction: column;
			width: calc((100% - 108px)/4);
			padding: 12px 20px;
		}

		.title {
			@if $type =="" {
				color: $c_base;
				font-weight: 600;
				position: relative;
				margin-top: 0;
			}

			@if $type ==sp {
				font-size: 16px;
				text-align: center;
			}

			@if $type ==pc {
				font-size: 20px;
				text-align: center;
			}

			.mini {
				@if $type ==pc {
					font-size: 15px;
					display: block;
				}
			}

			.kome {
				@if $type =="" {
					position: absolute;
					width: 30px;
					font-weight: 600;
				}

				@if $type ==pc {
					font-size: 10px;
				}
			}
		}



		.hasBg {
			@if $type =="" {
				background: #fff;
				border-radius: 50%;
				width: 78px;
				height: 78px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			@if $type ==sp {
				margin-top: 12px;
			}

			@if $type ==pc {
				margin-top: 20px;
			}

			img {
				@if $type =="" {
					max-width: 32px;
					aspect-ratio: 1/1;
				}
			}
		}



		.text {
			@if $type =="" {
				font-weight: 500;
				display: flex;
			}

			@if $type ==sp {
				text-align: center;
				font-size: 14px;
				line-height: 1.5;
				margin-top: 12px;
			}

			@if $type ==pc {
				margin-top: 16px;
				align-items: flex-start;
				line-height: 1.56;
			}

			.num {
				@if $type =="" {
					font-weight: 600;
					color: $c_base;
					position: relative;
				}

				@if $type ==pc {
					font-size: 20px;

					top: -4px;
				}
			}
		}

		&.next {
			&::before {
				content: "";
				position: absolute;
				border: 10px solid transparent;
				border-left: 13px solid $c_base;

				@if $type ==sp {
					transform: rotate(90deg);
					bottom: -40px;
					left: auto;
					right: auto;
				}

				@if $type ==pc {
					right: -35px;
					top: 50%;
					transform: translateY(-50%);

				}
			}

		}

		ul {
			li {

				.num {
					@if $type =="" {
						font-weight: 600;
						color: $c_base;
						position: relative;
					}

					@if $type ==pc {
						font-size: 20px;
					}
				}
			}
		}
	}

	&+p {
		@if $type ==pc {
			margin-top: 34px;
		}

	}

	&.intro {
		@if $type ==pc {
			justify-content: flex-start;
			flex-wrap: wrap;
			gap: 30px;
		}

		li {
			@if $type ==pc {
				padding: 20px;
				width: 194px;
				margin-right: 0;
			}

			.illust {
				aspect-ratio: 1/1;
				background: #fff;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				@if $type ==sp {
					padding: 10px;
				}

				@if $type ==pc {
					padding: 10px;
					width: 135px;
				}

				>img {
					border-radius: 50%;

					&.img1 {
						max-width: 100px;
					}

					&.img2 {
						max-width: 106px;
					}

					&.img3 {
						max-width: 106px;
					}

					&.img4 {
						max-width: 80px;
					}

					&.img5 {
						max-width: 98px;
					}
				}
			}

			.title {
				@if $type ==pc {
					margin-top: 6px;
				}
			}

			.text {
				@if $type ==pc {
					margin-top: -6px;

				}
			}
		}
	}

	&.full {
		@if $type ==pc {
			gap: 56px;
		}

		.card {
			@if $type ==pc {
				width: calc((100% - 112px)/3);
				margin-right: 0;
				padding-bottom: 40px;
			}

			&::before {
				@if $type ==sp {
					right: auto;
					left: auto;
				}

				@if $type ==pc {
					right: -44px !important;
					left: auto !important;
					transform: translateX(0) !important;
				}
			}

			.hasBg {
				@if $type ==pc {
					margin-top: 8px;
				}
			}

			.text {
				@if $type =="" {
					font-weight: 600;
				}

				@if $type ==pc {
					text-align: center;
					margin-top: 10px;
					line-height: 1.56;
				}
			}
		}
	}

	&.col4 {
		@if $type ==pc {
			gap: 30px;
		}

		@if $type ==tb {
			gap: 20px;
		}

		.card {
			@if $type ==pc {
				width: calc((100% - 90px)/4);
				margin-right: 0;
			}

			@if $type ==tb {
				width: calc((100% - 60px)/4);
				margin-right: 0;
			}

			.title {}

			&.next {
				&::before {
					@if $type ==pc {
						right: -32px;
					}

					@if $type ==tb {
						border: 7px solid transparent;
						border-left: 10px solid #005b2a;
						right: -22px;
					}
				}
			}
		}

	}
}




.innerMini {

	@if $type =="" {
		max-width: 920px;
		margin-inline: auto;
	}

	@if $type ==pc {
		margin-top: 16px;
	}
}


//waパーツ 
.qa {
	dt {
		@if $type =="" {
			color: $c_base;
			font-weight: bold;
			position: relative;
		}

		@if $type ==sp {
			font-size: 16px;
			padding-left: 30px;
			padding-top: 10px;
		}

		@if $type ==pc {
			font-size: 18px;
			line-height: 1.94;
			padding-left: 40px;
			padding-top: 20px;
		}

		&::before {
			@if $type =="" {
				content: 'Q';
				position: absolute;
				left: 0;
				@include fontEn;
				font-weight: 600;
			}

			@if $type ==sp {
				font-size: 20px;
				top: 7px;
			}

			@if $type ==pc {
				content: 'Q';
				position: absolute;
				left: 0;
				@include fontEn;
				font-size: 30px;
				top: 7px;
				font-weight: 600;
			}
		}
	}

	dd {
		@if $type =="" {
			position: relative;
			border-bottom: solid 1px #aaa;
		}

		@if $type ==sp {
			font-size: 16px;
			padding-bottom: 10px;
			padding-left: 30px;
			margin-top: 20px;
		}

		@if $type ==pc {
			font-size: 16px;
			padding-left: 40px;
			margin-top: 20px;
			padding-bottom: 20px;
		}

		&::before {
			@if $type =="" {
				content: 'A';
				position: absolute;
				left: 0;
				@include fontEn;
				font-weight: 600;
			}

			@if $type ==sp {

				font-size: 20px;
				top: -3px;

			}

			@if $type ==pc {
				font-size: 30px;
				top: -10px;

			}
		}
	}


}

.anchorList {
	ul {
		@if $type =="" {
			display: flex;
			flex-wrap: wrap;
		}

		@if $type ==sp {
			row-gap: 20px;
			gap: 20px;
		}

		@if $type ==pc {
			row-gap: 30px;
			gap: 30px;
		}

		li {
			@if $type =="" {
				border-bottom: solid 1px $c_base;
			}

			@if $type ==sp {
				width: calc((100% - 20px) / 2);
			}

			@if $type ==pc {
				width: calc((100% - 60px)/ 3);
			}

			a {
				@if $type =="" {
					text-decoration: none;
					color: $c_text;
					display: block;
					font-weight: 600;
					position: relative;
				}

				@if $type ==sp {
					padding: 10px 38px 10px 16px;
				}

				@if $type ==pc {
					padding: 14px 40px 14px 20px;
					font-size: 18px;
				}

				&::before {
					@if $type =="" {
						content: "";
						position: absolute;
						background: url(../images/common/arrow-green.svg);
						background-size: contain;
						background-repeat: no-repeat;
						width: auto;
						aspect-ratio: 83/150;
						transform: rotate(90deg);
						transition: all 0.3s;
					}

					@if $type ==sp {
						height: 16px;
						right: 14px;
						top: 15px;
						width: 7px;
					}

					@if $type ==pc {
						width: 8px;
						height: auto;
						right: 20px;
						top: 22px;
					}
				}

				&:hover,
				&:focus {
					@if $type ==pc {
						&::before {
							top: 30px;
						}
					}
				}
			}
		}
	}
}



//バナー画像を囲う用のリスト
*+.bannerList {
	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 36px;
	}
}

.bannerList {
	&.col3 {
		@if $type =="" {
			display: flex;
		}

		@if $type ==sp {
			flex-direction: column;
			max-width: 335px;
			margin-inline: auto;
		}

		@if $type ==pc {
			flex-wrap: wrap;
		}

		li {
			@if $type =="" {}

			@if $type ==sp {
				width: 100%;
				padding: 20px;
				border: solid #cdcdcd 1px;
				display: flex;
				justify-content: center;
				align-items: center;
				aspect-ratio: 2 / 1;

				&+li {
					border-top: none;
				}
			}

			@if $type ==pc {
				padding: 30px;
				width: calc(100% / 3);
				border: solid #cdcdcd 1px;
				display: flex;
				justify-content: center;
				align-items: center;
				aspect-ratio: 367/218;

				&:nth-child(n + 4) {
					border-top: none;
				}


				&:not(:first-child) {
					border-left: none;
				}

				&:nth-child(4n) {
					border-left: solid #cdcdcd 1px;
				}

			}

			a {
				@if $type ==sp {
					display: flex;
					justify-content: center;
					align-items: center;

				}

				@if $type ==pc {

					display: flex;
					justify-content: center;
					align-items: center;
					transition: opacity 0.2s;

					&:hover,
					&:focus {
						opacity: 0.7;
					}
				}

				.bannerImg {}
			}

		}
	}
}



// 汎用系↓


/* common class */
@if $type=="" {
	.pointerNone {
		cursor: default;
		text-decoration: none;
	}

	.bold {
		font-weight: bold !important;
	}

	.underline {
		text-decoration: underline !important;
	}

	.txtGreen {
		color: $c_base;
	}


	.marker {
		background: linear-gradient(transparent 60%, #ffd4d5 60%) !important;
	}

	.taC {
		text-align: center !important;
	}

	.taL {
		text-align: left !important;
	}

	.taR {
		text-align: right !important;
	}

	.Right {
		margin-left: auto !important;
	}

	.Left {
		margin-right: auto !important;
	}


	.Center {
		margin: 0 auto !important;
	}

	.ovh {
		overflow: hidden !important;
	}

	.mw100 {
		max-width: 100% !important;
		width: auto !important;
	}

	.w100p {
		width: 100% !important;
	}

	.pT0 {
		padding-top: 0 !important;
	}

	.mB0 {
		margin-bottom: 0 !important;
	}

	.mB5 {
		margin-bottom: 5px !important;
	}

	.mB10 {
		margin-bottom: 10px !important;
	}

	.mB20 {
		margin-bottom: 20px !important;
	}

	.mB30 {
		margin-bottom: 30px !important;
	}

	.mB40 {
		margin-bottom: 40px !important;
	}

	.mB50 {
		margin-bottom: 50px !important;
	}

	.mT0 {
		margin-top: 0 !important;
	}

	.mT5 {
		margin-top: 5px !important;
	}

	.mT10 {
		margin-top: 10px !important;
	}

	.mT20 {
		margin-top: 20px !important;
	}

	.mT30 {
		margin-top: 30px !important;
	}

	.mT40 {
		margin-top: 40px !important;
	}

	.mT50 {
		margin-top: 50px !important;
	}

	.mT60 {
		margin-top: 60px !important;
	}

	.mT70 {
		margin-top: 70px !important;
	}

	.mT80 {
		margin-top: 80px !important;
	}

	.mL0 {
		margin-left: 0px !important;
	}

	.mL5 {
		margin-left: 5px !important;
	}

	.mL10 {
		margin-left: 10px !important;
	}

	.mL20 {
		margin-left: 20px !important;
	}

	.mL30 {
		margin-left: 30px !important;
	}

	.mL40 {
		margin-left: 40px !important;
	}

	.mL50 {
		margin-left: 50px !important;
	}

	.mR0 {
		margin-right: 0px !important;
	}

	.mR5 {
		margin-right: 5px !important;
	}

	.mR10 {
		margin-right: 10px !important;
	}

	.mR20 {
		margin-right: 20px !important;
	}

	.mR30 {
		margin-right: 30px !important;
	}

	.mR40 {
		margin-right: 40px !important;
	}

	.mR50 {
		margin-right: 50px !important;
	}
}

@if $type==sp {
	.mB0 {
		margin-bottom: 0 !important;
	}

	.mB5_sp {
		margin-bottom: 5px !important;
	}

	.mB10_sp {
		margin-bottom: 10px !important;
	}

	.mB20_sp {
		margin-bottom: 20px !important;
	}

	.mB30_sp {
		margin-bottom: 30px !important;
	}

	.mB40_sp {
		margin-bottom: 40px !important;
	}

	.mB50_sp {
		margin-bottom: 50px !important;
	}

	.mT0 {
		margin-top: 0 !important;
	}

	.mT5_sp {
		margin-top: 5px !important;
	}

	.mT10_sp {
		margin-top: 10px !important;
	}

	.mT20_sp {
		margin-top: 20px !important;
	}

	.mT30_sp {
		margin-top: 30px !important;
	}

	.mT40_sp {
		margin-top: 40px !important;
	}

	.mT50_sp {
		margin-top: 50px !important;
	}

	.mL0_sp {
		margin-left: 0px !important;
	}

	.mL5_sp {
		margin-left: 5px !important;
	}

	.mL10_sp {
		margin-left: 10px !important;
	}

	.mL20_sp {
		margin-left: 20px !important;
	}

	.mL30_sp {
		margin-left: 30px !important;
	}

	.mL40_sp {
		margin-left: 40px !important;
	}

	.mL50_sp {
		margin-left: 50px !important;
	}

	.mR0_sp {
		margin-right: 0px !important;
	}

	.mR5_sp {
		margin-right: 5px !important;
	}

	.mR10_sp {
		margin-right: 10px !important;
	}

	.mR20_sp {
		margin-right: 20px !important;
	}

	.mR30_sp {
		margin-right: 30px !important;
	}

	.mR40_sp {
		margin-right: 40px !important;
	}

	.mR50_sp {
		margin-right: 50px !important;
	}

	.size12_sp {
		font-size: 12px !important;
	}

	.size14_sp {
		font-size: 14px !important;
	}

	.size16_sp {
		font-size: 16px !important;
	}

	.size18_sp {
		font-size: 18px !important;
	}

	.size20_sp {
		font-size: 20px !important;
	}

	.w50p_sp {
		width: 50% !important;
	}
}

@if $type==pc {
	.mB0_pc {
		margin-bottom: 0 !important;
	}

	.mB5_pc {
		margin-bottom: 5px !important;
	}

	.mB10_pc {
		margin-bottom: 10px !important;
	}

	.mB20_pc {
		margin-bottom: 20px !important;
	}

	.mB30_pc {
		margin-bottom: 30px !important;
	}

	.mB40_pc {
		margin-bottom: 40px !important;
	}

	.mB50_pc {
		margin-bottom: 50px !important;
	}

	.mB60_pc {
		margin-bottom: 60px !important;
	}

	.mB70_pc {
		margin-bottom: 70px !important;
	}

	.mB80_pc {
		margin-bottom: 80px !important;
	}

	.mT0_pc {
		margin-top: 0 !important;
	}

	.mT5_pc {
		margin-top: 5px !important;
	}

	.mT10_pc {
		margin-top: 10px !important;
	}

	.mT20_pc {
		margin-top: 20px !important;
	}

	.mT30_pc {
		margin-top: 30px !important;
	}

	.mT40_pc {
		margin-top: 40px !important;
	}

	.mT50_pc {
		margin-top: 50px !important;
	}

	.mT60_pc {
		margin-top: 60px !important;
	}

	.mT70_pc {
		margin-top: 70px !important;
	}

	.mT80_pc {
		margin-top: 80px !important;
	}

	.mT90_pc {
		margin-top: 90px !important;
	}

	.mL0_pc {
		margin-left: 0px !important;
	}

	.mL5_pc {
		margin-left: 5px !important;
	}

	.mL10_pc {
		margin-left: 10px !important;
	}

	.mL20_pc {
		margin-left: 20px !important;
	}

	.mL30_pc {
		margin-left: 30px !important;
	}

	.mL40_pc {
		margin-left: 40px !important;
	}

	.mL50_pc {
		margin-left: 50px !important;
	}

	.mR0_pc {
		margin-right: 0px !important;
	}

	.mR5_pc {
		margin-right: 5px !important;
	}

	.mR10_pc {
		margin-right: 10px !important;
	}

	.mR20_pc {
		margin-right: 20px !important;
	}

	.mR30_pc {
		margin-right: 30px !important;
	}

	.mR40_pc {
		margin-right: 40px !important;
	}

	.mR50_pc {
		margin-right: 50px !important;
	}

	.size12_pc {
		font-size: 12px !important;
	}

	.size14_pc {
		font-size: 14px !important;
	}

	.size16_pc {
		font-size: 16px !important;
	}

	.size18_pc {
		font-size: 18px !important;
	}

	.size20_pc {
		font-size: 20px !important;
	}

	.size21_pc {
		font-size: 21px !important;
	}

	.size22_pc {
		font-size: 22px !important;
	}

	.size23_pc {
		font-size: 23px !important;
	}

	.size24_pc {
		font-size: 24px !important;
	}
}