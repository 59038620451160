.sample {
	@if $type =="" {}

	@if $type ==sp {}

	@if $type ==pc {}
}

.bgSample {
	@if $type =="" {
		background: $c_gray !important;
	}
}

.borderSample {
	@if $type =="" {
		border: 2px solid #333;
	}
}

.blockSample {
	@if $type =="" {
		display: block;
	}
}

//mokuji
*+.pageInfo {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 20px;
	}

	@if $type ==pc {
		margin-top: 40px;
	}
}

.pageInfo {
	@if $type =="" {
		box-shadow: 0 0 25px rgba(0, 0, 0, .07);
		background: #fffce9;
		position: relative;
		overflow: hidden;
	}

	@if $type ==sp {
		padding: 66px 26px 26px;
		margin-right: auto;
		margin-left: auto;
		border-radius: 15px;
	}

	@if $type ==pc {
		max-width: 700px;
		padding: 100px 40px 40px;
		margin-right: auto;
		margin-left: auto;
		border-radius: 15px;
	}

	&:after {
		@if $type =="" {
			content: "index";
			display: block;
			color: $c_gray;
			text-align: center;
			font-weight: bold;
			letter-spacing: 0.075em;
			position: absolute;
		}

		@if $type ==sp {
			font-size: 18px;
			top: 25px;
			left: 20px;
		}

		@if $type ==pc {
			font-size: 22px;
			top: 35px;
			left: 40px;
		}
	}

	.pageInfoList {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {}
	}
}

.pageInfoList {
	@if $type =="" {
		counter-reset: number 0;
	}

	@if $type ==pc {}

	>li {
		@if $type =="" {
			padding-left: 2.5em;
			position: relative;
		}

		@if $type ==sp {
			position: relative;
		}

		@if $type ==pc {}

		+li {
			@if $type ==sp {}

			@if $type ==pc {
				margin-top: 8px;
			}
		}

		&:before {
			@if $type =="" {
				counter-increment: number 1;
				content: "0" counter(number) ".";
				display: flex;
				align-items: center;
				justify-content: center;
				@include fontEn;
				font-weight: bold;
				color: $c_gray;
				position: absolute;
				top: 0.2em;
				left: 0;
				z-index: 2;
			}

			@if $type ==sp {}

			@if $type ==pc {}
		}

		&:nth-child(n + 10) {
			&:before {
				@if $type =="" {
					content: counter(number) ".";
				}
			}
		}

		a {
			@if $type =="" {
				display: inline-block;
				text-decoration: none;
				position: relative;
				overflow: hidden;
			}

			@if $type ==sp {
				&:before {
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					background: linear-gradient(transparent 70%, $c_base 70%);
					position: absolute;
					top: 0;
					left: -100%;
					transition: .2s left;
					z-index: 0;
					opacity: .4;
				}
			}

			@if $type ==pc {

				&:hover {
					&:before {
						left: 0;
					}
				}

				&:before {
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					background: linear-gradient(transparent 70%, $c_base 70%);
					position: absolute;
					top: 0;
					left: -100%;
					transition: .2s left;
					z-index: 0;
					opacity: .4;
				}
			}

		}
	}
}

.sampleCloseWrap {
	@if $type =="" {
		display: block;
		width: 40px;
		height: 40px;
		background: $c_base;
		position: relative;
		cursor: pointer;
	}

	@if $type ==sp {}

	@if $type ==pc {}

	>span {
		@if $type =="" {
			display: block;
			width: 20px;
			height: 3px;

			&:nth-child(1) {
				background: #c00;
			}

			&:nth-child(2) {
				background: #00c;
			}
		}
	}

	&.flow2 {
		span {
			@if $type =="" {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				margin: auto;

				&:nth-child(1) {
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					transform: rotate(-45deg);
				}
			}

			@if $type ==sp {}

			@if $type ==pc {}

		}
	}
}

main {
	@if $type ==sp {
		overflow: hidden;
	}

	@if $type ==pc {
		background: #fff;
		font-size: 15px;
	}

	@if $type ==tb {}

	// .contentsWrap {
	// 	@if $type ==sp {
	// 		background: #e1e6e9;
	// 		padding-top: 60px;
	// 		padding-bottom: 60px;
	// 	}

	// 	@if $type ==pc {
	// 		background: $c_bg_gray;
	// 		padding-top: 120px;
	// 		padding-bottom: 120px;
	// 	}
	// }
}

.indexTitle {
	@if $type =="" {
		text-align: center;
		position: relative;
		display: flex;
		align-items: center;
	}

	@if $type ==sp {
		height: 175px;
	}

	@if $type ==pc {
		height: 350px;

	}

	&.mini {
		@if $type ==pc {
			height: 230px;
		}

		.inner {
			h1 {
				@if $type =="" {
					font-weight: 500;

					&:before {
						content: none !important;
					}
				}

				@if $type ==sp {
					padding-left: 0;
					margin-top: -2px;
				}

				@if $type ==pc {
					font-size: 36px;
					padding-left: 0;
				}

			}

			>span {
				@if $type =="" {
					display: block;
					color: #fff;
					font-weight: 500;
					text-align: left;
					position: relative;
				}

				@if $type ==sp {
					font-size: 15px;
					padding-left: 36px;
				}

				@if $type ==pc {
					font-size: 18px;
					padding-left: 40px;
				}

			}
		}
	}

	>.inner {
		@if $type =="" {
			margin-inline: auto;
			width: 100%;
		}

		@if $type ==sp {
			padding-inline: 20px;
		}

		@if $type ==pc {
			max-width: 1102px;
			width: 90%;
		}

		h1 {
			@if $type =="" {
				color: #fff;
				font-weight: 900;
				text-align: left;
				letter-spacing: 0.035em;
			}

			@if $type ==sp {
				font-size: 24px;
				line-height: 1.6;
				padding-left: 40px;
			}

			@if $type ==pc {
				font-size: 48px;
				line-height: 1.6;
				padding-left: 100px;
			}
		}
	}

	&.doctorBg {
		@if $type ==sp {
			background: url(../images/second/doctor-bg_sp.png);
			background-repeat: no-repeat;
			background-size: cover;
		}

		@if $type ==pc {
			background: url(../images/second/doctor-bg.png);
			background-size: cover;
		}

		h1 {
			@if $type =="" {
				position: relative;
			}

			&::before {
				@if $type =="" {
					position: absolute;
					content: "";
					background: url(../images/second/doctor-icon.png);
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}

				@if $type ==sp {
					left: 10px;
					top: 50%;
					transform: translateY(-50%);
					width: 25px;
					height: 46px;

				}

				@if $type ==pc {
					left: 10px;
					top: 50%;
					transform: translateY(-50%);
					width: 72px;
					height: 92px;
				}
			}
		}

		.inner {
			>span {
				&::before {
					@if $type =="" {
						content: "";
						position: absolute;
						display: inline-block;
						background: url(../images/second/doctor-icon.png);
						background-repeat: no-repeat;
						background-size: contain;
						left: 0px;
						top: 0;
						width: 30px;
						height: 30px;
					}

					@if $type ==sp {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
	}

	&.lawyerBg {
		@if $type =="" {
			background: url(../images/second/lawyer-bg.png);
			background-size: cover;
		}

		h1 {
			@if $type =="" {
				position: relative;
			}

			&::before {
				@if $type =="" {
					position: absolute;
					content: "";
					background: url(../images/second/lawyer-icon.png);
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}

				@if $type ==sp {
					left: 10px;
					top: 50%;
					transform: translateY(-50%);
					width: 25px;
					height: 46px;

				}

				@if $type ==pc {
					left: 10px;
					top: 50%;
					transform: translateY(-50%);
					width: 72px;
					height: 92px;
				}
			}
		}

		.inner {
			>span {
				&::before {
					@if $type =="" {
						content: "";
						position: absolute;
						display: inline-block;
						background: url(../images/second/lawyer-icon.png);
						background-repeat: no-repeat;
						background-size: contain;
						left: 0px;
						top: 0;
						width: 30px;
						height: 30px;
					}

					@if $type ==sp {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
	}



	&.chikenBg {
		@if $type =="" {
			background: url(../images/second/chiken-bg.png);
			background-size: cover;
		}

		.inner {
			h1 {
				@if $type =="" {
					position: relative;
				}

				&::before {
					@if $type =="" {
						position: absolute;
						content: "";
						background: url(../images/second/furasko-icon.png);
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
					}

					@if $type ==sp {
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						width: 25px;
						height: auto;
						aspect-ratio: 52/56;
					}

					@if $type ==pc {
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						width: 52px;
						height: auto;
						aspect-ratio: 52/56;
					}
				}
			}

			>span {
				&::before {
					@if $type =="" {
						content: "";
						position: absolute;
						display: inline-block;
						background: url(../images/second/furasko-icon.png);
						background-repeat: no-repeat;
						background-size: contain;
						left: 0px;
						top: 0;
						width: 30px;
						height: 30px;
					}

					@if $type ==sp {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
	}

	&.proBg {
		@if $type ==sp {
			background: url(../images/second/professionals-bg_sp.png);
			background-repeat: no-repeat;
			background-size: cover;
		}

		@if $type ==pc {
			background: url(../images/second/professionals-bg.png);
			background-size: cover;
		}

		.inner {
			h1 {
				@if $type =="" {
					position: relative;
				}

				&::before {
					@if $type =="" {
						position: absolute;
						content: "";
						background: url(../images/second/pro-icon.png);
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
					}

					@if $type ==sp {
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						width: 25px;
						height: auto;
						aspect-ratio: 52/56;
					}

					@if $type ==pc {
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						width: 52px;
						height: auto;
						aspect-ratio: 52/56;
					}
				}
			}

			>span {
				&::before {
					@if $type =="" {
						content: "";
						position: absolute;
						display: inline-block;
						background: url(../images/second/pro-icon.png);
						background-repeat: no-repeat;
						background-size: contain;
						left: 0px;
						top: 0;
						width: 30px;
						height: 30px;
					}

					@if $type ==sp {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
	}

	&.hojinBg {
		@if $type ==sp {
			background: url(../images/second/hojin-bg_sp.png);
			background-repeat: no-repeat;
			background-size: cover;
		}

		@if $type ==pc {
			background: url(../images/second/hojin-bg.png);
			background-size: cover;
		}

		.inner {
			h1 {
				@if $type =="" {
					position: relative;
				}

				&::before {
					@if $type =="" {
						position: absolute;
						content: "";
						background: url(../images/second/hojin-icon.png);
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
					}

					@if $type ==sp {
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						width: 25px;
						height: auto;
						aspect-ratio: 52/56;
					}

					@if $type ==pc {
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						width: 52px;
						height: auto;
						aspect-ratio: 52/56;
					}
				}
			}

			>span {
				&::before {
					@if $type =="" {
						content: "";
						position: absolute;
						display: inline-block;
						background: url(../images/second/hojin-icon.png);
						background-repeat: no-repeat;
						background-size: contain;
						left: 0px;
						top: 0;
						width: 30px;
						height: 30px;
					}

					@if $type ==sp {
						width: 25px;
						height: 25px;
					}
				}
			}
		}
	}

	&.commonBg {
		@if $type ==sp {
			background: url(../images/second/common-bg_sp.png);
			background-size: cover;
			background-repeat: no-repeat;
		}

		@if $type ==pc {
			background: url(../images/second/common-bg.png);
			background-size: cover;
		}

		.inner {
			>span {
				@if $type =="" {
					padding-left: 0 !important;
				}
			}

			h1 {
				@if $type =="" {
					position: relative;
					padding-left: 0;
				}
			}
		}
	}
}

.section {
	&.service {
		@if $type =="" {
			background: url(../images/second/service-bg.png);
			background-size: cover;
		}

		@if $type ==sp {
			padding-bottom: 65px;
		}

		@if $type ==pc {
			padding-bottom: 85px;
		}
	}
}

.service+.section {
	@if $type ==pc {
		padding-top: 85px;
	}
}




article {
	.newsListArea {
		@if $type =="" {
			margin-top: 0;
		}
	}

	.inner {
		@if $type =="" {
			margin-inline: auto;
		}

		@if $type ==sp {
			padding-inline: 20px;
			// padding-top: 40px;
			// padding-bottom: 40px;
		}

		@if $type ==pc {
			width: 90%;
			max-width: 1000px;
			// padding-top: 80px;
			// padding-bottom: 80px;
		}

		.btnWrap {
			@if $type =="" {
				margin-inline: auto;
				text-align: center;

			}

			@if $type ==sp {
				margin-top: 40px;
			}

			@if $type ==pc {
				margin-top: 60px;
			}

			.prevBtn {
				@if $type =="" {
					display: inline-block;
					color: #fff;
					background: $c_base;
					text-align: center;
					text-decoration: none;
					border-radius: 8px;
					font-weight: 600;
					box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
				}

				@if $type ==sp {
					padding: 8px 20px;
				}

				@if $type ==pc {
					padding: 10px 26px;
					font-size: 18px;
				}

				&:hover,
				&:focus {
					@if $type ==pc {
						opacity: 0.7;
					}
				}
			}
		}
	}


	.newsSec {
		.inner {
			.newsListArea {
				.newsItem {
					a {
						text-decoration: none;

						.textArea {

							.infoArea {
								display: flex;
								gap: 20px;
							}
						}

						.date {
							@if $type ==pc {
								min-width: 82px;
							}
						}

						.cat {
							@if $type =="" {
								color: #fff;
								font-size: 11px;
								width: 136px;
							}
						}
					}
				}
			}
		}
	}

}




// 整理番号ページcss
#searchid {
	@if $type ==sp {
		margin-bottom: 20px;
	}
}

#searchbutton {
	@if $type =="" {
		background: $c_base;
		color: #fff;
		border: none;
		padding: 2px 25px;
		font-size: 16px;
		border-radius: 8px;
	}

	@if $type ==pc {}
}

.errmsg {
	@if $type =="" {
		text-align: center;
		font-size: 150%;
	}

	@if $type ==sp {
		font-size: 200%
	}

}

.rtitle {
	@if $type =="" {
		text-align: center;
		font-size: 200%;
	}

	@if $type ==sp {
		font-size: 200%;
	}
}

.rtable {
	@if $type =="" {
		text-align: center;
	}

}

.rtable_row {
	@if $type =="" {
		display: table;
		margin: 0.2em auto;
	}

}

.rtable_col {
	@if $type =="" {
		display: table-cell;
		vertical-align: middle;
		font-size: 150%;
		height: 2.2em;
		padding: 0.2em;
	}

	@if $type ==sp {
		font-size: 120%;
	}

}

.col_caption_row {
	@if $type =="" {
		border: solid 0px;
	}

}

.col_caption_col {
	@if $type =="" {
		border: solid 0px;
		text-align: right;
		width: 3em;
	}

}

.col_result {
	@if $type =="" {
		border: solid 1px #999;
	}

}

.col_stat {
	@if $type =="" {
		width: 9em;
	}

}

.col_date {
	@if $type =="" {
		width: 6em;
	}
}






// import //

// use Slick
//@import "_slick";
//@import "_slick-theme";