@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*サイトカラー暗め*/
/*テキストに使うフォントカラー*/
/*未設定*/
/*
.f_em{ @include fontUsu; }
.f_os{ @include fontOs; }
.f_min{ @include fontMin; }
.f_lc{ @include fontLc; }
*/
.bgSample {
  background: #93989e !important;
}

.borderSample {
  border: 2px solid #333;
}

.blockSample {
  display: block;
}

.pageInfo {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
  background: #fffce9;
  position: relative;
  overflow: hidden;
}
.pageInfo:after {
  content: "index";
  display: block;
  color: #93989e;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.075em;
  position: absolute;
}
.pageInfoList {
  counter-reset: number 0;
}
.pageInfoList > li {
  padding-left: 2.5em;
  position: relative;
}
.pageInfoList > li:before {
  counter-increment: number 1;
  content: "0" counter(number) ".";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  color: #93989e;
  position: absolute;
  top: 0.2em;
  left: 0;
  z-index: 2;
}
.pageInfoList > li:nth-child(n+10):before {
  content: counter(number) ".";
}
.pageInfoList > li a {
  display: inline-block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.sampleCloseWrap {
  display: block;
  width: 40px;
  height: 40px;
  background: #005b2a;
  position: relative;
  cursor: pointer;
}
.sampleCloseWrap > span {
  display: block;
  width: 20px;
  height: 3px;
}
.sampleCloseWrap > span:nth-child(1) {
  background: #c00;
}
.sampleCloseWrap > span:nth-child(2) {
  background: #00c;
}
.sampleCloseWrap.flow2 span {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.sampleCloseWrap.flow2 span:nth-child(1) {
  transform: rotate(45deg);
}
.sampleCloseWrap.flow2 span:nth-child(2) {
  transform: rotate(-45deg);
}

.indexTitle {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
}
.indexTitle.mini .inner h1 {
  font-weight: 500;
}
.indexTitle.mini .inner h1:before {
  content: none !important;
}
.indexTitle.mini .inner > span {
  display: block;
  color: #fff;
  font-weight: 500;
  text-align: left;
  position: relative;
}
.indexTitle > .inner {
  margin-inline: auto;
  width: 100%;
}
.indexTitle > .inner h1 {
  color: #fff;
  font-weight: 900;
  text-align: left;
  letter-spacing: 0.035em;
}
.indexTitle.doctorBg h1 {
  position: relative;
}
.indexTitle.doctorBg h1::before {
  position: absolute;
  content: "";
  background: url(../images/second/doctor-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.indexTitle.doctorBg .inner > span::before {
  content: "";
  position: absolute;
  display: inline-block;
  background: url(../images/second/doctor-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  left: 0px;
  top: 0;
  width: 30px;
  height: 30px;
}
.indexTitle.lawyerBg {
  background: url(../images/second/lawyer-bg.png);
  background-size: cover;
}
.indexTitle.lawyerBg h1 {
  position: relative;
}
.indexTitle.lawyerBg h1::before {
  position: absolute;
  content: "";
  background: url(../images/second/lawyer-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.indexTitle.lawyerBg .inner > span::before {
  content: "";
  position: absolute;
  display: inline-block;
  background: url(../images/second/lawyer-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  left: 0px;
  top: 0;
  width: 30px;
  height: 30px;
}
.indexTitle.chikenBg {
  background: url(../images/second/chiken-bg.png);
  background-size: cover;
}
.indexTitle.chikenBg .inner h1 {
  position: relative;
}
.indexTitle.chikenBg .inner h1::before {
  position: absolute;
  content: "";
  background: url(../images/second/furasko-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.indexTitle.chikenBg .inner > span::before {
  content: "";
  position: absolute;
  display: inline-block;
  background: url(../images/second/furasko-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  left: 0px;
  top: 0;
  width: 30px;
  height: 30px;
}
.indexTitle.proBg .inner h1 {
  position: relative;
}
.indexTitle.proBg .inner h1::before {
  position: absolute;
  content: "";
  background: url(../images/second/pro-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.indexTitle.proBg .inner > span::before {
  content: "";
  position: absolute;
  display: inline-block;
  background: url(../images/second/pro-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  left: 0px;
  top: 0;
  width: 30px;
  height: 30px;
}
.indexTitle.hojinBg .inner h1 {
  position: relative;
}
.indexTitle.hojinBg .inner h1::before {
  position: absolute;
  content: "";
  background: url(../images/second/hojin-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.indexTitle.hojinBg .inner > span::before {
  content: "";
  position: absolute;
  display: inline-block;
  background: url(../images/second/hojin-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  left: 0px;
  top: 0;
  width: 30px;
  height: 30px;
}
.indexTitle.commonBg .inner > span {
  padding-left: 0 !important;
}
.indexTitle.commonBg .inner h1 {
  position: relative;
  padding-left: 0;
}

.section.service {
  background: url(../images/second/service-bg.png);
  background-size: cover;
}

article .newsListArea {
  margin-top: 0;
}
article .inner {
  margin-inline: auto;
}
article .inner .btnWrap {
  margin-inline: auto;
  text-align: center;
}
article .inner .btnWrap .prevBtn {
  display: inline-block;
  color: #fff;
  background: #005b2a;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
}
article .newsSec .inner .newsListArea .newsItem a {
  text-decoration: none;
}
article .newsSec .inner .newsListArea .newsItem a .textArea .infoArea {
  display: flex;
  gap: 20px;
}
article .newsSec .inner .newsListArea .newsItem a .cat {
  color: #fff;
  font-size: 11px;
  width: 136px;
}

#searchbutton {
  background: #005b2a;
  color: #fff;
  border: none;
  padding: 2px 25px;
  font-size: 16px;
  border-radius: 8px;
}

.errmsg {
  text-align: center;
  font-size: 150%;
}

.rtitle {
  text-align: center;
  font-size: 200%;
}

.rtable {
  text-align: center;
}

.rtable_row {
  display: table;
  margin: 0.2em auto;
}

.rtable_col {
  display: table-cell;
  vertical-align: middle;
  font-size: 150%;
  height: 2.2em;
  padding: 0.2em;
}

.col_caption_row {
  border: solid 0px;
}

.col_caption_col {
  border: solid 0px;
  text-align: right;
  width: 3em;
}

.col_result {
  border: solid 1px #999;
}

.col_stat {
  width: 9em;
}

.col_date {
  width: 6em;
}

a {
  color: #005b2a;
  text-decoration: underline;
  text-underline-offset: 5px;
}
a.hasArrow span {
  position: relative;
  padding-left: 20px;
}
a.hasArrow span::after {
  position: absolute;
  content: "";
  background: url(../images/common/arrow-green.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
  width: 6px;
  height: auto;
  left: 0;
  top: 8px;
}
a.external {
  position: relative;
}
a.external::after {
  position: absolute;
  content: "";
  background: url(../images/common/external_icon.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 1/1;
}

.breadcrumbArea ul {
  display: flex;
}
.breadcrumbArea ul li {
  color: #333;
  margin-right: 5px;
  font-weight: 400;
}
.breadcrumbArea ul li:before {
  content: "/";
  display: inline-block;
  margin-right: 3px;
}
.breadcrumbArea ul li:first-child:before {
  content: "";
  display: none;
}
.breadcrumbArea ul li a {
  text-decoration: none;
  color: #333;
}
.breadcrumbArea ul li a:hover {
  text-decoration: underline;
}

.breadcrumbArea + .section {
  padding-top: 0 !important;
}

.section:last-of-type {
  padding-bottom: 0;
}
.section .inner > .greenLabel:first-child {
  margin-top: 0;
}
.section .inner > .pageBigLead:first-child {
  margin-top: 0;
}
.section .inner.semi {
  margin-inline: auto;
}
.pageBigLead {
  color: #333;
  font-weight: 600;
  text-align: center;
  margin-inline: auto;
}
.pageLead {
  color: #333;
  font-weight: 500;
  margin-inline: auto;
}

.h2TitleWrap {
  color: #005b2a;
  margin-inline: auto;
  text-align: center;
  letter-spacing: 0.06em;
}
.h2TitleWrap .engh2 {
  font-weight: 900;
  font-family: "Lato", sans-serif;
  font-style: italic;
  letter-spacing: 0.06em;
}
.h2TitleWrap h2 {
  font-family: "Noto Sans JP", sans-serif;
  color: #005b2a;
  letter-spacing: 0.06em;
  line-height: 1;
}

.h2TitleWrap + p {
  font-weight: 500;
}

h3.title {
  font-weight: 400;
  line-height: 1.4;
  position: relative;
}
h3.title:after {
  content: "";
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  border-left: 2px solid #003500;
  border-right: 2px solid #003500;
  position: absolute;
  top: 0;
  left: 0;
}

.titleGray,
h4.title {
  color: #93989e;
  font-weight: bold;
}

h5.title {
  font-weight: bold;
}

.titleHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titleHeader .titleCenter {
  line-height: 1.4;
}

.titleCenterSub {
  color: #93989e;
  line-height: 1.4;
  font-weight: bold;
}

[class^=listCol].setBtn {
  margin-inline: auto;
  align-items: center;
}
[class^=listCol].setBtn .listItem h4 {
  margin-top: 0;
}
[class^=listCol].hasBox {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
}
[class^=listCol].hasBox .imgArea p img {
  border-radius: 10px;
}

.colImgFixed {
  display: flex;
  /*
  @if $type == tb{
  	flex-direction: column;
  }
  */
}
.colImgFixed > * {
  /*
  		@if $type == tb{
  			width: 100%;

  			&.colText {
  				order: 1;
  				margin-top: 30px;
  			}

  			&.colImg {
  				order: 0;
  			}

  			&:nth-child(odd) {
  				margin-right: 0;
  			}

  			&:nth-child(n + 3) {
  				margin-top: 0;
  			}
  		}
  */
}
.colImgFixed > *.colImg img {
  display: block;
  margin: 0 auto;
}

.commonTable {
  width: 100%;
  text-align: left;
  line-height: 1.25;
}
.commonTable.tableEqual {
  table-layout: fixed;
}
.commonTable > tbody > tr > th,
.commonTable > thead > tr > th {
  vertical-align: top;
  font-weight: bold;
  word-break: break-all;
  background: #edf9e4;
  color: #005b2a;
}
.commonTable > tbody > tr > th[scope=row],
.commonTable > thead > tr > th[scope=row] {
  background: #eee;
}
.commonTable > tbody > tr > td,
.commonTable > thead > tr > td {
  text-align: left;
  vertical-align: middle;
  word-break: break-all;
  background: #fff;
}
.commonTable th.valTop,
.commonTable td.valTop {
  vertical-align: top;
}
.commonTable th.nowrap,
.commonTable td.nowrap {
  white-space: nowrap;
}

.otherTable {
  width: 100%;
  line-height: 1.25;
  text-align: left;
}
.otherTable th {
  background: #edf9e4;
  color: #005b2a;
}
.otherTable th,
.otherTable td {
  border: solid 1px #cfcfcf;
}

ul.commonList > li {
  padding-left: 1.2em;
  position: relative;
}
ul.commonList > li:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #005b2a;
  position: absolute;
}
ul.commonList > li a {
  display: inline-block;
}

ol.commonList {
  counter-reset: number 0;
}
ol.commonList > li {
  padding-left: 1.6em;
  position: relative;
}
ol.commonList > li:before {
  counter-increment: number 1;
  content: counter(number) ".";
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #005b2a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
ol.commonList > li:nth-child(n+10):before {
  content: counter(number) ".";
}
.title + dl.commonList {
  border-top: none !important;
}

dl.commonList {
  border-top: 1px solid #93989e;
}
dl.commonList > div {
  border-bottom: 1px solid #93989e;
}
dl.commonList > div > dt {
  flex: none;
  font-weight: bold;
}
dl.commonList > div > dd > *:nth-of-type(1) {
  margin-top: 0 !important;
}

.col2Btn {
  display: flex;
  justify-content: center;
  gap: 30px;
  text-align: center;
}
.col2Btn .btnWrap {
  margin-top: 0;
}

.listTextCol2 {
  display: flex;
  border: solid 1px #cfcfcf;
}
.listTextCol2 .textArea > ul {
  padding: 0;
}
.listTextCol2 .textArea h5 {
  margin-top: 0;
}
.listTextCol2 .textArea:has(+ .textArea) {
  border-top: 0;
  margin-top: 0;
  border-left: none;
}

.lineBox {
  border: solid 1px #cfcfcf;
  border-radius: 10px;
}

.iconText {
  text-decoration: underline;
  text-underline-offset: 5px;
  display: inline !important;
}
.iconText.pdf {
  position: relative;
}
.iconText.pdf::before {
  content: "";
  position: absolute;
  background: url(../images/second/pdf-icon.svg);
  background-size: contain;
  aspect-ratio: 117/150;
  height: auto;
}
.iconText.excel {
  position: relative;
}
.iconText.excel::before {
  position: absolute;
  background: url(../images/second/excel-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 24px;
}
.iconText.word {
  position: relative;
}
.iconText.word::before {
  position: absolute;
  content: "";
  background: url(../images/second/word-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 30px;
}

.col2NavLink {
  display: flex;
}
.col2NavLink div a {
  background: #2e2e2e;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  position: relative;
}
.col2NavLink div a .arrow {
  position: absolute;
  background: #fff;
  border-radius: 50px;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.col2NavLink div a .arrow::before {
  content: "";
  background: url(../images/common/arrow.svg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 83/150;
  background-repeat: no-repeat;
  background-size: contain;
}

.iframeSize {
  margin-inline: auto;
  max-width: 640px;
  width: 90%;
}
.iframeSize .iframeWrap {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}
.iframeSize .iframeWrap iframe {
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.mapWrap {
  width: 100%;
  margin-top: 1.5em;
  position: relative;
}
.mapWrap iframe {
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.colorBox,
.colorBoxAdd {
  background: #fffce9;
}

.colorBox .colorBoxTitle,
.colorBoxAdd .colorBoxTitle {
  font-weight: bold;
  color: #93989e;
  line-height: 1.5;
}
.taC {
  text-align: center !important;
}

.taL {
  text-align: left !important;
}

.taR {
  text-align: right !important;
}

.pT0 {
  padding-top: 0 !important;
}

.pT10 {
  padding-top: 10px !important;
}

.pT20 {
  padding-top: 20px !important;
}

.pT30 {
  padding-top: 30px !important;
}

.pT40 {
  padding-top: 40px !important;
}

.pT50 {
  padding-top: 50px !important;
}

.pT60 {
  padding-top: 60px !important;
}

.pT70 {
  padding-top: 70px !important;
}

.pB0 {
  padding-bottom: 0px !important;
}

.pB5 {
  padding-bottom: 5px !important;
}

.pB10 {
  padding-bottom: 10px !important;
}

.pB20 {
  padding-bottom: 20px !important;
}

.pB30 {
  padding-bottom: 30px !important;
}

.pB40 {
  padding-bottom: 40px !important;
}

.mB0 {
  margin-bottom: 0 !important;
}

.mB5 {
  margin-bottom: 5px !important;
}

.mB10 {
  margin-bottom: 10px !important;
}

.mB20 {
  margin-bottom: 20px !important;
}

.mB30 {
  margin-bottom: 30px !important;
}

.mB40 {
  margin-bottom: 40px !important;
}

.mB50 {
  margin-bottom: 50px !important;
}

.mB60 {
  margin-bottom: 60px !important;
}

.mB70 {
  margin-bottom: 70px !important;
}

.mB80 {
  margin-bottom: 80px !important;
}

.mT0 {
  margin-top: 0 !important;
}

.mT5 {
  margin-top: 5px !important;
}

.mT10 {
  margin-top: 10px !important;
}

.mT20 {
  margin-top: 20px !important;
}

.mT30 {
  margin-top: 30px !important;
}

.mT40 {
  margin-top: 40px !important;
}

.mT50 {
  margin-top: 50px !important;
}

.mT60 {
  margin-top: 60px !important;
}

.mT70 {
  margin-top: 70px !important;
}

.mT80 {
  margin-top: 80px !important;
}

.mL0 {
  margin-left: 0px !important;
}

.mL5 {
  margin-left: 5px !important;
}

.mL10 {
  margin-left: 10px !important;
}

.mL20 {
  margin-left: 20px !important;
}

.mL30 {
  margin-left: 30px !important;
}

.mL40 {
  margin-left: 40px !important;
}

.mL50 {
  margin-left: 50px !important;
}

.mR0 {
  margin-right: 0px !important;
}

.mR5 {
  margin-right: 5px !important;
}

.mR10 {
  margin-right: 10px !important;
}

.mR20 {
  margin-right: 20px !important;
}

.mR30 {
  margin-right: 30px !important;
}

.mR40 {
  margin-right: 40px !important;
}

.mR50 {
  margin-right: 50px !important;
}

.size12 {
  font-size: 12px !important;
}

.size13 {
  font-size: 13px !important;
}

.size14 {
  font-size: 14px !important;
}

.size15 {
  font-size: 15px !important;
}

.size16 {
  font-size: 16px !important;
}

.size17 {
  font-size: 17px !important;
}

.size18 {
  font-size: 18px !important;
}

.size19 {
  font-size: 19px !important;
}

.size20 {
  font-size: 20px !important;
}

.size22 {
  font-size: 22px !important;
}

.size24 {
  font-size: 24px !important;
}

.size28 {
  font-size: 28px !important;
}

.colorRed {
  color: #de0000 !important;
}

.colorBlue {
  color: #356fcf !important;
}

.colorGray {
  color: #93989e !important;
}

.colorPrimary {
  color: #005b2a !important;
}

.preBox {
  background: #eff0f4;
  border: 1px solid #005b2a;
  padding: 15px;
  box-sizing: border-box;
  clear: both;
  overflow: hidden;
  font-family: Arial, sans-serif;
  line-height: 1.8;
}
.preBox pre {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align-last: auto;
}

.summaryBox .summaryBoxThumb > span {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.summaryBox .summaryBoxThumb img {
  display: block;
}

@keyframes scrollIn {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.movieLinkL {
  display: inline-block;
  position: relative;
}
.movieLinkL:after {
  content: "";
  display: block;
  background: url(../images/home/movie.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
}

.sec_border {
  border-top: 4px solid #3764ab;
}

.section.sec_white {
  background: #fff;
}
.section.sec_white:last-of-type {
  margin-bottom: -170px;
  padding-bottom: 170px;
}

.contentsWrap > .pageLead {
  font-size: 15px;
}

.col2_img_list li p {
  margin-top: 15px;
}

.lc3wide a {
  text-decoration: none;
  transition: opacity 0.25s;
}
.lc3wide a:hover {
  opacity: 0.75;
}

.newsListArea .newsItem a {
  display: flex;
  position: relative;
  display: block;
  text-decoration: none;
  color: #000;
}
.newsListArea .newsItem a .textArea {
  display: flex;
}
.newsListArea .newsItem a .textArea .date {
  color: #005b2a;
  letter-spacing: 0.06em;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.newsListArea .newsItem a .textArea .title {
  font-weight: 500;
}
.newsListArea .newsItem a .textArea .cat {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newsListArea .newsItem a .c-arrow {
  border-radius: 50%;
  visibility: hidden;
}
.newsListArea .newsItem a:hover, .newsListArea .newsItem a:focus {
  box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
}
.newsListArea .newsItem a:hover .c-arrow, .newsListArea .newsItem a:focus .c-arrow {
  visibility: visible;
}
.pager {
  text-align: center;
  margin-inline: auto;
  margin-top: 40px;
}
.pager ul {
  display: flex;
  justify-content: center;
}
.pager ul li a {
  text-decoration: none;
}
.pager ul li a.link_before {
  color: #005b2a;
}
.pager ul li a.link_after {
  color: #005b2a;
}
.pager ul li span.current_page {
  position: relative;
  color: #fff;
  z-index: 1;
}
.pager ul li span.current_page::before {
  position: absolute;
  content: "";
  background: #005b2a;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.commonContactArea .inner .dialArea {
  max-width: 934px;
  background: #f3f3f3;
  border-radius: 10px;
  margin-inline: auto;
}
.commonContactArea .inner .dialArea .title {
  color: #fff;
  font-weight: 500;
  background: #449ab6;
  text-align: center;
  margin-inline: auto;
}
.commonContactArea .inner .dialArea a {
  font-weight: 900;
  margin-inline: auto;
  text-align: center;
  display: block;
  font-family: "Lato", sans-serif;
  font-style: italic;
  letter-spacing: 0.06em;
  text-decoration: none;
  color: #000;
}
.commonContactArea .inner .dialArea .dialText {
  text-align: center;
}
.commonContactArea .inner .dialArea .dialText .stText > span {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  display: inline-block;
}

.navBtnWrap {
  position: relative;
}
.navBtnWrap.center {
  justify-content: center !important;
}
.navBtnWrap li {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.2);
}
.navBtnWrap li a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  display: block;
  border-radius: 10px;
  position: relative;
  height: 100%;
}
.navBtnWrap li a .c-arrow::after {
  position: absolute;
  content: "";
  background: url(../images/common/arrow-white.svg);
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 83/150;
}
.navBtnWrap li a .c-arrow.forLink::after {
  transform: rotate(90deg) !important;
  background-repeat: no-repeat;
}
.navBtnWrap li a:hover, .navBtnWrap li a:focus {
  opacity: 0.7;
}
.navBtnWrap.mini {
  display: flex;
}
.navBtnWrap.mini li a {
  font-weight: 600;
}
.navBtnWrap.col3 li a {
  background: #2e2e2e;
  color: #fff;
}
.navBtnWrap.col3 li a .c-arrow::before {
  background: #fff;
}
.navBtnWrap.col2 li a {
  background: #2e2e2e;
  color: #fff;
}
.navBtnWrap.col2 li a .c-arrow::before {
  background: #fff;
}
.navBtnBlock {
  background: #f3f3f3;
  border-radius: 10px;
}
.navBtnBlock .navBtnWrap {
  margin-inline: auto;
}

.navCardWrap {
  display: flex;
}
.navCardWrap .navCardItem {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
}
.navCardWrap .navCardItem a {
  border-radius: 10px;
  display: block;
  position: relative;
  height: 100%;
  text-decoration: none;
  color: #000;
}
.navCardWrap .navCardItem a .title {
  color: #fff;
  font-weight: 500;
  background: #005b2a;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  position: relative;
}
.navCardWrap .navCardItem a .title.forExternal::before {
  content: "";
  background: url(../images/common/external_icon_white.svg);
  position: absolute;
  background-size: contain;
  aspect-ratio: 1/1;
}
.navCardWrap .navCardItem a * + .cardText {
  margin-top: 0;
}
.navCardWrap .navCardItem a .cardText {
  line-height: 1.75;
  font-weight: 500;
  border-radius: 0px 0px 10px 10px;
}
.navCardWrap .navCardItem a .c-arrow {
  position: absolute;
  display: inline-block;
  right: 48px;
}
.navCardWrap .navCardItem a .c-arrow::before {
  content: "";
  background: #005b2a;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.navCardWrap .navCardItem a .c-arrow::after {
  content: "";
  position: absolute;
  background: url(../images/common/arrow-white.svg);
  background-repeat: no-repeat;
  background-size: contain;
  top: calc(50% + 1px);
  left: calc(14px + 50%);
  transform: translate(-50%, -50%);
  width: 6px;
  height: auto;
  aspect-ratio: 83/150;
}
.bannerWrap.center {
  justify-content: center;
}

.commonBanner {
  display: flex;
  box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
  border-radius: 10px;
  text-decoration: none !important;
}
.commonBanner:hover, .commonBanner:focus {
  opacity: 0.7;
}
.commonBanner p {
  width: 50%;
  border-radius: 10px 0 0 10px;
  margin-top: 0;
}
.commonBanner p.green {
  background: #005b2a;
}
.commonBanner p.skyblue {
  background: #449ab6;
}
.commonBanner p.orange {
  background: #f3961e;
}
.commonBanner p.chiken-blue {
  background: #3363ab;
}
.commonBanner p > img {
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
}
.commonBanner div {
  color: #fff;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
  width: 50%;
  border-radius: 0 10px 10px 0px;
  background: #005b2a;
  position: relative;
  z-index: 2;
}
.commonBanner div.orange {
  background: #f3961e;
  position: relative;
  z-index: 2;
}
.commonBanner div.chiken-blue {
  background: #3363ab;
}
.commonBanner div.skyblue {
  background: #449ab6;
  position: relative;
  z-index: 2;
}
.commonBanner div.green {
  background: #005b2a;
  position: relative;
  z-index: 2;
}
.commonBanner div.chiken-blue {
  background: #3363ab;
  position: relative;
  z-index: 2;
}
.commonBanner div .c-arrow::before {
  content: "";
  width: 27px;
  height: 27px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.commonBanner div .c-arrow::after {
  content: "";
  position: absolute;
  left: calc(1px + 50%);
  transform: translate(-50%, -50%);
  aspect-ratio: 83/150;
}
.commonBanner div .c-arrow.skyblue::after {
  background: url(../images/common/arrow-lightblue.svg);
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 83/150;
}
.commonBanner div .c-arrow.lightgray::after {
  background: url(../images/common/arrow-lightgray.svg);
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 83/150;
}
.commonBanner div .c-arrow.orange::after {
  background: url(../images/common/arrow-orange.svg);
  aspect-ratio: 83/150;
  background-repeat: no-repeat;
  background-size: contain;
}
.commonBanner div .c-arrow.green::after {
  background: url(../images/common/arrow-green.svg);
  aspect-ratio: 83/150;
  background-repeat: no-repeat;
  background-size: contain;
}
.commonBanner div .c-arrow.c-hojin::after {
  background: url(../images/common/arrow-lightgreen.svg);
  aspect-ratio: 83/150;
  background-repeat: no-repeat;
  background-size: contain;
}

.hasBorder {
  border-top: solid 1px #005b2a;
}

.s-moreBtn {
  font-weight: 600;
  border-radius: 8px;
  display: block;
  text-decoration: none;
  position: relative;
}
.s-moreBtn .c-arrow::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.s-moreBtn .c-arrow::after {
  content: "";
  background: url(../images/common/arrow-green.svg);
  position: absolute;
  top: 50%;
  left: calc(0px + 50%);
  transform: translate(-50%, -50%);
  width: 5px;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
}
h2.greenLabel {
  background: #005b2a;
  color: #fff;
  letter-spacing: 0.06em;
  line-height: 1.95;
  font-weight: 500;
}
h3 {
  border-radius: 6px;
  color: #000;
  letter-spacing: 0.06em;
  line-height: 1.75;
  font-weight: 600;
  position: relative;
}
h3::before {
  content: "";
  background: #005b2a;
  height: 100%;
  position: absolute;
}

h4 {
  font-weight: 600;
  letter-spacing: 0.06em;
  color: #005b2a;
  line-height: 1.75;
}

h5 {
  font-weight: 600;
  line-height: 2.18;
  letter-spacing: 0.06em;
}

.capImg {
  max-width: 860px;
  margin-inline: auto;
}
.capText {
  line-height: 1.86;
  font-weight: 500;
  letter-spacing: 0.06em;
}

.cardWrap {
  display: flex;
  flex-wrap: wrap;
}
.cardWrap .card {
  display: flex;
  background: #f2f2f2;
  border-radius: 10px;
  position: relative;
}
.cardWrap .card .title {
  color: #005b2a;
  font-weight: 600;
  position: relative;
  margin-top: 0;
}
.cardWrap .card .title .kome {
  position: absolute;
  width: 30px;
  font-weight: 600;
}
.cardWrap .card .hasBg {
  background: #fff;
  border-radius: 50%;
  width: 78px;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardWrap .card .hasBg img {
  max-width: 32px;
  aspect-ratio: 1/1;
}
.cardWrap .card .text {
  font-weight: 500;
  display: flex;
}
.cardWrap .card .text .num {
  font-weight: 600;
  color: #005b2a;
  position: relative;
}
.cardWrap .card.next::before {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-left: 13px solid #005b2a;
}
.cardWrap .card ul li .num {
  font-weight: 600;
  color: #005b2a;
  position: relative;
}
.cardWrap.intro li .illust {
  aspect-ratio: 1/1;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardWrap.intro li .illust > img {
  border-radius: 50%;
}
.cardWrap.intro li .illust > img.img1 {
  max-width: 100px;
}
.cardWrap.intro li .illust > img.img2 {
  max-width: 106px;
}
.cardWrap.intro li .illust > img.img3 {
  max-width: 106px;
}
.cardWrap.intro li .illust > img.img4 {
  max-width: 80px;
}
.cardWrap.intro li .illust > img.img5 {
  max-width: 98px;
}
.cardWrap.full .card .text {
  font-weight: 600;
}
.innerMini {
  max-width: 920px;
  margin-inline: auto;
}

.qa dt {
  color: #005b2a;
  font-weight: bold;
  position: relative;
}
.qa dt::before {
  content: "Q";
  position: absolute;
  left: 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.qa dd {
  position: relative;
  border-bottom: solid 1px #aaa;
}
.qa dd::before {
  content: "A";
  position: absolute;
  left: 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.anchorList ul {
  display: flex;
  flex-wrap: wrap;
}
.anchorList ul li {
  border-bottom: solid 1px #005b2a;
}
.anchorList ul li a {
  text-decoration: none;
  color: #333;
  display: block;
  font-weight: 600;
  position: relative;
}
.anchorList ul li a::before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow-green.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: auto;
  aspect-ratio: 83/150;
  transform: rotate(90deg);
  transition: all 0.3s;
}
.bannerList.col3 {
  display: flex;
}
/* common class */
.pointerNone {
  cursor: default;
  text-decoration: none;
}

.bold {
  font-weight: bold !important;
}

.underline {
  text-decoration: underline !important;
}

.txtGreen {
  color: #005b2a;
}

.marker {
  background: linear-gradient(transparent 60%, #ffd4d5 60%) !important;
}

.taC {
  text-align: center !important;
}

.taL {
  text-align: left !important;
}

.taR {
  text-align: right !important;
}

.Right {
  margin-left: auto !important;
}

.Left {
  margin-right: auto !important;
}

.Center {
  margin: 0 auto !important;
}

.ovh {
  overflow: hidden !important;
}

.mw100 {
  max-width: 100% !important;
  width: auto !important;
}

.w100p {
  width: 100% !important;
}

.pT0 {
  padding-top: 0 !important;
}

.mB0 {
  margin-bottom: 0 !important;
}

.mB5 {
  margin-bottom: 5px !important;
}

.mB10 {
  margin-bottom: 10px !important;
}

.mB20 {
  margin-bottom: 20px !important;
}

.mB30 {
  margin-bottom: 30px !important;
}

.mB40 {
  margin-bottom: 40px !important;
}

.mB50 {
  margin-bottom: 50px !important;
}

.mT0 {
  margin-top: 0 !important;
}

.mT5 {
  margin-top: 5px !important;
}

.mT10 {
  margin-top: 10px !important;
}

.mT20 {
  margin-top: 20px !important;
}

.mT30 {
  margin-top: 30px !important;
}

.mT40 {
  margin-top: 40px !important;
}

.mT50 {
  margin-top: 50px !important;
}

.mT60 {
  margin-top: 60px !important;
}

.mT70 {
  margin-top: 70px !important;
}

.mT80 {
  margin-top: 80px !important;
}

.mL0 {
  margin-left: 0px !important;
}

.mL5 {
  margin-left: 5px !important;
}

.mL10 {
  margin-left: 10px !important;
}

.mL20 {
  margin-left: 20px !important;
}

.mL30 {
  margin-left: 30px !important;
}

.mL40 {
  margin-left: 40px !important;
}

.mL50 {
  margin-left: 50px !important;
}

.mR0 {
  margin-right: 0px !important;
}

.mR5 {
  margin-right: 5px !important;
}

.mR10 {
  margin-right: 10px !important;
}

.mR20 {
  margin-right: 20px !important;
}

.mR30 {
  margin-right: 30px !important;
}

.mR40 {
  margin-right: 40px !important;
}

.mR50 {
  margin-right: 50px !important;
}

@media screen and (max-width: 768px) {
  * + .pageInfo {
    margin-top: 20px;
  }
  .pageInfo {
    padding: 66px 26px 26px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 15px;
  }
  .pageInfo:after {
    font-size: 18px;
    top: 25px;
    left: 20px;
  }
  .pageInfoList > li {
    position: relative;
  }
  .pageInfoList > li a:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 70%, #005b2a 70%);
    position: absolute;
    top: 0;
    left: -100%;
    transition: 0.2s left;
    z-index: 0;
    opacity: 0.4;
  }
  main {
    overflow: hidden;
  }
  .indexTitle {
    height: 175px;
  }
  .indexTitle.mini .inner h1 {
    padding-left: 0;
    margin-top: -2px;
  }
  .indexTitle.mini .inner > span {
    font-size: 15px;
    padding-left: 36px;
  }
  .indexTitle > .inner {
    padding-inline: 20px;
  }
  .indexTitle > .inner h1 {
    font-size: 24px;
    line-height: 1.6;
    padding-left: 40px;
  }
  .indexTitle.doctorBg {
    background: url(../images/second/doctor-bg_sp.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .indexTitle.doctorBg h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 46px;
  }
  .indexTitle.doctorBg .inner > span::before {
    width: 25px;
    height: 25px;
  }
  .indexTitle.lawyerBg h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 46px;
  }
  .indexTitle.lawyerBg .inner > span::before {
    width: 25px;
    height: 25px;
  }
  .indexTitle.chikenBg .inner h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: auto;
    aspect-ratio: 52/56;
  }
  .indexTitle.chikenBg .inner > span::before {
    width: 25px;
    height: 25px;
  }
  .indexTitle.proBg {
    background: url(../images/second/professionals-bg_sp.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .indexTitle.proBg .inner h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: auto;
    aspect-ratio: 52/56;
  }
  .indexTitle.proBg .inner > span::before {
    width: 25px;
    height: 25px;
  }
  .indexTitle.hojinBg {
    background: url(../images/second/hojin-bg_sp.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .indexTitle.hojinBg .inner h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: auto;
    aspect-ratio: 52/56;
  }
  .indexTitle.hojinBg .inner > span::before {
    width: 25px;
    height: 25px;
  }
  .indexTitle.commonBg {
    background: url(../images/second/common-bg_sp.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .section.service {
    padding-bottom: 65px;
  }
  article .inner {
    padding-inline: 20px;
  }
  article .inner .btnWrap {
    margin-top: 40px;
  }
  article .inner .btnWrap .prevBtn {
    padding: 8px 20px;
  }
  article .newsSec .inner .newsListArea .newsItem a {
    text-decoration: none;
  }
  article .newsSec .inner .newsListArea .newsItem a .textArea .infoArea {
    display: flex;
    gap: 20px;
  }
  #searchid {
    margin-bottom: 20px;
  }
  .errmsg {
    font-size: 200%;
  }
  .rtitle {
    font-size: 200%;
  }
  .rtable_col {
    font-size: 120%;
  }
  main * + p {
    margin-top: 20px;
    font-size: 14px;
  }
  .btnWrap {
    margin-top: 20px;
  }
  a.hasArrow span {
    position: relative;
    padding-left: 20px;
  }
  a.hasArrow span::after {
    position: absolute;
    content: "";
    background: url(../images/common/arrow-green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
    width: 6px;
    height: auto;
    left: 0;
    top: 8px;
  }
  a.external::after {
    width: 20px;
    height: auto;
    right: -20px;
    bottom: -1px;
  }
  .second {
    padding-top: 100px;
  }
  .breadcrumbArea {
    position: relative;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 50px;
  }
  .breadcrumbArea ul {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
  }
  .breadcrumbArea ul li {
    color: #333;
    margin-right: 5px;
    font-weight: 400;
    font-size: 12px;
  }
  .breadcrumbArea ul li:before {
    content: "/";
    display: inline-block;
    margin-right: 3px;
  }
  .breadcrumbArea ul li:first-child:before {
    content: "";
    display: none;
  }
  .breadcrumbArea ul li a {
    text-decoration: none;
    color: #333;
  }
  .breadcrumbArea ul li a:hover {
    text-decoration: underline;
  }
  .section {
    padding: 40px 0;
    margin: 0 auto;
  }
  .section .inner {
    padding: 0 20px;
  }
  .section .inner > p {
    margin-top: 20px;
  }
  .pageBigLead {
    font-size: 18px;
    line-height: 1.5;
  }
  .pageBigLead + .pageLead {
    margin-top: 25px;
  }
  .pageBigLead + p {
    margin-top: 20px;
  }
  .pageLead {
    font-size: 14px;
    line-height: 1.85;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .pageLead + .btnWrap {
    margin-top: 52px;
  }
  .h2TitleWrap {
    font-size: 27px;
  }
  .h2TitleWrap h2 {
    font-size: 17px;
    margin-top: -2px;
  }
  .h2TitleWrap + p {
    margin-top: 20px;
  }
  .inner + * {
    margin-top: 20px;
  }
  * + .titleBorder,
  * + h2.title {
    margin-top: 40px;
  }
  * + h3.title {
    margin-top: 40px;
  }
  h3.title {
    padding-left: 14px;
    font-size: 18px;
  }
  h3.title:after {
    width: 3px;
  }
  * + .titleGray,
  * + h4.title {
    margin-top: 20px;
  }
  .titleGray + *,
  h4.title + * {
    margin-top: 0;
  }
  .titleGray,
  h4.title {
    font-size: 16px;
  }
  * + h5.title {
    margin-top: 20px;
  }
  h5.title + * {
    margin-top: 2px;
  }
  h5.title {
    font-size: 16px;
  }
  * + .titleHeader {
    margin-top: 20px;
  }
  .titleHeader .titleCenter {
    font-size: 20px;
  }
  .titleCenterSub {
    margin-top: 4px;
    font-size: 12px;
  }
  * + [class^=listCol] {
    margin-top: 28px;
  }
  [class^=listCol] {
    width: 100%;
  }
  [class^=listCol] > .listItem + .listItem {
    margin-top: 20px;
  }
  [class^=listCol].setBtn.rev {
    flex-direction: column-reverse;
  }
  [class^=listCol].setBtn .listItem.imgArea {
    margin-inline: auto;
    text-align: center;
  }
  [class^=listCol].setBtn .listItem .btnWrap {
    margin-top: 20px;
  }
  [class^=listCol].setBtn .listItem .btnWrap .btn {
    margin-inline: auto;
    font-size: 18px;
  }
  [class^=listCol].setBtn .listItem h4 {
    margin-top: 0;
  }
  [class^=listCol].hasBox {
    padding: 20px;
  }
  [class^=listCol].hasBox .imgArea p img {
    border-radius: 10px;
  }
  * + .colImgFixed {
    margin-top: 30px;
  }
  .colImgFixed {
    flex-direction: column;
    /*
    @if $type == tb{
    	flex-direction: column;
    }
    */
  }
  .colImgFixed > * {
    width: 100%;
    /*
    		@if $type == tb{
    			width: 100%;

    			&.colText {
    				order: 1;
    				margin-top: 30px;
    			}

    			&.colImg {
    				order: 0;
    			}

    			&:nth-child(odd) {
    				margin-right: 0;
    			}

    			&:nth-child(n + 3) {
    				margin-top: 0;
    			}
    		}
    */
  }
  .colImgFixed > *.colText {
    order: 1;
    margin-top: 30px;
  }
  .colImgFixed > *.colImg {
    order: 0;
  }
  * + .commonTable {
    margin-top: 20px;
  }
  .commonTable {
    font-size: 14px;
  }
  .commonTable a {
    line-height: 1.6;
  }
  .commonTable > tbody > tr + tr th,
  .commonTable > thead > tr + tr th {
    border-top: none;
  }
  .commonTable > tbody > tr * + *,
  .commonTable > thead > tr * + * {
    border-top: none;
  }
  .commonTable > tbody > tr > th,
  .commonTable > thead > tr > th {
    padding: 12px 6px;
    display: block;
    border-left: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
    border-top: 1px solid #cfcfcf;
  }
  .commonTable > tbody > tr > td,
  .commonTable > thead > tr > td {
    padding: 12px 10px;
    display: block;
    border-right: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;
    border-left: solid 1px #cfcfcf;
  }
  * + .otherTable {
    margin-top: 20px;
  }
  .otherTable {
    font-size: 14px;
  }
  .otherTable th {
    background: #edf9e4;
    color: #005b2a;
  }
  .otherTable th,
  .otherTable td {
    padding: 12px 6px;
  }
  .scrollTable {
    overflow: auto;
  }
  .scrollTable table {
    min-width: calc(100% + 50px);
    margin-top: 0;
    white-space: nowrap;
  }
  * + .scrollText {
    margin-top: 15px;
  }
  .scrolltext {
    width: 160px;
    display: block;
    padding: 4px 8px;
    margin-bottom: 8px;
    margin-left: auto;
    color: #005b2a;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    border-radius: 12px;
    background: #333;
  }
  * + ul.commonList,
  * + ol.commonList {
    margin-top: 20px;
  }
  ul.commonList.col_sp {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  ul.commonList.col_sp > li {
    width: calc(50% - 20px);
  }
  ul.commonList.col_sp > li:nth-child(odd) {
    margin-right: 10px;
  }
  ul.commonList.col_sp > li:nth-child(even) {
    margin-left: 10px;
  }
  ul.commonList > li + li {
    margin-top: 10px;
  }
  ul.commonList > li:before {
    top: 11px;
    margin: auto;
    left: 0;
  }
  ul.commonList > li a {
    font-size: 14px;
  }
  ol.commonList > li {
    position: relative;
  }
  ol.commonList > li + li {
    margin-top: 7px;
  }
  ol.commonList > li a {
    font-size: 14px;
  }
  *:not(.title) + dl.commonList {
    margin-top: 20px;
  }
  .title + dl.commonList {
    margin-top: 0;
  }
  dl.commonList > div {
    padding: 15px 0;
  }
  dl.commonList > div > dt,
  dl.commonList > div > dd {
    font-size: 12px;
    line-height: 1.3;
  }
  dl.commonList > div > dt {
    font-size: 16px;
    font-weight: 600;
  }
  dl.commonList > div > dd {
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.76;
  }
  .col2Btn {
    flex-direction: column;
    margin-top: 30px;
  }
  * + .listTextCol2 {
    margin-top: 20px;
  }
  .listTextCol2 {
    flex-direction: column;
    padding: 20px;
  }
  .listTextCol2 .textArea {
    margin-top: 30px;
    border-top: solid 1px #cfcfcf;
    padding-top: 30px;
  }
  .listTextCol2 .textArea:has(+ .textArea) {
    padding-top: 0;
  }
  .lineBox {
    padding: 20px;
    margin-top: 30px;
  }
  .iconText.pdf::before {
    content: "";
    position: absolute;
    background: url(../images/second/pdf-icon.svg);
    background-size: contain;
    aspect-ratio: 117/150;
    height: auto;
    right: -30px;
    background-repeat: no-repeat;
    bottom: -2px;
    width: 15px;
  }
  .iconText.excel::before {
    position: absolute;
    background: url(../images/second/excel-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 24px;
  }
  .iconText.word::before {
    position: absolute;
    content: "";
    background: url(../images/second/word-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 30px;
  }
  .col2NavLink {
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
  }
  .col2NavLink div {
    width: 100%;
  }
  .col2NavLink div a {
    font-size: 16px;
    padding-left: 34px;
    padding-block: 11px;
    padding-right: 70px;
  }
  .col2NavLink div a .arrow {
    width: 18px;
    height: 18px;
  }
  .col2NavLink div a .arrow::before {
    content: "";
    background: url(../images/common/arrow.svg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
    width: 4px;
    height: auto;
  }
  .iframeSize {
    margin-inline: auto;
    max-width: 640px;
    width: 90%;
  }
  .iframeSize .iframeWrap {
    margin-top: 1.5em;
  }
  .mapWrap {
    padding-bottom: 50.25%;
  }
  * + .colorBox {
    margin-top: 30px;
  }
  * + .colorBoxAdd {
    margin-top: 15px;
  }
  .colorBox {
    padding: 25px 13px 25px;
    border-radius: 8px;
  }
  .colorBoxAdd {
    padding: 18px 15px 18px;
    border-radius: 8px;
  }
  .colorBox .colorBoxTitle,
  .colorBoxAdd .colorBoxTitle {
    font-size: 15px;
  }
  .colorBox .colorBoxTitle + p,
  .colorBoxAdd .colorBoxTitle + p {
    margin-top: 6px;
  }
  .preBox {
    margin-top: 20px;
  }
  .taC_sp {
    text-align: center !important;
  }
  .taL_sp {
    text-align: left !important;
  }
  .taR_sp {
    text-align: right !important;
  }
  .pT0_sp {
    padding-top: 0 !important;
  }
  .pT10_sp {
    padding-top: 10px !important;
  }
  .pT20_sp {
    padding-top: 20px !important;
  }
  .pB0_sp {
    padding-bottom: 0 !important;
  }
  .pB10_sp {
    padding-bottom: 10px !important;
  }
  .pB20_sp {
    padding-bottom: 20px !important;
  }
  .pB30_sp {
    padding-bottom: 30px !important;
  }
  .pB40_sp {
    padding-bottom: 40px !important;
  }
  .pT30_sp {
    padding-top: 30px !important;
  }
  .pT40_sp {
    padding-top: 40px !important;
  }
  .mB0_sp {
    margin-bottom: 0 !important;
  }
  .mB5_sp {
    margin-bottom: 5px !important;
  }
  .mB10_sp {
    margin-bottom: 10px !important;
  }
  .mB15_sp {
    margin-bottom: 15px !important;
  }
  .mB20_sp {
    margin-bottom: 20px !important;
  }
  .mB30_sp {
    margin-bottom: 30px !important;
  }
  .mB40_sp {
    margin-bottom: 40px !important;
  }
  .mB50_sp {
    margin-bottom: 50px !important;
  }
  .mB60_sp {
    margin-bottom: 60px !important;
  }
  .mB70_sp {
    margin-bottom: 70px !important;
  }
  .mB80_sp {
    margin-bottom: 80px !important;
  }
  .mT0_sp {
    margin-top: 0 !important;
  }
  .mT5_sp {
    margin-top: 5px !important;
  }
  .mT10_sp {
    margin-top: 10px !important;
  }
  .mT20_sp {
    margin-top: 20px !important;
  }
  .mT30_sp {
    margin-top: 30px !important;
  }
  .mT40_sp {
    margin-top: 40px !important;
  }
  .mT50_sp {
    margin-top: 50px !important;
  }
  .mT60_sp {
    margin-top: 60px !important;
  }
  .mT70_sp {
    margin-top: 70px !important;
  }
  .mT80_sp {
    margin-top: 80px !important;
  }
  .mL0_sp {
    margin-left: 0px !important;
  }
  .mL5_sp {
    margin-left: 5px !important;
  }
  .mL10_sp {
    margin-left: 10px !important;
  }
  .mL20_sp {
    margin-left: 20px !important;
  }
  .mL30_sp {
    margin-left: 30px !important;
  }
  .mL40_sp {
    margin-left: 40px !important;
  }
  .mL50_sp {
    margin-left: 50px !important;
  }
  .mR0_sp {
    margin-right: 0px !important;
  }
  .mR5_sp {
    margin-right: 5px !important;
  }
  .mR10_sp {
    margin-right: 10px !important;
  }
  .mR20_sp {
    margin-right: 20px !important;
  }
  .mR30_sp {
    margin-right: 30px !important;
  }
  .mR40_sp {
    margin-right: 40px !important;
  }
  .mR50_sp {
    margin-right: 50px !important;
  }
  .size12_sp {
    font-size: 12px !important;
  }
  .size13_sp {
    font-size: 13px !important;
  }
  .size14_sp {
    font-size: 14px !important;
  }
  .size15_sp {
    font-size: 15px !important;
  }
  .size16_sp {
    font-size: 16px !important;
  }
  .size17_sp {
    font-size: 17px !important;
  }
  .size18_sp {
    font-size: 18px !important;
  }
  .size19_sp {
    font-size: 19px !important;
  }
  .size20_sp {
    font-size: 20px !important;
  }
  .size22_sp {
    font-size: 22px !important;
  }
  .size24_sp {
    font-size: 24px !important;
  }
  .size28_sp {
    font-size: 28px !important;
  }
  .w50p_sp {
    width: 50% !important;
  }
  * + .summaryBox {
    margin-top: 30px;
  }
  @keyframes scrollIn {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  .movieLinkL {
    display: inline-block;
    position: relative;
  }
  .movieLinkL:after {
    content: "";
    display: block;
    background: url(../images/home/movie.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    background-size: 100% auto;
    margin-top: -35px;
    margin-left: -35px;
  }
  .sec_border {
    border-top: 4px solid #3764ab;
  }
  .section.sec_white {
    background: #fff;
  }
  .section.sec_white:last-of-type {
    margin-bottom: -170px;
    padding-bottom: 170px;
  }
  .contentsWrap {
    padding-bottom: 60px;
  }
  .contentsWrap + .section {
    padding-top: 0;
  }
  .contentsWrap > .pageLead {
    font-size: 15px;
    text-align: left;
    padding: 0 20px;
    font-size: 17px;
    line-height: 2;
  }
  .col2_img_list li {
    margin-top: 30px;
    text-align: center;
  }
  .col2_img_list li:nth-of-type(1) {
    margin-top: 0;
  }
  .col2_img_list li p {
    margin-top: 15px;
    text-align: left;
  }
  .lc3wide .listItem {
    text-align: center;
  }
  .lc3wide a {
    text-decoration: none;
    transition: opacity 0.25s;
  }
  .lc3wide a:hover {
    opacity: 0.75;
  }
  .newsSec .inner .newsH2Wrap {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .newsSec .inner .newsH2Wrap .h2TitleWrap {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: baseline;
    margin-inline: unset;
  }
  .newsSec .inner .newsH2Wrap .h2TitleWrap .engh2 {
    font-size: 24px;
  }
  .newsSec .inner .newsH2Wrap .h2TitleWrap h2 {
    font-size: 12px;
  }
  .newsListArea .newsItem a {
    flex-direction: column;
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: solid 1px #aaa;
  }
  .newsListArea .newsItem a .textArea {
    flex-direction: column;
  }
  .newsListArea .newsItem a .textArea .date {
    font-size: 11px;
    letter-spacing: 0.15em;
  }
  .newsListArea .newsItem a .textArea .title {
    margin-top: 4px;
    font-size: 13px;
  }
  .newsListArea .newsItem a .textArea .cat {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    padding: 4px 5px;
    height: 20px;
  }
  .newsListArea .newsItem a .c-arrow {
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
  }
  .newsListArea .newsItem a .c-arrow::before {
    content: "";
    width: 20px;
    height: 20px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    margin: auto;
  }
  .newsListArea .newsItem a .c-arrow::after {
    content: "";
    width: 6px;
    height: 6px;
    border: 0;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    position: absolute;
    top: 11px;
    left: 5px;
    transform: rotate(45deg) translate(-50%, -50%);
    margin: auto;
    border-radius: 2px;
  }
  .newsListArea .newsItem a:hover, .newsListArea .newsItem a:focus {
    box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
  }
  .newsListArea .newsItem a:hover .c-arrow, .newsListArea .newsItem a:focus .c-arrow {
    visibility: visible;
  }
  .pager ul {
    gap: 30px;
  }
  .pager ul li a {
    text-decoration: none;
  }
  .pager ul li a.link_before {
    color: #005b2a;
  }
  .pager ul li a.link_after {
    color: #005b2a;
  }
  .newsMoreBtn {
    margin-top: 30px;
  }
  .newsMoreBtn .s-moreBtn {
    margin-left: auto;
  }
  .commonContactArea {
    padding-top: 60px;
    padding-bottom: 62px;
  }
  .commonContactArea .inner .dialArea {
    margin-top: 30px;
    padding: 16px 58px 8px;
  }
  .commonContactArea .inner .dialArea .title {
    font-size: 13px;
    border-radius: 5px;
    max-width: 198px;
    width: 100%;
  }
  .commonContactArea .inner .dialArea a {
    font-size: 30px;
    margin-top: -3px;
  }
  .commonContactArea .inner .dialArea .dialText {
    margin-top: -8px;
    line-height: 1.84;
    letter-spacing: 0.06em;
  }
  .commonContactArea .inner .dialArea .dialText .jpText {
    font-size: 13px;
    margin-top: -8px;
  }
  .commonContactArea .inner .dialArea .dialText .stText {
    font-size: 13px;
  }
  .navBtnWrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 16px;
  }
  .navBtnWrap li {
    width: 100%;
  }
  .navBtnWrap li a {
    font-size: 15px;
    padding: 10px 13px;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  .navBtnWrap li a .c-arrow {
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
  }
  .navBtnWrap li a .c-arrow::before {
    content: "";
    width: 20px;
    height: 20px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    margin: auto;
  }
  .navBtnWrap li a .c-arrow::after {
    position: absolute;
    content: "";
    background: url(../images/common/arrow-white.svg);
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 83/150;
    width: 4px;
    height: auto;
    top: 50%;
    left: 50%;
  }
  .navBtnWrap li a .c-arrow.forLink::after {
    top: 7px !important;
    left: 8px !important;
  }
  .navBtnWrap li a:hover, .navBtnWrap li a:focus {
    opacity: 0.7;
  }
  .navBtnWrap li a.external::after {
    bottom: 18px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    right: 8px;
  }
  .navBtnWrap.mini {
    justify-content: center;
  }
  .navBtnWrap.col3 li {
    width: 100%;
  }
  .navBtnWrap.col3 li a .c-arrow::before {
    background: #fff;
  }
  .navBtnWrap.col3 li a .c-arrow::after {
    content: "";
    width: 4px;
    height: auto;
    position: absolute;
    background: url(../images/common/arrow.svg);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .navBtnWrap.col2 li a .c-arrow::before {
    background: #fff;
  }
  .navBtnWrap.col2 li a .c-arrow::after {
    content: "";
    width: 4px;
    height: auto;
    position: absolute;
    background: url(../images/common/arrow.svg);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .navBtnBlock {
    padding: 20px 20px;
    margin-top: 40px;
  }
  .navBtnWrap + p {
    margin-top: 52px;
  }
  * + .navCardWrap {
    padding-top: 20px;
  }
  .navCardWrap {
    gap: 20px;
    flex-direction: column;
  }
  .navCardWrap .navCardItem a .title {
    font-size: 16px;
    padding: 8px 16px 8px;
  }
  .navCardWrap .navCardItem a .title.forExternal::before {
    width: 20px;
    height: auto;
    top: 14px;
    right: 2px;
  }
  .navCardWrap .navCardItem a * + .cardText {
    margin-top: 0;
  }
  .navCardWrap .navCardItem a .cardText {
    font-size: 14px;
    padding: 10px 16px 32px;
    border-radius: 0px 0px 5px 5px;
  }
  .navCardWrap .navCardItem a .c-arrow {
    bottom: 26px;
    right: 40px;
  }
  .navCardWrap .navCardItem a .c-arrow::before {
    content: "";
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
  }
  .navCardWrap .navCardItem a .c-arrow::after {
    top: 50%;
    left: calc(11px + 50%);
  }
  .navCardWrap.hasLine {
    position: relative;
  }
  .navCardWrap.hasLine::before {
    content: "";
    position: absolute;
    background: #005b2a;
    height: 1px;
    width: 100%;
    left: 0;
    top: 0;
  }
  p + .bannerWrap {
    margin-top: 40px;
  }
  .bannerWrap {
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    gap: 30px;
    align-items: center;
  }
  .commonBanner {
    width: 335px;
  }
  .commonBanner:hover, .commonBanner:focus {
    opacity: 0.7;
  }
  .commonBanner p.green {
    background: #005b2a;
  }
  .commonBanner p.skyblue {
    background: #449ab6;
  }
  .commonBanner p.orange {
    background: #f3961e;
  }
  .commonBanner p.chiken-blue {
    background: #3363ab;
  }
  .commonBanner p > img {
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
  }
  .commonBanner div {
    font-size: 16px;
    line-height: 1.34;
    padding-left: 4px;
    gap: 18px;
  }
  .commonBanner div.orange {
    background: #f3961e;
    position: relative;
    z-index: 2;
  }
  .commonBanner div.chiken-blue {
    background: #3363ab;
  }
  .commonBanner div.skyblue {
    background: #449ab6;
    position: relative;
    z-index: 2;
  }
  .commonBanner div.green {
    background: #005b2a;
    position: relative;
    z-index: 2;
  }
  .commonBanner div.chiken-blue {
    background: #3363ab;
    position: relative;
    z-index: 2;
  }
  .commonBanner div .c-arrow {
    position: absolute;
    display: inline-block;
    right: min(1.85vw, 20px);
    width: 20px;
    height: 20px;
    top: 53%;
    transform: translateY(-50%);
  }
  .commonBanner div .c-arrow::before {
    content: "";
    width: 27px;
    height: 27px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
  }
  .commonBanner div .c-arrow::after {
    width: 4px;
    height: auto;
    top: 50%;
  }
  .commonBanner div .c-arrow.skyblue::after {
    background: url(../images/common/arrow-lightblue.svg);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 83/150;
  }
  .commonBanner div .c-arrow.lightgray::after {
    background: url(../images/common/arrow-lightgray.svg);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 83/150;
  }
  .commonBanner div .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .commonBanner div .c-arrow.green::after {
    background: url(../images/common/arrow-green.svg);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .commonBanner div .c-arrow.c-hojin::after {
    background: url(../images/common/arrow-lightgreen.svg);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .s-moreBtn {
    color: #fff;
    background: #005b2a;
    font-size: 11px;
    padding-left: 16px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 4px;
  }
  .s-moreBtn .c-arrow {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 10px;
  }
  .s-moreBtn .c-arrow::before {
    content: "";
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 12px;
  }
  .s-moreBtn .c-arrow::after {
    content: "";
    background: url(../images/common/arrow-green.svg);
    position: absolute;
    top: 50%;
    left: calc(0px + 50%);
    transform: translate(-50%, -50%);
    width: 5px;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
    width: 3px;
    height: auto;
    left: 50%;
  }
  h2.greenLabel {
    font-size: 16px;
    padding: 12px 20px 12px 20px;
    margin: 45px calc(50% - 50vw) 0;
    width: 100vw;
  }
  h2.greenLabel + .cardWrap {
    margin-top: 30px;
  }
  h2.greenLabel + p {
    margin-top: 20px;
  }
  h3 {
    font-size: 15px;
    padding-left: 16px;
    margin-top: 26px;
  }
  h3::before {
    width: 4px;
    left: 0px;
    top: 0px;
  }
  h4 {
    font-size: 16px;
    margin-top: 12px;
  }
  h4 + p {
    margin-top: 8px;
  }
  h5 {
    margin-top: 8px;
    font-size: 16px;
  }
  h5 + p {
    margin-top: 8px;
  }
  .capImg {
    margin-top: 30px;
  }
  .capText {
    margin-top: 12px;
    font-size: 13px;
  }
  * + .cardWrap {
    margin-top: 30px;
  }
  .cardWrap {
    flex-direction: column;
    gap: 45px;
    align-items: center;
  }
  .cardWrap .card {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    padding: 12px 20px;
    max-width: 500px;
    width: 75%;
  }
  .cardWrap .card .title {
    font-size: 16px;
    text-align: center;
  }
  .cardWrap .card .hasBg {
    margin-top: 12px;
  }
  .cardWrap .card .text {
    text-align: center;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 12px;
  }
  .cardWrap .card.next::before {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-left: 13px solid #005b2a;
    transform: rotate(90deg);
    bottom: -40px;
    left: auto;
    right: auto;
  }
  .cardWrap.intro li .illust {
    aspect-ratio: 1/1;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .cardWrap.intro li .illust > img {
    border-radius: 50%;
  }
  .cardWrap.intro li .illust > img.img1 {
    max-width: 100px;
  }
  .cardWrap.intro li .illust > img.img2 {
    max-width: 106px;
  }
  .cardWrap.intro li .illust > img.img3 {
    max-width: 106px;
  }
  .cardWrap.intro li .illust > img.img4 {
    max-width: 80px;
  }
  .cardWrap.intro li .illust > img.img5 {
    max-width: 98px;
  }
  .cardWrap.full .card::before {
    right: auto;
    left: auto;
  }
  .qa dt {
    font-size: 16px;
    padding-left: 30px;
    padding-top: 10px;
  }
  .qa dt::before {
    font-size: 20px;
    top: 7px;
  }
  .qa dd {
    font-size: 16px;
    padding-bottom: 10px;
    padding-left: 30px;
    margin-top: 20px;
  }
  .qa dd::before {
    font-size: 20px;
    top: -3px;
  }
  .anchorList ul {
    row-gap: 20px;
    gap: 20px;
  }
  .anchorList ul li {
    width: calc((100% - 20px) / 2);
  }
  .anchorList ul li a {
    padding: 10px 38px 10px 16px;
  }
  .anchorList ul li a::before {
    height: 16px;
    right: 14px;
    top: 15px;
    width: 7px;
  }
  * + .bannerList {
    margin-top: 20px;
  }
  .bannerList.col3 {
    flex-direction: column;
    max-width: 335px;
    margin-inline: auto;
  }
  .bannerList.col3 li {
    width: 100%;
    padding: 20px;
    border: solid #cdcdcd 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 2/1;
  }
  .bannerList.col3 li + li {
    border-top: none;
  }
  .bannerList.col3 li a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* common class */
  .mB0 {
    margin-bottom: 0 !important;
  }
  .mB5_sp {
    margin-bottom: 5px !important;
  }
  .mB10_sp {
    margin-bottom: 10px !important;
  }
  .mB20_sp {
    margin-bottom: 20px !important;
  }
  .mB30_sp {
    margin-bottom: 30px !important;
  }
  .mB40_sp {
    margin-bottom: 40px !important;
  }
  .mB50_sp {
    margin-bottom: 50px !important;
  }
  .mT0 {
    margin-top: 0 !important;
  }
  .mT5_sp {
    margin-top: 5px !important;
  }
  .mT10_sp {
    margin-top: 10px !important;
  }
  .mT20_sp {
    margin-top: 20px !important;
  }
  .mT30_sp {
    margin-top: 30px !important;
  }
  .mT40_sp {
    margin-top: 40px !important;
  }
  .mT50_sp {
    margin-top: 50px !important;
  }
  .mL0_sp {
    margin-left: 0px !important;
  }
  .mL5_sp {
    margin-left: 5px !important;
  }
  .mL10_sp {
    margin-left: 10px !important;
  }
  .mL20_sp {
    margin-left: 20px !important;
  }
  .mL30_sp {
    margin-left: 30px !important;
  }
  .mL40_sp {
    margin-left: 40px !important;
  }
  .mL50_sp {
    margin-left: 50px !important;
  }
  .mR0_sp {
    margin-right: 0px !important;
  }
  .mR5_sp {
    margin-right: 5px !important;
  }
  .mR10_sp {
    margin-right: 10px !important;
  }
  .mR20_sp {
    margin-right: 20px !important;
  }
  .mR30_sp {
    margin-right: 30px !important;
  }
  .mR40_sp {
    margin-right: 40px !important;
  }
  .mR50_sp {
    margin-right: 50px !important;
  }
  .size12_sp {
    font-size: 12px !important;
  }
  .size14_sp {
    font-size: 14px !important;
  }
  .size16_sp {
    font-size: 16px !important;
  }
  .size18_sp {
    font-size: 18px !important;
  }
  .size20_sp {
    font-size: 20px !important;
  }
  .w50p_sp {
    width: 50% !important;
  }
}
@media print, screen and (min-width: 769px) {
  * + .pageInfo {
    margin-top: 40px;
  }
  .pageInfo {
    max-width: 700px;
    padding: 100px 40px 40px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 15px;
  }
  .pageInfo:after {
    font-size: 22px;
    top: 35px;
    left: 40px;
  }
  .pageInfoList > li + li {
    margin-top: 8px;
  }
  .pageInfoList > li a:hover:before {
    left: 0;
  }
  .pageInfoList > li a:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 70%, #005b2a 70%);
    position: absolute;
    top: 0;
    left: -100%;
    transition: 0.2s left;
    z-index: 0;
    opacity: 0.4;
  }
  main {
    background: #fff;
    font-size: 15px;
  }
  .indexTitle {
    height: 350px;
  }
  .indexTitle.mini {
    height: 230px;
  }
  .indexTitle.mini .inner h1 {
    font-size: 36px;
    padding-left: 0;
  }
  .indexTitle.mini .inner > span {
    font-size: 18px;
    padding-left: 40px;
  }
  .indexTitle > .inner {
    max-width: 1102px;
    width: 90%;
  }
  .indexTitle > .inner h1 {
    font-size: 48px;
    line-height: 1.6;
    padding-left: 100px;
  }
  .indexTitle.doctorBg {
    background: url(../images/second/doctor-bg.png);
    background-size: cover;
  }
  .indexTitle.doctorBg h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 72px;
    height: 92px;
  }
  .indexTitle.lawyerBg h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 72px;
    height: 92px;
  }
  .indexTitle.chikenBg .inner h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 52px;
    height: auto;
    aspect-ratio: 52/56;
  }
  .indexTitle.proBg {
    background: url(../images/second/professionals-bg.png);
    background-size: cover;
  }
  .indexTitle.proBg .inner h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 52px;
    height: auto;
    aspect-ratio: 52/56;
  }
  .indexTitle.hojinBg {
    background: url(../images/second/hojin-bg.png);
    background-size: cover;
  }
  .indexTitle.hojinBg .inner h1::before {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 52px;
    height: auto;
    aspect-ratio: 52/56;
  }
  .indexTitle.commonBg {
    background: url(../images/second/common-bg.png);
    background-size: cover;
  }
  .section.service {
    padding-bottom: 85px;
  }
  .service + .section {
    padding-top: 85px;
  }
  article .inner {
    width: 90%;
    max-width: 1000px;
  }
  article .inner .btnWrap {
    margin-top: 60px;
  }
  article .inner .btnWrap .prevBtn {
    padding: 10px 26px;
    font-size: 18px;
  }
  article .inner .btnWrap .prevBtn:hover, article .inner .btnWrap .prevBtn:focus {
    opacity: 0.7;
  }
  article .newsSec .inner .newsListArea .newsItem a {
    text-decoration: none;
  }
  article .newsSec .inner .newsListArea .newsItem a .textArea .infoArea {
    display: flex;
    gap: 20px;
  }
  article .newsSec .inner .newsListArea .newsItem a .date {
    min-width: 82px;
  }
  main * + p {
    margin-top: 20px;
    font-size: 16px;
  }
  .btnWrap {
    margin-top: 30px;
  }
  a {
    transition: 0.2s color;
  }
  a:hover {
    text-decoration: none;
  }
  a.hasArrow span {
    position: relative;
    padding-left: 20px;
  }
  a.hasArrow span::after {
    position: absolute;
    content: "";
    background: url(../images/common/arrow-green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
    width: 6px;
    height: auto;
    left: 0;
    top: 8px;
  }
  a.external::after {
    width: 26px;
    height: auto;
    right: -27px;
    bottom: -2px;
  }
  .breadcrumbArea {
    max-width: 1102px;
    margin: 0 auto;
    position: relative;
    margin-top: 24px;
    padding-bottom: 80px;
  }
  .breadcrumbArea ul {
    display: flex;
  }
  .breadcrumbArea ul li {
    color: #333;
    margin-right: 5px;
    font-weight: 400;
    font-size: 15px;
  }
  .breadcrumbArea ul li:before {
    content: "/";
    display: inline-block;
    margin-right: 3px;
  }
  .breadcrumbArea ul li:first-child:before {
    content: "";
    display: none;
  }
  .breadcrumbArea ul li a {
    text-decoration: none;
    color: #333;
  }
  .breadcrumbArea ul li a:hover {
    text-decoration: underline;
  }
  .section {
    padding: 60px 0 60px;
  }
  .section .inner {
    max-width: 1102px;
    width: 90%;
    margin: 0 auto;
  }
  .section .inner.semi {
    padding-inline: 20px;
    width: 100%;
  }
  .section .inner > p {
    margin-top: 32px;
  }
  .pageBigLead {
    font-size: 24px;
    line-height: 2 !important;
  }
  .pageBigLead + .pageLead {
    margin-top: 20px;
  }
  .pageBigLead + p {
    margin-top: 24px;
  }
  .pageLead {
    line-height: 2.18;
    font-size: 16px;
    max-width: 870px;
    text-align: center;
  }
  .pageLead + .btnWrap {
    margin-top: 70px;
  }
  .h2TitleWrap {
    font-size: 30px;
  }
  .h2TitleWrap h2 {
    font-size: 24px;
    margin-top: 4px;
  }
  .h2TitleWrap + p {
    margin-top: 34px;
  }
  .inner + * {
    margin-top: 40px;
  }
  * + .titleBorder,
  * + h2.title {
    margin-top: 60px;
  }
  * + h3.title {
    margin-top: 50px;
  }
  h3.title {
    padding-left: 18px;
    font-size: 24px;
  }
  h3.title:after {
    width: 3px;
  }
  * + .titleGray,
  * + h4.title {
    margin-top: 30px;
  }
  .titleGray + *,
  h4.title + * {
    margin-top: 5px;
  }
  .titleGray,
  h4.title {
    font-size: 20px;
  }
  * + h5.title {
    margin-top: 30px;
  }
  h5.title + * {
    margin-top: 5px;
  }
  h5.title {
    font-size: 18px;
  }
  * + .titleHeader {
    margin-top: 30px;
  }
  .titleHeader .titleCenter {
    font-size: 28px;
  }
  .titleCenterSub {
    margin-top: 8px;
    font-size: 15px;
  }
  * + [class^=listCol] {
    margin-top: 43px;
  }
  [class^=listCol] {
    display: flex;
    width: 100%;
  }
  [class^=listCol].setBtn {
    justify-content: space-between;
    gap: 74px;
  }
  [class^=listCol].setBtn.rev {
    flex-direction: row-reverse;
    gap: 7.3%;
  }
  [class^=listCol].setBtn .listItem {
    width: 49%;
  }
  [class^=listCol].setBtn .listItem.imgArea {
    margin-right: 0;
  }
  [class^=listCol].setBtn .listItem.textArea {
    width: 100%;
  }
  [class^=listCol].setBtn .listItem .btnWrap {
    margin-top: 30px;
  }
  [class^=listCol].setBtn .listItem h4 {
    margin-top: 0;
  }
  [class^=listCol].setBtn .listItem h4 + p {
    margin-top: 8px;
  }
  [class^=listCol].hasBox {
    padding: 45px;
  }
  [class^=listCol].hasBox .imgArea p img {
    border-radius: 10px;
  }
  * + .setBtn {
    margin-top: 40px;
  }
  * + .hasBox {
    margin-top: 85px;
  }
  .listCol2 > .listItem {
    width: calc(50% - 27px);
  }
  .listCol2 > .listItem:nth-child(odd) {
    margin-right: 54px;
  }
  .listCol2 > .listItem:nth-child(n+3) {
    margin-top: 50px;
  }
  .listCol3 .listItem {
    width: calc(33.3% - 37px);
  }
  .listCol3 .listItem:not(:nth-child(3n)) {
    margin-right: 56px;
  }
  .listCol3 .listItem:nth-child(n+4) {
    margin-top: 50px;
  }
  .listCol4 .listItem {
    width: calc(25% - 26px);
  }
  .listCol4 .listItem:not(:nth-child(4n)) {
    margin-right: 30px;
  }
  .listCol4 .listItem:nth-child(n+5) {
    margin-top: 30px;
  }
  * + .colImgFixed {
    margin-top: 30px;
  }
  .colImgFixed {
    /*
    @if $type == tb{
    	flex-direction: column;
    }
    */
  }
  .colImgFixed > * {
    /*
    		@if $type == tb{
    			width: 100%;

    			&.colText {
    				order: 1;
    				margin-top: 30px;
    			}

    			&.colImg {
    				order: 0;
    			}

    			&:nth-child(odd) {
    				margin-right: 0;
    			}

    			&:nth-child(n + 3) {
    				margin-top: 0;
    			}
    		}
    */
  }
  .colImgFixed > *.colImg {
    flex: none;
  }
  .colImgFixed > *:nth-child(odd) {
    margin-right: 97px;
  }
  .colImgFixed > *:nth-child(n+3) {
    margin-top: 30px;
  }
  * + .commonTable {
    margin-top: 30px;
  }
  .commonTable {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .commonTable > tbody > tr > th,
  .commonTable > thead > tr > th {
    padding: 28px 1.5%;
    font-size: 18px;
    border-top: 1px solid #cfcfcf;
    border-left: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;
  }
  .commonTable > tbody > tr > th:not([scope=row]),
  .commonTable > thead > tr > th:not([scope=row]) {
    width: 230px !important;
  }
  .commonTable > tbody > tr > td,
  .commonTable > thead > tr > td {
    padding: 28px 4%;
    line-height: 1.54;
    border-top: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;
  }
  * + .otherTable {
    margin-top: 30px;
  }
  .otherTable th {
    background: #edf9e4;
    color: #005b2a;
  }
  .otherTable th,
  .otherTable td {
    padding: 28px 1.5%;
  }
  * + .scrollTable {
    margin-top: 30px;
  }
  .scrolltext {
    display: none !important;
  }
  * + ul.commonList,
  * + ol.commonList {
    margin-top: 20px;
  }
  ul.commonList.col_pc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  ul.commonList.col_pc > li {
    width: calc(50% - 20px);
  }
  ul.commonList.col_pc > li:nth-child(odd) {
    margin-right: 10px;
  }
  ul.commonList.col_pc > li:nth-child(even) {
    margin-left: 10px;
  }
  ul.commonList > li + li {
    margin-top: 18px;
  }
  ul.commonList > li:before {
    top: 11px;
    margin: auto;
    left: 0;
  }
  ol.commonList.col_pc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  ol.commonList.col_pc > li {
    width: calc(50% - 20px);
  }
  ol.commonList.col_pc > li:nth-child(odd) {
    margin-right: 10px;
  }
  ol.commonList.col_pc > li:nth-child(even) {
    margin-left: 10px;
  }
  ol.commonList.col_pc > li:nth-of-type(2) {
    margin-top: 0 !important;
  }
  ol.commonList > li + li {
    margin-top: 7px;
  }
  ol.commonList > li:before {
    font-size: 16px;
    left: 7px;
  }
  *:not(.title) + dl.commonList {
    margin-top: 40px;
  }
  .title + dl.commonList {
    margin-top: 0;
  }
  dl.commonList > div {
    display: flex;
    padding: 20px 0;
  }
  dl.commonList > div > dt,
  dl.commonList > div > dd {
    font-size: 16px;
  }
  dl.commonList > div > dt {
    width: 196px;
    padding-right: 20px;
  }
  dl.commonList > div > dd {
    width: calc(100% - 196px);
  }
  .col2Btn {
    margin-top: 40px;
  }
  * + .listTextCol2 {
    margin-top: 30px;
  }
  .listTextCol2 {
    margin-top: 20px;
  }
  .listTextCol2 ul {
    width: 48%;
    padding: 28px;
  }
  .listTextCol2 ul.bold li {
    font-size: 18px;
  }
  .listTextCol2 ul.bold li + li {
    margin-top: 10px;
  }
  .listTextCol2 .textArea {
    width: 48%;
    padding: 28px;
    border-left: solid 1px #cfcfcf;
  }
  .listTextCol2 .textArea > ul {
    width: 100%;
  }
  .lineBox {
    padding: 28px;
    margin-top: 40px;
  }
  .iconText:hover, .iconText:focus {
    text-decoration: none;
  }
  .iconText.pdf::before {
    content: "";
    position: absolute;
    background: url(../images/second/pdf-icon.svg);
    background-size: contain;
    aspect-ratio: 117/150;
    height: auto;
    right: -30px;
    background-repeat: no-repeat;
    bottom: -2px;
    width: 19px;
  }
  .iconText.excel::before {
    position: absolute;
    background: url(../images/second/excel-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 24px;
  }
  .iconText.word::before {
    position: absolute;
    content: "";
    background: url(../images/second/word-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 30px;
  }
  .col2NavLink {
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 60px;
  }
  .col2NavLink div {
    width: calc((100% - 30px) / 2);
  }
  .col2NavLink div a {
    font-size: 18px;
    padding-left: 34px;
    padding-block: 11px;
    padding-right: 70px;
  }
  .col2NavLink div a .arrow {
    width: 27px;
    height: 27px;
  }
  .col2NavLink div a .arrow::before {
    content: "";
    background: url(../images/common/arrow.svg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
    width: 6px;
    height: auto;
  }
  .iframeSize {
    margin-inline: auto;
    max-width: 640px;
    width: 90%;
  }
  .iframeSize .iframeWrap {
    margin-top: 42px;
  }
  .mapWrap {
    padding-bottom: 50.55%;
    margin-top: 63px;
  }
  * + .colorBox {
    margin-top: 40px;
  }
  * + .colorBoxAdd {
    margin-top: 20px;
  }
  .colorBox {
    padding: 40px 40px 40px;
    border-radius: 15px;
  }
  .colorBoxAdd {
    padding: 20px 40px 20px;
    border-radius: 8px;
  }
  .colorBox .colorBoxTitle,
  .colorBoxAdd .colorBoxTitle {
    font-size: 18px;
  }
  .colorBox .colorBoxTitle + p,
  .colorBoxAdd .colorBoxTitle + p {
    margin-top: 4px;
  }
  .preBox {
    margin-top: 30px;
  }
  .taC_pc {
    text-align: center !important;
  }
  .taL_pc {
    text-align: left !important;
  }
  .taR_pc {
    text-align: right !important;
  }
  .pT10_pc {
    padding-top: 10px !important;
  }
  .pT20_pc {
    padding-top: 20px !important;
  }
  .pT30_pc {
    padding-top: 30px !important;
  }
  .pT40_pc {
    padding-top: 40px !important;
  }
  .pB10_pc {
    padding-bottom: 10px !important;
  }
  .pB20_pc {
    padding-bottom: 20px !important;
  }
  .pB30_pc {
    padding-bottom: 30px !important;
  }
  .pB40_pc {
    padding-bottom: 40px !important;
  }
  .mB0_pc {
    margin-bottom: 0 !important;
  }
  .mB5_pc {
    margin-bottom: 5px !important;
  }
  .mB10_pc {
    margin-bottom: 10px !important;
  }
  .mB15_pc {
    margin-bottom: 15px !important;
  }
  .mB20_pc {
    margin-bottom: 20px !important;
  }
  .mB30_pc {
    margin-bottom: 30px !important;
  }
  .mB40_pc {
    margin-bottom: 40px !important;
  }
  .mB50_pc {
    margin-bottom: 50px !important;
  }
  .mB60_pc {
    margin-bottom: 60px !important;
  }
  .mB70_pc {
    margin-bottom: 70px !important;
  }
  .mB80_pc {
    margin-bottom: 80px !important;
  }
  .mT0_pc {
    margin-top: 0 !important;
  }
  .mT5_pc {
    margin-top: 5px !important;
  }
  .mT10_pc {
    margin-top: 10px !important;
  }
  .mT20_pc {
    margin-top: 20px !important;
  }
  .mT30_pc {
    margin-top: 30px !important;
  }
  .mT40_pc {
    margin-top: 40px !important;
  }
  .mT50_pc {
    margin-top: 50px !important;
  }
  .mT70_pc {
    margin-top: 70px !important;
  }
  .mT80_pc {
    margin-top: 80px !important;
  }
  .mL0_pc {
    margin-left: 0px !important;
  }
  .mL5_pc {
    margin-left: 5px !important;
  }
  .mL10_pc {
    margin-left: 10px !important;
  }
  .mL20_pc {
    margin-left: 20px !important;
  }
  .mL30_pc {
    margin-left: 30px !important;
  }
  .mL40_pc {
    margin-left: 40px !important;
  }
  .mL55_pc {
    margin-left: 55px !important;
  }
  .mR0_pc {
    margin-right: 0px !important;
  }
  .mR5_pc {
    margin-right: 5px !important;
  }
  .mR10_pc {
    margin-right: 10px !important;
  }
  .mR20_pc {
    margin-right: 20px !important;
  }
  .mR30_pc {
    margin-right: 30px !important;
  }
  .mR40_pc {
    margin-right: 40px !important;
  }
  .mR50_pc {
    margin-right: 50px !important;
  }
  .mR55_pc {
    margin-right: 55px !important;
  }
  .size12_pc {
    font-size: 12px !important;
  }
  .size13_pc {
    font-size: 13px !important;
  }
  .size14_pc {
    font-size: 14px !important;
  }
  .size15_pc {
    font-size: 15px !important;
  }
  .size16_pc {
    font-size: 16px !important;
  }
  .size17_pc {
    font-size: 17px !important;
  }
  .size18_pc {
    font-size: 18px !important;
  }
  .size19_pc {
    font-size: 19px !important;
  }
  .size20_pc {
    font-size: 20px !important;
  }
  .size22_pc {
    font-size: 22px !important;
  }
  .size24_pc {
    font-size: 24px !important;
  }
  .size28_pc {
    font-size: 28px !important;
  }
  .hoverAlpha,
  .hoverAlpha img {
    display: block;
    transition: 0.3s opacity;
  }
  .hoverAlpha:hover, .hoverAlpha:hover img,
  .hoverAlpha img:hover,
  .hoverAlpha img:hover img {
    opacity: 0.8;
  }
  * + .summaryBox {
    margin-top: 30px;
  }
  .summaryBox {
    display: flex;
    justify-content: space-between;
  }
  .summaryBox .summaryBoxText {
    max-width: 55%;
    margin-right: 5%;
  }
  .summaryBox .summaryBoxThumb {
    width: 40%;
    max-width: 500px;
    overflow: hidden;
    aspect-ratio: 6/4;
    position: relative;
  }
  .summaryBox .summaryBoxThumb > span {
    background: #c00;
    animation: 0.4s 0.4s scrollIn forwards ease-out;
    transform: translateX(0);
  }
  .summaryBox .summaryBoxThumb img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @keyframes scrollIn {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  .movieLinkL {
    display: inline-block;
    position: relative;
  }
  .movieLinkL:after {
    content: "";
    display: block;
    background: url(../images/home/movie.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
  }
  .sec_border {
    border-top: 4px solid #3764ab;
  }
  .section.sec_white {
    background: #fff;
  }
  .section.sec_white:last-of-type {
    margin-bottom: -170px;
    padding-bottom: 170px;
  }
  .contentsWrap {
    padding-bottom: 120px;
  }
  .contentsWrap > .pageLead {
    font-size: 15px;
    text-align: center;
  }
  .col2_img_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .col2_img_list li {
    flex-basis: 48%;
    margin-top: 50px;
  }
  .col2_img_list li:nth-of-type(1), .col2_img_list li:nth-of-type(2) {
    margin-top: 0;
  }
  .col2_img_list li p {
    margin-top: 15px;
  }
  .lc3wide {
    justify-content: space-between;
  }
  .lc3wide .listItem:not(:nth-child(3n)) {
    margin-right: 10px;
  }
  .lc3wide .listItem {
    width: calc(33.3% - 9px);
  }
  .lc3wide a {
    text-decoration: none;
    transition: opacity 0.25s;
  }
  .lc3wide a:hover {
    opacity: 0.75;
  }
  .newsListArea {
    margin-top: 30px;
  }
  .newsListArea .newsItem a {
    position: relative;
    border-radius: 10px;
    align-items: center;
    padding: 23px 40px 23px 30px;
    transition: 0.2s ease-in-out;
  }
  .newsListArea .newsItem a::before {
    content: "";
    position: absolute;
    background: #aaa;
    height: 1px;
    width: 98%;
    right: 0;
    bottom: 0px;
    transition: 0.2s ease-in-out;
  }
  .newsListArea .newsItem a .textArea {
    gap: 44px;
  }
  .newsListArea .newsItem a .textArea .date {
    font-size: 16px;
    min-width: 82px;
  }
  .newsListArea .newsItem a .textArea .title {
    font-size: 16px;
  }
  .newsListArea .newsItem a .textArea .cat {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    width: 126px;
    height: 30px;
  }
  .newsListArea .newsItem a .c-arrow {
    position: absolute;
    width: 27px;
    height: 27px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-block;
    transition: 0.2s ease-in-out;
  }
  .newsListArea .newsItem a .c-arrow::before {
    content: "";
    width: 27px;
    height: 27px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
  }
  .newsListArea .newsItem a .c-arrow::after {
    content: "";
    width: 6px;
    height: 6px;
    border: 0;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 8px;
    bottom: 0;
    margin: auto;
    border-radius: 2px;
  }
  .newsListArea .newsItem a:hover, .newsListArea .newsItem a:focus {
    box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
  }
  .newsListArea .newsItem a:hover .c-arrow, .newsListArea .newsItem a:focus .c-arrow {
    visibility: visible;
  }
  .newsListArea .newsItem a:hover::before, .newsListArea .newsItem a:focus::before {
    visibility: hidden;
  }
  .pager ul {
    gap: 30px;
  }
  .pager ul li a {
    text-decoration: none;
  }
  .pager ul li a.link_before {
    color: #005b2a;
  }
  .pager ul li a.link_after {
    color: #005b2a;
  }
  .pager ul li:hover, .pager ul li:focus {
    opacity: 0.7;
  }
  .s-moreBtn {
    margin-top: 24px !important;
  }
  .commonContactArea {
    padding-top: 80px;
    padding-bottom: 70px;
  }
  .commonContactArea .inner .dialArea {
    margin-top: 40px;
    padding: 20px 40px 20px;
  }
  .commonContactArea .inner .dialArea .title {
    font-size: 18px;
    padding: 5px 40px;
    max-width: 390px;
    border-radius: 5px;
  }
  .commonContactArea .inner .dialArea a {
    font-size: 44px;
  }
  .commonContactArea .inner .dialArea .dialText {
    margin-top: -12px;
  }
  .commonContactArea .inner .dialArea .dialText .jpText {
    font-size: 17px;
  }
  .commonContactArea .inner .dialArea .dialText .stText {
    font-size: 17px;
  }
  .commonContactArea .inner .dialArea .dialText .stText > span {
    font-size: 18px;
  }
  * + .navBtnWrap {
    margin-top: 34px;
  }
  .navBtnWrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 18px;
  }
  .navBtnWrap li {
    width: calc((100% - 36px) / 3);
  }
  .navBtnWrap li a {
    font-size: 19px;
    padding: 23px 40px;
    letter-spacing: 0.06em;
  }
  .navBtnWrap li a .c-arrow {
    position: absolute;
    right: 46px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
  }
  .navBtnWrap li a .c-arrow::before {
    content: "";
    width: 27px;
    height: 27px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  .navBtnWrap li a .c-arrow::after {
    position: absolute;
    content: "";
    background: url(../images/common/arrow-white.svg);
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 83/150;
    width: 6px !important;
    height: auto;
    top: calc(0px + 50%);
    left: calc(14px + 50%);
  }
  .navBtnWrap li a .c-arrow.forLink::after {
    top: -5px !important;
    left: 9px !important;
  }
  .navBtnWrap li a:hover, .navBtnWrap li a:focus {
    opacity: 0.7;
  }
  .navBtnWrap li a.external::after {
    right: 18px;
    width: 30px;
    top: 14px;
  }
  .navBtnWrap.mini {
    gap: 40px;
    row-gap: 30px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .navBtnWrap.mini li {
    width: calc((100% - 80px) / 3);
    margin-right: 0;
    min-width: 262px;
  }
  .navBtnWrap.mini li a {
    padding: 14px 52px 14px 32px;
    font-size: 18px;
  }
  .navBtnWrap.col3 li {
    width: calc((100% - 80px) / 3);
  }
  .navBtnWrap.col3 li a .c-arrow::before {
    background: #fff;
  }
  .navBtnWrap.col3 li a .c-arrow::after {
    content: "";
    position: absolute;
    width: 9px;
    height: auto;
    background: url(../images/common/arrow.svg);
    top: 50%;
    left: calc(50% + 0px);
    transform: translate(-50%, -50%);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .navBtnWrap.col2 li {
    width: calc((100% - 18px) / 2);
    max-width: 340px;
  }
  .navBtnWrap.col2 li a .c-arrow::before {
    background: #fff;
  }
  .navBtnWrap.col2 li a .c-arrow::after {
    content: "";
    position: absolute;
    width: 9px;
    height: auto;
    background: url(../images/common/arrow.svg);
    top: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 83/150;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .navBtnBlock {
    padding: 32px 22px;
    margin-top: 80px;
  }
  .navBtnWrap + p {
    margin-top: 42px;
  }
  * + .navCardWrap {
    margin-top: 52px;
  }
  .navCardWrap {
    flex-wrap: wrap;
    gap: 24px;
  }
  .navCardWrap .navCardItem {
    width: calc((100% - 24px) / 2);
  }
  .navCardWrap .navCardItem a {
    transition: all 0.3s;
  }
  .navCardWrap .navCardItem a .title {
    font-size: 18px;
    padding: 6px 55px 6px 40px;
    line-height: 1.2;
    height: 70px;
  }
  .navCardWrap .navCardItem a .title.forExternal::before {
    right: 20px;
    top: 20px;
    width: 30px;
    height: auto;
  }
  .navCardWrap .navCardItem a * + .cardText {
    margin-top: 0;
  }
  .navCardWrap .navCardItem a .cardText {
    font-size: 16px;
    padding: 20px 40px 24px;
    border-radius: 0px 0px 10px 10px;
  }
  .navCardWrap .navCardItem a .c-arrow {
    bottom: 30px;
  }
  .navCardWrap .navCardItem a .c-arrow::before {
    content: "";
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 27px;
    height: 27px;
  }
  .navCardWrap .navCardItem a:hover, .navCardWrap .navCardItem a:focus {
    opacity: 0.7;
  }
  p + .navCardWrap {
    margin-top: 40px;
  }
  p + .bannerWrap {
    margin-top: 70px;
  }
  .bannerWrap {
    display: flex;
    max-width: 934px;
    margin-inline: auto;
    gap: 34px;
  }
  .commonBanner {
    max-width: 450px;
    width: 100%;
    transition: all 0.3s;
  }
  .commonBanner:hover, .commonBanner:focus {
    opacity: 0.7;
  }
  .commonBanner p.green {
    background: #005b2a;
  }
  .commonBanner p.skyblue {
    background: #449ab6;
  }
  .commonBanner p.orange {
    background: #f3961e;
  }
  .commonBanner p.chiken-blue {
    background: #3363ab;
  }
  .commonBanner p > img {
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
  }
  .commonBanner div {
    font-size: clamp(16px, 2vw, 18px);
    line-height: 1.31;
  }
  .commonBanner div > span {
    max-width: 165px;
  }
  .commonBanner div.orange {
    background: #f3961e;
    position: relative;
    z-index: 2;
  }
  .commonBanner div.chiken-blue {
    background: #3363ab;
  }
  .commonBanner div.skyblue {
    background: #449ab6;
    position: relative;
    z-index: 2;
  }
  .commonBanner div.green {
    background: #005b2a;
    position: relative;
    z-index: 2;
  }
  .commonBanner div.chiken-blue {
    background: #3363ab;
    position: relative;
    z-index: 2;
  }
  .commonBanner div .c-arrow {
    position: absolute;
    display: inline-block;
    right: min(1.25vw, 20px);
    width: 27px;
    height: 27px;
    top: 53%;
    transform: translateY(-50%);
  }
  .commonBanner div .c-arrow::before {
    content: "";
    width: 27px;
    height: 27px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  .commonBanner div .c-arrow::after {
    width: 6px;
    height: auto;
    top: calc(50% + 0px);
  }
  .commonBanner div .c-arrow.skyblue::after {
    background: url(../images/common/arrow-lightblue.svg);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 83/150;
  }
  .commonBanner div .c-arrow.lightgray::after {
    background: url(../images/common/arrow-lightgray.svg);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 83/150;
  }
  .commonBanner div .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .commonBanner div .c-arrow.green::after {
    background: url(../images/common/arrow-green.svg);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .commonBanner div .c-arrow.c-hojin::after {
    background: url(../images/common/arrow-lightgreen.svg);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .hasBorder {
    padding-top: 40px;
  }
  .s-moreBtn {
    color: #fff;
    background: #005b2a;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 40px;
    padding-block: 11px;
    max-width: 160px;
    margin-left: auto;
    margin-top: 14px;
    transition: all 0.3s;
  }
  .s-moreBtn .c-arrow {
    position: absolute;
    display: inline-block;
    right: 12px;
    top: 53%;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
  }
  .s-moreBtn .c-arrow::before {
    content: "";
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  .s-moreBtn .c-arrow::after {
    content: "";
    background: url(../images/common/arrow-green.svg);
    position: absolute;
    top: 50%;
    left: calc(0px + 50%);
    transform: translate(-50%, -50%);
    width: 5px;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .s-moreBtn:hover, .s-moreBtn:focus {
    opacity: 0.7;
  }
  h2.greenLabel {
    font-size: 22px;
    padding: 5px 30px;
    margin-top: 40px;
    border-radius: 6px;
  }
  h2.greenLabel + h3 {
    margin-top: 26px;
  }
  h2.greenLabel + .cardWrap {
    margin-top: 40px;
  }
  h2.greenLabel + p {
    margin-top: 27px;
  }
  h3 {
    font-size: 20px;
    padding-left: 24px;
    margin-top: 30px;
  }
  h3::before {
    width: 7px;
    left: 0px;
    top: 4px;
    height: 30px;
  }
  h4 {
    font-size: 20px;
    margin-top: 22px;
  }
  h4 + p {
    margin-top: 8px;
  }
  h5 {
    margin-top: 45px;
    font-size: 18px;
  }
  h5 + p {
    margin-top: 12px;
  }
  .capImg {
    margin-top: 36px;
  }
  .capText {
    margin-top: 12px;
    font-size: 15px;
  }
  * + .cardWrap {
    margin-top: 60px;
  }
  .cardWrap {
    row-gap: 30px;
  }
  .cardWrap .card {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin-right: 36px;
    flex-direction: column;
    width: calc((100% - 108px) / 4);
    padding: 12px 20px;
  }
  .cardWrap .card .title {
    font-size: 20px;
    text-align: center;
  }
  .cardWrap .card .title .mini {
    font-size: 15px;
    display: block;
  }
  .cardWrap .card .title .kome {
    font-size: 10px;
  }
  .cardWrap .card .hasBg {
    margin-top: 20px;
  }
  .cardWrap .card .text {
    margin-top: 16px;
    align-items: flex-start;
    line-height: 1.56;
  }
  .cardWrap .card .text .num {
    font-size: 20px;
    top: -4px;
  }
  .cardWrap .card.next::before {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-left: 13px solid #005b2a;
    right: -35px;
    top: 50%;
    transform: translateY(-50%);
  }
  .cardWrap .card ul li .num {
    font-size: 20px;
  }
  .cardWrap + p {
    margin-top: 34px;
  }
  .cardWrap.intro {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px;
  }
  .cardWrap.intro li {
    padding: 20px;
    width: 194px;
    margin-right: 0;
  }
  .cardWrap.intro li .illust {
    aspect-ratio: 1/1;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 135px;
  }
  .cardWrap.intro li .illust > img {
    border-radius: 50%;
  }
  .cardWrap.intro li .illust > img.img1 {
    max-width: 100px;
  }
  .cardWrap.intro li .illust > img.img2 {
    max-width: 106px;
  }
  .cardWrap.intro li .illust > img.img3 {
    max-width: 106px;
  }
  .cardWrap.intro li .illust > img.img4 {
    max-width: 80px;
  }
  .cardWrap.intro li .illust > img.img5 {
    max-width: 98px;
  }
  .cardWrap.intro li .title {
    margin-top: 6px;
  }
  .cardWrap.intro li .text {
    margin-top: -6px;
  }
  .cardWrap.full {
    gap: 56px;
  }
  .cardWrap.full .card {
    width: calc((100% - 112px) / 3);
    margin-right: 0;
    padding-bottom: 40px;
  }
  .cardWrap.full .card::before {
    right: -44px !important;
    left: auto !important;
    transform: translateX(0) !important;
  }
  .cardWrap.full .card .hasBg {
    margin-top: 8px;
  }
  .cardWrap.full .card .text {
    text-align: center;
    margin-top: 10px;
    line-height: 1.56;
  }
  .cardWrap.col4 {
    gap: 30px;
  }
  .cardWrap.col4 .card {
    width: calc((100% - 90px) / 4);
    margin-right: 0;
  }
  .cardWrap.col4 .card.next::before {
    right: -32px;
  }
  .innerMini {
    margin-top: 16px;
  }
  .qa dt {
    font-size: 18px;
    line-height: 1.94;
    padding-left: 40px;
    padding-top: 20px;
  }
  .qa dt::before {
    content: "Q";
    position: absolute;
    left: 0;
    font-family: "Lato", sans-serif;
    font-size: 30px;
    top: 7px;
    font-weight: 600;
  }
  .qa dd {
    font-size: 16px;
    padding-left: 40px;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .qa dd::before {
    font-size: 30px;
    top: -10px;
  }
  .anchorList ul {
    row-gap: 30px;
    gap: 30px;
  }
  .anchorList ul li {
    width: calc((100% - 60px) / 3);
  }
  .anchorList ul li a {
    padding: 14px 40px 14px 20px;
    font-size: 18px;
  }
  .anchorList ul li a::before {
    width: 8px;
    height: auto;
    right: 20px;
    top: 22px;
  }
  .anchorList ul li a:hover::before, .anchorList ul li a:focus::before {
    top: 30px;
  }
  * + .bannerList {
    margin-top: 36px;
  }
  .bannerList.col3 {
    flex-wrap: wrap;
  }
  .bannerList.col3 li {
    padding: 30px;
    width: 33.3333333333%;
    border: solid #cdcdcd 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 367/218;
  }
  .bannerList.col3 li:nth-child(n+4) {
    border-top: none;
  }
  .bannerList.col3 li:not(:first-child) {
    border-left: none;
  }
  .bannerList.col3 li:nth-child(4n) {
    border-left: solid #cdcdcd 1px;
  }
  .bannerList.col3 li a {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s;
  }
  .bannerList.col3 li a:hover, .bannerList.col3 li a:focus {
    opacity: 0.7;
  }
  /* common class */
  .mB0_pc {
    margin-bottom: 0 !important;
  }
  .mB5_pc {
    margin-bottom: 5px !important;
  }
  .mB10_pc {
    margin-bottom: 10px !important;
  }
  .mB20_pc {
    margin-bottom: 20px !important;
  }
  .mB30_pc {
    margin-bottom: 30px !important;
  }
  .mB40_pc {
    margin-bottom: 40px !important;
  }
  .mB50_pc {
    margin-bottom: 50px !important;
  }
  .mB60_pc {
    margin-bottom: 60px !important;
  }
  .mB70_pc {
    margin-bottom: 70px !important;
  }
  .mB80_pc {
    margin-bottom: 80px !important;
  }
  .mT0_pc {
    margin-top: 0 !important;
  }
  .mT5_pc {
    margin-top: 5px !important;
  }
  .mT10_pc {
    margin-top: 10px !important;
  }
  .mT20_pc {
    margin-top: 20px !important;
  }
  .mT30_pc {
    margin-top: 30px !important;
  }
  .mT40_pc {
    margin-top: 40px !important;
  }
  .mT50_pc {
    margin-top: 50px !important;
  }
  .mT60_pc {
    margin-top: 60px !important;
  }
  .mT70_pc {
    margin-top: 70px !important;
  }
  .mT80_pc {
    margin-top: 80px !important;
  }
  .mT90_pc {
    margin-top: 90px !important;
  }
  .mL0_pc {
    margin-left: 0px !important;
  }
  .mL5_pc {
    margin-left: 5px !important;
  }
  .mL10_pc {
    margin-left: 10px !important;
  }
  .mL20_pc {
    margin-left: 20px !important;
  }
  .mL30_pc {
    margin-left: 30px !important;
  }
  .mL40_pc {
    margin-left: 40px !important;
  }
  .mL50_pc {
    margin-left: 50px !important;
  }
  .mR0_pc {
    margin-right: 0px !important;
  }
  .mR5_pc {
    margin-right: 5px !important;
  }
  .mR10_pc {
    margin-right: 10px !important;
  }
  .mR20_pc {
    margin-right: 20px !important;
  }
  .mR30_pc {
    margin-right: 30px !important;
  }
  .mR40_pc {
    margin-right: 40px !important;
  }
  .mR50_pc {
    margin-right: 50px !important;
  }
  .size12_pc {
    font-size: 12px !important;
  }
  .size14_pc {
    font-size: 14px !important;
  }
  .size16_pc {
    font-size: 16px !important;
  }
  .size18_pc {
    font-size: 18px !important;
  }
  .size20_pc {
    font-size: 20px !important;
  }
  .size21_pc {
    font-size: 21px !important;
  }
  .size22_pc {
    font-size: 22px !important;
  }
  .size23_pc {
    font-size: 23px !important;
  }
  .size24_pc {
    font-size: 24px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1100px) {
  article .newsSec .inner .newsListArea .newsItem a {
    text-decoration: none;
  }
  article .newsSec .inner .newsListArea .newsItem a .textArea .infoArea {
    display: flex;
    gap: 20px;
  }
  a.hasArrow span {
    position: relative;
    padding-left: 20px;
  }
  a.hasArrow span::after {
    position: absolute;
    content: "";
    background: url(../images/common/arrow-green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
    width: 6px;
    height: auto;
    left: 0;
    top: 8px;
  }
  .breadcrumbArea ul {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
  }
  .breadcrumbArea ul li {
    color: #333;
    margin-right: 5px;
    font-weight: 400;
  }
  .breadcrumbArea ul li:before {
    content: "/";
    display: inline-block;
    margin-right: 3px;
  }
  .breadcrumbArea ul li:first-child:before {
    content: "";
    display: none;
  }
  .breadcrumbArea ul li a {
    text-decoration: none;
    color: #333;
  }
  .breadcrumbArea ul li a:hover {
    text-decoration: underline;
  }
  .section .inner {
    padding: 0 20px;
  }
  [class^=listCol].setBtn .listItem h4 {
    margin-top: 0;
  }
  [class^=listCol].hasBox .imgArea p img {
    border-radius: 10px;
  }
  .listCol3 .listItem {
    width: calc(33.3% - 37px);
  }
  .listCol3 .listItem:not(:nth-child(3n)) {
    margin-right: 56px;
  }
  .listCol3 .listItem:nth-child(n+4) {
    margin-top: 50px;
  }
  .colImgFixed {
    /*
    @if $type == tb{
    	flex-direction: column;
    }
    */
  }
  .colImgFixed > * {
    /*
    		@if $type == tb{
    			width: 100%;

    			&.colText {
    				order: 1;
    				margin-top: 30px;
    			}

    			&.colImg {
    				order: 0;
    			}

    			&:nth-child(odd) {
    				margin-right: 0;
    			}

    			&:nth-child(n + 3) {
    				margin-top: 0;
    			}
    		}
    */
  }
  .otherTable th {
    background: #edf9e4;
    color: #005b2a;
  }
  .iconText.pdf::before {
    content: "";
    position: absolute;
    background: url(../images/second/pdf-icon.svg);
    background-size: contain;
    aspect-ratio: 117/150;
    height: auto;
  }
  .iconText.excel::before {
    position: absolute;
    background: url(../images/second/excel-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 24px;
  }
  .iconText.word::before {
    position: absolute;
    content: "";
    background: url(../images/second/word-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 30px;
  }
  .col2NavLink div a .arrow::before {
    content: "";
    background: url(../images/common/arrow.svg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .iframeSize {
    margin-inline: auto;
    max-width: 640px;
    width: 90%;
  }
  @keyframes scrollIn {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  .movieLinkL {
    display: inline-block;
    position: relative;
  }
  .movieLinkL:after {
    content: "";
    display: block;
    background: url(../images/home/movie.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
  }
  .sec_border {
    border-top: 4px solid #3764ab;
  }
  .section.sec_white {
    background: #fff;
  }
  .section.sec_white:last-of-type {
    margin-bottom: -170px;
    padding-bottom: 170px;
  }
  .contentsWrap > .pageLead {
    font-size: 15px;
  }
  .col2_img_list li p {
    margin-top: 15px;
  }
  .lc3wide a {
    text-decoration: none;
    transition: opacity 0.25s;
  }
  .lc3wide a:hover {
    opacity: 0.75;
  }
  .newsListArea .newsItem a .textArea .cat {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .newsListArea .newsItem a:hover, .newsListArea .newsItem a:focus {
    box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
  }
  .newsListArea .newsItem a:hover .c-arrow, .newsListArea .newsItem a:focus .c-arrow {
    visibility: visible;
  }
  .pager ul li a {
    text-decoration: none;
  }
  .pager ul li a.link_before {
    color: #005b2a;
  }
  .pager ul li a.link_after {
    color: #005b2a;
  }
  .navBtnWrap li a .c-arrow::after {
    position: absolute;
    content: "";
    background: url(../images/common/arrow-white.svg);
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 83/150;
  }
  .navBtnWrap li a:hover, .navBtnWrap li a:focus {
    opacity: 0.7;
  }
  .navBtnWrap.col3 li a .c-arrow::before {
    background: #fff;
  }
  .navBtnWrap.col2 li a .c-arrow::before {
    background: #fff;
  }
  .navCardWrap .navCardItem a * + .cardText {
    margin-top: 0;
  }
  .navCardWrap .navCardItem a .c-arrow::before {
    content: "";
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  .commonBanner:hover, .commonBanner:focus {
    opacity: 0.7;
  }
  .commonBanner p.green {
    background: #005b2a;
  }
  .commonBanner p.skyblue {
    background: #449ab6;
  }
  .commonBanner p.orange {
    background: #f3961e;
  }
  .commonBanner p.chiken-blue {
    background: #3363ab;
  }
  .commonBanner p > img {
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
  }
  .commonBanner div.orange {
    background: #f3961e;
    position: relative;
    z-index: 2;
  }
  .commonBanner div.chiken-blue {
    background: #3363ab;
  }
  .commonBanner div.skyblue {
    background: #449ab6;
    position: relative;
    z-index: 2;
  }
  .commonBanner div.green {
    background: #005b2a;
    position: relative;
    z-index: 2;
  }
  .commonBanner div.chiken-blue {
    background: #3363ab;
    position: relative;
    z-index: 2;
  }
  .commonBanner div .c-arrow::before {
    content: "";
    width: 27px;
    height: 27px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  .commonBanner div .c-arrow.skyblue::after {
    background: url(../images/common/arrow-lightblue.svg);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 83/150;
  }
  .commonBanner div .c-arrow.lightgray::after {
    background: url(../images/common/arrow-lightgray.svg);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 83/150;
  }
  .commonBanner div .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .commonBanner div .c-arrow.green::after {
    background: url(../images/common/arrow-green.svg);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .commonBanner div .c-arrow.c-hojin::after {
    background: url(../images/common/arrow-lightgreen.svg);
    aspect-ratio: 83/150;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .s-moreBtn .c-arrow::before {
    content: "";
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  .s-moreBtn .c-arrow::after {
    content: "";
    background: url(../images/common/arrow-green.svg);
    position: absolute;
    top: 50%;
    left: calc(0px + 50%);
    transform: translate(-50%, -50%);
    width: 5px;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  .cardWrap .card.next::before {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-left: 13px solid #005b2a;
  }
  .cardWrap.intro li .illust {
    aspect-ratio: 1/1;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardWrap.intro li .illust > img {
    border-radius: 50%;
  }
  .cardWrap.intro li .illust > img.img1 {
    max-width: 100px;
  }
  .cardWrap.intro li .illust > img.img2 {
    max-width: 106px;
  }
  .cardWrap.intro li .illust > img.img3 {
    max-width: 106px;
  }
  .cardWrap.intro li .illust > img.img4 {
    max-width: 80px;
  }
  .cardWrap.intro li .illust > img.img5 {
    max-width: 98px;
  }
  .cardWrap.col4 {
    gap: 20px;
  }
  .cardWrap.col4 .card {
    width: calc((100% - 60px) / 4);
    margin-right: 0;
  }
  .cardWrap.col4 .card.next::before {
    border: 7px solid transparent;
    border-left: 10px solid #005b2a;
    right: -22px;
  }
  /* common class */
}